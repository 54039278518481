import gql from "graphql-tag";

export const fragmentOrder = gql`
    fragment Order on Order {
        id
        orderNum
        orderDate
        customerNum
        customerName
        customerOrderNum
        customerRefNum
        orderRefNum
        additionalInfo
        delivery
        rows {
          commodityName
          orderedAmount
          orderedUnit
          transportAmount
          transportUnit
          weight
          items
          length
          width
          height
          additionalInfo
          transactions {
            contractorId
            status
            vehicleId
            waybillNum
            routeId
            sender
            pickupTransactionId
            pickupLocation
            pickupAddress
            pickupAddressAdditionalInfo
            pickupZipcode
            pickupCity
            pickupDoor
            pickupHours
            pickupCoordinatesX
            pickupCoordinatesY
            pickupPerson
            pickupPhone
            pickupEmail
            receiver
            unloadTransactionId
            unloadLocation
            unloadAddress
            unloadAddressAdditionalInfo
            unloadZipcode
            unloadCity
            unloadDoor
            unloadHours
            unloadCoordinatesX
            unloadCoordinatesY
            unloadPerson
            unloadPhone
            unloadEmail
            orderedAmount
            orderedUnit
            actualUnloadEndTime
            actualAmount
            actualUnit
            actualPickupStartTime
            actualPickupEndTime
            actualUnloadStartTime
          }
        }
      }
`;

export default fragmentOrder;