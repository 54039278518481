import React from "react";
import PropTypes from "prop-types";
import Button from "../input/Button";
import styles from "./RoadHazardOptionButton.module.scss";
import { roadHazards } from "../../app.config";

import iconAccident from "./assets/road-hazard-accident.svg";
import iconSlippery from "./assets/road-hazard-slippery.svg";
import iconReindeers from "./assets/road-hazard-reindeers.svg";
import iconOther from "./assets/road-hazard-other.svg";


const Icon = ({type}) => {
  let icon;
  let alt;
  switch(type){
    case roadHazards.types.accident:
      icon = iconAccident;
      alt = "Onnettomuus";
      break;
    case roadHazards.types.slippery:
      icon = iconSlippery;
      alt = "Liukasta";
      break;
    case roadHazards.types.reindeers:
      icon = iconReindeers;
      alt = "Poroja";
      break;
    default:
      icon = iconOther;
      alt = "Muu vaara";
      break;
  }

  return (
    <React.Fragment>
      <img src={icon} alt={alt} className={styles.icon} />
      <span className={styles.title}>{alt}</span>
    </React.Fragment>
  )
}

const RoadHazardOptionButton = ({ type, onClick = (()=>{}) }) => {
  
  /* 
    The exception form URL contriols the information collected for the exception, 
    build the link to the form based on information provided to the button
  */

  return (
    <Button
      className={styles.root}
      onClick={() => onClick(type)}
    >
      <Icon type={type} />
    </Button>
  )
};

RoadHazardOptionButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default RoadHazardOptionButton;
