import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { navigate } from "@reach/router";
import { Query, withApollo } from "react-apollo";
import * as Sentry from '@sentry/browser';
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { adminDeleteUserMutation } from "../../api/graphql/adminDeleteUser";
import { deleteUserMutation } from "../../api/graphql/deleteUser";
import { adminGetUserQuery, adminGetUserProps } from "../../api/graphql/adminGetUser";
import { getUsersQuery } from "../../api/graphql/getUsers";
import { adminGetUsersQuery } from "../../api/graphql/adminGetUsers";
import DelayedComponent from "../DelayedComponent";
import Spinner from "../layout/Spinner";

import trashCanSVG from "../routes/assets/trashcan.svg";
import styles from "./DriverListRow.module.scss";
//import get from "lodash/get";

const TrashCanButton = ({ onClick, alt, width, height, isActive }) => (
  <IconButton
    className={`${styles.iconButton} ${!isActive && styles.iconButtonInactive}`}
    onClick={(event) => {
      event.stopPropagation();
      isActive && onClick && onClick();
    }}
  >
    <img 
      src={trashCanSVG} 
      alt={alt || "Delete"} 
      width={width} 
      height={height} 
    />
  </IconButton>
);

const LoadingAnim = () => {
  return <Spinner className={styles.spinner} />
};

const DriverListRow = ({ client, loading, driver, isAdmin, isInvalid, index }) => {
  const { username, userId, name, email, phone, isEnabled, status } = driver;
  const deleteMutation = isAdmin ? adminDeleteUserMutation : deleteUserMutation;
  const usersQuery = isAdmin ? adminGetUsersQuery : getUsersQuery;
  const deleteUserDataName = isAdmin ? "adminDeleteUser" : "deleteUser";
  const getUsersDataName = isAdmin ? "adminGetUsers" : "getUsers";

  const [isDeleting, setDeleting] = useState(false);

  const deleteUser = () => {
    client.mutate({
      mutation: deleteMutation,
      variables: {username, userId},
      onError: () => setDeleting(false),
      update: (cache, { data }) => {
        const username = get(data, [deleteUserDataName, "username"], null);
        if(username){
          const allUsers = get(cache.readQuery({ query: usersQuery }), getUsersDataName, []);
          cache.writeQuery({
            query: usersQuery,
            data: { [getUsersDataName]: allUsers.filter(e => `${e.id}` !== `${username}`) }
          });
        }
      }
    });
  }

  return (
    <TableRow
      hover
      onClick={!loading && username && !isInvalid ? () => navigate("/admin/driver/" + username) : null}
      tabIndex={-1}
      className={`
        ${styles.tableRow}
        ${!loading && isInvalid ? styles.invalid : ""}
        ${loading ? styles.loading : ""}
        ${isDeleting ? styles.deleting : ""}
      `} 
    >
      <React.Fragment>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell>{email}</TableCell>
        {!isAdmin && <TableCell>{phone}</TableCell>}

        {isAdmin && <AdminViewRowContent user={driver} delay={index * 300} />}
        <TableCell
          className={`${styles.userStatus} ${styles[isEnabled]} ${
            styles[status]
          }`}
        >
          {
            loading ? (
              <React.Fragment>Ladataan <LoadingAnim /></React.Fragment>
            )
            :
            isDeleting ? (
              <React.Fragment>Poistetaan <LoadingAnim /></React.Fragment>
            )
            :
            isInvalid ? (
              <React.Fragment>Käytöstä poistettu</React.Fragment>
            )
            :
            isEnabled ? 
              status === "CONFIRMED" ?
              (
                <React.Fragment>Aktiivinen</React.Fragment>
              )
              :
              (status === "UNCONFIRMED" || status === "FORCE_CHANGE_PASSWORD") && (
                <React.Fragment>Odottaa käyttäjää</React.Fragment>
              )
            :
            isEnabled === false && (
              <React.Fragment>Epäaktiivinen ({status})</React.Fragment>
            )
          }
        </TableCell>
        <TableCell className={styles.action}>
          {(!loading && (isInvalid || isEnabled === false)) && (
            <TrashCanButton
              onClick={() => {
                setDeleting(true);
                deleteUser()
              }}
              isActive={!loading && !isDeleting}
              width={20}
              height={20}
            />
          )}
        </TableCell>
        
      </React.Fragment>
    </TableRow>
  );
};

const AdminViewRowContent = ({
  user,
  delay
}) => (
  <React.Fragment>
    <TableCell>{user.contractorId}</TableCell>
    <TableCell>
      <DelayedComponent delay={delay}>
        {(isDelayed) => (
          isDelayed ?  (
            <LoadingAnim />
          ) : (
            <Query query={adminGetUserQuery} variables={{username: user.username}} fetchPolicy="cache-and-network">
              {({ loading, error, data }) => {
                if(loading) {
                  return  (<LoadingAnim />);
                }

                if(error) {
                  Sentry.captureEvent({
                    message: "Query error - DriverListRow",
                    extra: {
                      'error': error,
                      'data': data,
                      'variableUsername': user.username
                    },
                  });          
                }

                const {isAdmin, isContractor, isDriver} = adminGetUserProps(data);
                return (
                  isAdmin ? "Admin"
                  : isContractor ? "Liikennöitsijä"
                  : isDriver ? "Kuljettaja"
                  : null
                );
              }}
            </Query>
          )
        )}
      </DelayedComponent>
    </TableCell>
  </React.Fragment>
);

DriverListRow.propTypes = {
  loading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isInvalid: PropTypes.bool,
  driver: PropTypes.shape({
    userId: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    isEnabled: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isDriver: PropTypes.bool,
    status: PropTypes.string
  })
};

export default withApollo(DriverListRow);
