import React from "react";
import PropTypes from "prop-types";

import styles from "./ArrowCircle.module.scss";

const ArrowCircle = ({ className }) => (
  <span className={`${styles.root} ${className}`} />
);

ArrowCircle.propTypes = {
  className: PropTypes.string
};

ArrowCircle.defaultPropTypes = {
  className: null
};

export default ArrowCircle;
