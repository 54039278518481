import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";
import * as Sentry from '@sentry/browser';

// Compare two lists of destinations, return object with states as lists of ids
export default (prevDestinations, newDestinations, isOnline, currentVehicleLicenseNum, completeVehicleDestinationsQueryData) => {
  // combine all destinations to one array with unique entries by id. New entries override the old
  const destinations = uniqBy([...newDestinations, ...prevDestinations], "id");

  const added = [];
  const updated = [];
  const cancelled = [];

  if (prevDestinations.length > 0) {
    destinations.forEach(destination => {
      const id = destination.id;
      const prevDest = prevDestinations.find(dest => dest.id === id);
      const newDest = newDestinations.find(dest => dest.id === id);

      // not in previous destinations => a new addition to the route
      if (!prevDest) {
        added.push(id);
      }
      // not in added destinations => a cancelled destination
      else if (!newDest) {

        Sentry.captureEvent({
          message: "Cancelled destination detected",
          extra: {
            'id': id, 
            'prevDest': prevDest, 
            'allPrevDestinations': prevDestinations, 
            'allNewDestinations': newDestinations,
            'isOnline': isOnline,
            'currentVehicleLicenseNum': currentVehicleLicenseNum,
            'completeVehicleDestinationsQueryData': completeVehicleDestinationsQueryData
          },
        });

        cancelled.push(id);
      }
      // in previous destinations, but not equal => an updated destination
      else if (
        prevDest &&
        JSON.stringify(getDiffableDestination(prevDest)) !==
          JSON.stringify(getDiffableDestination(newDest))
      ) {
        updated.push(id);
      }
    });
  }

  // return an object with a list of ids for each status
  return {
    added,
    updated,
    cancelled
  };
};

/**
 * Parse relevant properties to be diffed from the destination.
 * @param {*}   origDestination   destination to be parsed
 * @returns {*} destination
 */
const getDiffableDestination = origDestination => {
  // the props to omit change during loading/unloading etc. "normal" activity during the route
  // so, let's ignore them when diffing
  const destinationPropsToOmit = ["ata", "atd"];
  const destination = omit(origDestination, destinationPropsToOmit);

  // let's omit some of the transaction props as well
  const destinationTransactionPropsToOmit = [
    "actualAmount",
    "actualUnit",
    "timeStarted",
    "timeEnded",
    "waybillNum",
    "weightNoteNumberLoading",
    "weightNoteNumberUnloading"
  ];
  destination.transactions = destination.transactions.map(transaction =>
    omit(transaction, destinationTransactionPropsToOmit)
  );

  return destination;
};
