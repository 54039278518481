import gql from "graphql-tag";
import routeDestinationTransactionFragment from './routeDestinationTransaction'

export const routeDestinationFragment = gql`
    fragment RouteDestination on RouteDestination {
        id
        destinationId
        order
        type
        name
        address
        addressAdditionalInfo
        zipcode
        city
        additionalInfo
        eta
        etd
        contactPerson
        contactPhone
        ata
        atd
        transactions {
            ...RouteDestinationTransaction
        }
    }
    ${routeDestinationTransactionFragment}
`;

export default routeDestinationFragment;

