import gql from "graphql-tag";

export const logSecurityExceptionMutation = gql`
  mutation logSecurityException(
    $securityException: SecurityException
  ) {
    logSecurityException(
      securityException: $securityException
    )
  }
`;

