import React from "react";
import PropTypes from "prop-types";

class DelayedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDelayed: true
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ isDelayed: false });
    }, this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { isDelayed } = this.state;
    const { children } = this.props;
    return children(isDelayed);
  }
}

DelayedComponent.propTypes = {
  delay: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired
};

export default DelayedComponent;
