import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "./DriverList.module.scss";

class DriverList extends React.Component {
  render() {
    const { children, isAdmin } = this.props;

    return (
      <React.Fragment>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell>Sähköposti</TableCell>
                {!isAdmin && <TableCell>Puhelin</TableCell>}
                {isAdmin && (
                  <React.Fragment>
                    <TableCell>Liikennöitsijä</TableCell>
                    <TableCell>Rooli</TableCell>
                  </React.Fragment>
                )}
                <TableCell>Tila</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

DriverList.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool
};

export default DriverList;
