import filter from "lodash/filter";
import getOrdersFromDestinations from "./getOrdersFromDestinations";


/*
    This util is used to determine which orders are "active"
    This means that the order has some transactions which have been started (or completed) 
    but all transactions have not been copmpleted (either pending or in progress).
    
    This util just returns the key information on each order
*/

export default (destinations=[])=>{
    const orders = getOrdersFromDestinations(destinations);
    const active = filter(orders,(order)=>{
        const completedTxns = filter(order.transactions,(txn)=>{
            // If the transaction has both timeEnded & timeStarted - it is completed
            return (txn.timeStarted && txn.timeEnded) ? true : false;
        });
        const inProgressTxns = filter(order.transactions,(txn)=>{
            // If the transaction has timeStarted but no timeEnded - it is completed so inprogress
            return (txn.timeStarted && !txn.timeEnded) ? true : false; 
        });
        const upcomingTxns = filter(order.transactions,(txn)=>{
            // If the transaction has neither timeEnded & timeStarted - it is upcoming
            return (!txn.timeStarted && !txn.timeEnded) ? true : false;
        });
        
        // The following filter logic is written to show which cases are considered and active order.
        return (inProgressTxns.length) ? true // started loading/unloading - order in progress
            :  (completedTxns.length && (inProgressTxns.length || upcomingTxns.length)) ? true // Some completed & still some pending - order in progress
            :  false;
    });
    return active;
}
