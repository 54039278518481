import React from "react";
import PropTypes from "prop-types";
import Modal from "../components/layout/Modal";
import Button from "../components/input/Button";

class GeoLocated extends React.Component {
  subscriptions = [];
  watcher = null;
  geoSettings = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 30000
  };
  location = null;

  // Accuracy related variables
  static accuracyModalDelay = 1 * 60 * 1000; // minutes * seconds * milliseconds
  bestAccuracy = Number.MAX_VALUE;
  accuracyTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      permission: false,
      geolocated: false,
      restartWatch: false,
      positionError: false
    };

    this.getLocation = this.getLocation.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onError = this.onError.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
    this.initWatcher = this.initWatcher.bind(this);
  }

  componentWillUnmount() {
    this.stopWatcher();
  }

  async checkPermission(){
    this.setState({permission: "undefined", positionError: false});
    const permission = await this.getPermission();

    if (this.state.permission !== "denied") {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          this.onUpdate(location);
        },
        (err) => {
          this.onError(err);
        },
        this.geoSettings
      );
      this.initWatcher();
    }

    if (permission !== this.state.permission) {
      this.setState({ permission });
    }
  }

  initWatcher() {
    //console.log("Restart geolocation watcher");
    // Reset the best accuracy variable
    this.resetAccuracy();
    this.stopWatcher();
    this.watcher = navigator.geolocation.watchPosition(
      this.onUpdate, 
      this.onError, 
      {
        enableHighAccuracy: true,
      }
    );
  }

  stopWatcher(){
    if(this.watcher !== null){
      navigator.geolocation.clearWatch(this.watcher);
      this.watcher = null;
    }
  }

  resetAccuracy(){
    this.bestAccuracy = Number.MAX_VALUE;
    this.setState({restartWatch: false});
  }

  onAccuracyUpdate(accuracy){
    const minAccuracy = 25; // anything under 25 meters is accurate enough
    const accuracyDiffLimit = 2.5; // Relative change in accuracy that is counted as too large

    // Check to see if there is a significant decline in the accuracy
    let accuracyDiff = (accuracy)/(this.bestAccuracy + 0.01); // prevent division by zero

    // Stop the restart modal timeout if accuracy is close enough
    if(this.accuracyTimeout !== null && accuracyDiff < accuracyDiffLimit){
      clearTimeout(this.accuracyTimeout);
      this.accuracyTimeout = null;
    }

    // Update bestAccuracy if current accuracy is better
    if(accuracyDiff < 1){
      this.bestAccuracy = Math.max(accuracy, minAccuracy);
    }
    // Start the restart modal timeout, if accuracy has degreesed by more than 50%
    else if(!this.state.restartWatch && this.accuracyTimeout === null && accuracyDiff >= accuracyDiffLimit) {
      // Start timer to show the restart tracking modal
      this.accuracyTimeout = setTimeout(
        () => this.setState({restartWatch: true}),
        GeoLocated.accuracyModalDelay
      );
    }
  }

  onUpdate(location){
    //console.log("Location update\n", `lat: ${location.coords.latitude}, lng: ${location.coords.longitude}, accuracy: ${location.coords.accuracy}`);
    if(!isNaN(location.coords.latitude))
    {
      this.location = location.coords;
      this.location.timestamp = location.timestamp;

      this.onAccuracyUpdate(location.coords.accuracy);

      // Send location update to all subscribers
      for(let i=0; i<this.subscriptions.length; i++){
        this.runSubscription(this.subscriptions[i]);
      }

      // Update state if something relevant has changed
      let newState = {};
      !this.state.geolocated && (newState.geolocated = true);
      this.state.positionError && (newState.positionError = null);
      this.permission !== "granted" && (newState.permission = "granted");
      if(Object.keys(newState).length > 0){
        this.setState(newState);
      }
    }
  }

  onError(error){
    let permission = error.code === 1 ? "denied" : this.state.permission;
    if(permission === "denied"){
      //console.log("Location access denied.");
      this.stopWatcher();
    }
    this.setState({
      permission, 
      geolocated: permission !== "denied" && this.state.geolocated, 
      positionError: { message: error.message, code: error.code }
    });
  }

  async getPermission() {
    try {
       // This throws an error on Safari -> so the location can be queried later to force prompt
      return await navigator.permissions 
        .query({ name: "geolocation" })
        .then(result => result.state); 
    } catch (err) {
      console.log(`Error querying permissions API on this platform - ${err}`);
      return "undefined";
    } 
  }

  getLocation(){
    return this.location;
  }

  /** Geolocated.subscribe
   * Adds a function to get location updates.
   * 
   * Parameters:
   * func - function to be called - func({longitude: 0, latitude: 0})
   * interval - how often the location update is called in ms
   */
  subscribe(fn){
    if(!fn) { return; }
    if(!this.hasSubscription(fn)){
      this.subscriptions.push(fn);
      // Run the subscribed function immediatelly
      this.runSubscription(fn);
    }
  }
  unsubscribe(fn){
    let i = this.subscriptions.findIndex((func) => (func === fn));
    if(i >= 0){
      this.subscriptions.splice(i, 1);
    }
  }

  runSubscription(fn){
    if(this.location && fn){
      fn(this.location);
    }
  }

  hasSubscription(fn){
    return this.subscriptions.findIndex((func) => (func === fn)) >= 0;
  }

  render() {
    const { permission, geolocated, positionError, restartWatch } = this.state;
    const geoPermission = !["denied", "prompt"].includes(permission);
    let modal = null;

    // Explicitly ask for location permission to start the tracking on user action
    if(permission === false)
    {
      modal = (
        <Modal uncloseable>
          <p>Rahtari-sovellus vaatii paikannustiedon käyttöä.</p>
          <Button disabled={false} onClick={() => this.checkPermission()}>Salli paikannus</Button>
        </Modal>
      );
    }
    
    // Show a modal on location permission errors
    else if (!geoPermission || (positionError && positionError.code !== 1)) {
      // Function to localize geolocation error bsed on code to give more useful feedback to the user
      // Current error codes are here: https://developer.mozilla.org/en-US/docs/Web/API/PositionError
      const displayGeolocError = (positionError) => { 
        console.error(`Geopositioning error - ${positionError.message} (code:${positionError.code})`);
        switch (positionError.code) {
          case '1':
            return "Paikannus estetty - Salli paikannus selaimen tai laitteen asetuksista.";
          case '2':
            return "Paikannus epäonnistui - Varmista, että olet sallinut paikannuksen Rahtari-sovelluksessa.";
          case '3':
            return "Paikannus epäonnistui - Varmista, että olet sallinut paikannuksen Rahtari-sovelluksessa."
          default: // Unkown reason 
            return "Paikannus epäonnistui - Salli paikannus selaimen tai laitteen asetuksista.";
        }
      }
       
      modal = (
        // Display more relevant data on error. //target={window.location.pathname}
        <Modal uncloseable>
          GPS-seuranta kytketty pois päältä! 
          <br />
          Salli paikannus selaimen tai laitteen asetuksista käyttääksesi Rahtari-sovellusta.
          <br />
          <br />
          {positionError && displayGeolocError(positionError)}
          <br/>
          <Button disabled={false} onClick={() => this.checkPermission()}>Kokeile uudestaan</Button>
        </Modal>
      );
    }

    else if(restartWatch) {
      this.initWatcher();
    }

    return this.props.children
      ? (
        <React.Fragment>
          {this.props.children({ 
            geolocation: {
              isTracking: true, 
              geolocated: geolocated,
              restart: this.initWatcher,
              getLocation: this.getLocation, 
              subscribe: this.subscribe,
              unsubscribe: this.unsubscribe,
            }
          })}
          {modal}
        </React.Fragment>
      )
      : null;
  }
}

GeoLocated.propTypes = {
  children: PropTypes.func
};

GeoLocated.defaultPropTypes = {
  children: null
};

export default GeoLocated;



