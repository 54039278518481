import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Router, Redirect, navigate } from "@reach/router";
//import { Query, Mutation } from "react-apollo";
import { Mutation } from "react-apollo";
import Query from "../../apollo/extensions/CachedQuery"

import * as Sentry from '@sentry/browser';

import deepEqual from "lodash/isEqual";
import head from "lodash/head";
import get from "lodash/get";
import reduce from "lodash/reduce";
import filter from "lodash/filter";
import find from "lodash/find";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import memoize from "memoize-one";

import { fragmentRoute } from "../../api/graphql/fragments/route";
import { fragmentOrder } from "../../api/graphql/fragments/order";
import { getOrderQuery, getOrderProps } from "../../api/graphql/getOrder";
import { setTransactionStatusMutation } from "../../api/graphql/setTransactionStatus";

import getUniqueProps from "../../utils/getUniqueProps";
import getTimeStamp from "../../utils/getTimeStamp";
import reformatTimeStamp from "../../utils/reformatTimeStamp";

import updateTransactionInput from "../../utils/updateTransactionInput";
import fetchTransactionInputs from "../../utils/fetchTransactionInputs";
import amountValidation from "../data/utils/amountValidation";

import getDestinationsWithStates from "../../components/routes/utils/getDestinationsWithStates";
import diffDestinations from "../../components/routes/utils/diffDestinations";

import DeviceType from "../../enum/DeviceType";

import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import Map from "../../components/routes/map/Map";
import Schedule from "../../components/routes/schedule/Schedule";
import DestinationComponent from "../../components/routes/destination/Destination";
import OrderDetails from "../../components/routes/order/OrderDetails";
import OrderTransactions from "../../components/routes/order/OrderTransactions";
import DestinationWrapperComponent from "../../components/routes/destination/DestinationWrapper";
import Finished from "../../components/routes/destination/Finished";
import InvalidLoad from "./InvalidLoad";
import DestinationOrders from "./DestinationOrders";

import LogRouteException from "./LogRouteException";
import LogSecurityException from "./LogSecurityException";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDestinations: [],
      destinations: [],
      added: [],
      updated: [],
      cancelled: [],
      isLoading: false,
    };

    this.setLoadingState = this.setLoadingState.bind(this);
  }

  setLoadingState(isLoading) {
    this.setState((prevState) => prevState.isLoading !== isLoading ? {isLoading: isLoading} : null);
  }

  static getDerivedStateFromProps(props, state) {

    const { isOnline, currentVehicleLicenseNum, completeVehicleDestinationsQueryData } = props;

    // Check for destinations change
    const destinations = [...props.destinations];
    const prevDestinations = uniqBy(
      [...state.destinations, ...state.prevDestinations],
      "id"
    );

    // Are there changes in the routes? If yes, do get states do get the diff
    const destinationStatesDiff = !deepEqual(destinations, state.destinations)
      ? diffDestinations(state.destinations, destinations, isOnline, currentVehicleLicenseNum, completeVehicleDestinationsQueryData)
      : {
        added: [],
        updated: [],
        cancelled: []
      };

    const schedule = uniqBy([...destinations, ...prevDestinations], "id");
    const updated = uniq([...state.updated, ...destinationStatesDiff.updated]);

    // destination can't be both cancelled and added.
    // filter out mutually if either of these are in the new states:
    const added = uniq([...state.added, ...destinationStatesDiff.added]).filter(
      id => !destinationStatesDiff.cancelled.includes(id)
    );
    
    const destIds = Object.values(destinations).map(dest => dest.id);
    const cancelled = uniq([
      ...state.cancelled,
      ...destinationStatesDiff.cancelled
    ]).filter(id => !destinationStatesDiff.added.includes(id) && !destIds.includes(id));

    return {
      destinations,
      prevDestinations,
      schedule,
      added,
      updated,
      cancelled
    };
  }

  onInvalidTransactions = invalidTransactions => {
    this.setState({
      invalidTransactions
    });
  };

  dismissCancelledDestination = id => {
    // clear dismissed destination from state

    this.dismissDestinationStates(id, ["cancelled"]);
    const prevDestinations = [...this.state.prevDestinations].filter(
      destination => destination.id !== id
    );
    const destinations = [...this.state.destinations].filter(
      destination => destination.id !== id
    );

    this.setState({
      prevDestinations,
      destinations
    });
  };

  dismissDestinationStates = (id, states) => {
    states.forEach(state => {
      const ids = [...this.state[state]];
      const newIds = ids.filter(destId => `${destId}` !== `${id}`);

      if (ids.length !== newIds.length) {
        this.setState({
          [state]: newIds
        });
      }
    });
  };

  render() {
    const {
      refreshRoutes,
      destinationsUpdating,
      deviceType,
      geolocation,
      currentUser,
      currentVehicle,
      refreshStyles,
      mapMode,
      handleSnackbarClose,
      showSnackbar
    } = this.props;

    const {
      schedule,
      added,
      updated,
      cancelled,
      invalidTransactions,
      destinationComplete
    } = this.state;

    const destinations = getDestinationsWithStates(schedule, {
      added,
      updated,
      cancelled
    });
    
    return (
      <Router className="fullsize flexcolumn">
        <Schedule
          path="schedule/:status"
          destinations={destinations}
          dismissCancelledDestination={this.dismissCancelledDestination}
          refreshRoutes={refreshRoutes}
          destinationsUpdating={destinationsUpdating}
          allowExceptions={deviceType === DeviceType.VEHICLE}
          refreshStyles={refreshStyles}
        />
        <Destination
          path="schedule/:status/destination/:destinationId/"
          destinations={destinations}
          refreshRoutes={refreshRoutes}
          invalidTransactions={invalidTransactions}
          onInvalidTransactions={this.onInvalidTransactions}
          destinationComplete={destinationComplete}
          isLoading={this.state.isLoading || destinationsUpdating}
          setLoadingState={this.setLoadingState}
          allowActions={deviceType === DeviceType.VEHICLE}
          allowExceptions={deviceType === DeviceType.VEHICLE}
          refreshStyles={refreshStyles}
          dismissStates={id =>
            this.dismissDestinationStates(id, ["added", "updated"])
          }
          onClosePopup={() => {
            if (destinationComplete) {
              navigate("/routes/schedule/upcoming");
            }

            this.setState({
              invalidTransactions: null,
              destinationComplete: false
            });
          }}
        />
        <Order
          path="schedule/:status/destination/:destinationId/:orderNum"
          destinations={destinations}
          isLoading={this.state.isLoading || destinationsUpdating}
          setLoadingState={this.setLoadingState}
          refreshRoutes={this.props.refreshRoutes}
          onInvalidTransactions={this.onInvalidTransactions}
          allowActions={deviceType === DeviceType.VEHICLE}
          allowExceptions={deviceType === DeviceType.VEHICLE}
          refreshStyles={refreshStyles}
          updateTransactionLoadInput={(pickupTransactionId, values) =>
            updateTransactionInput(pickupTransactionId, values)
          }
          updateTransactionUnloadInput={(unloadTransactionId, values) =>
            updateTransactionInput(unloadTransactionId, values)
          }
        />
        {deviceType === DeviceType.VEHICLE &&
          <Map
            path="map/*"
            geolocation={geolocation}
            destinations={destinations}
            dismissCancelledDestination={this.dismissCancelledDestination}
            refreshRoutes={refreshRoutes}
            destinationsUpdating={destinationsUpdating}
            currentVehicle={currentVehicle}
            refreshStyles={refreshStyles}
            mapMode={mapMode}
            onlyTransactions={false}
            handleSnackbarClose={handleSnackbarClose}
            showSnackbar={showSnackbar}
          />
        }
        {/**
         * Map with restricted functionality for observing the loading/unloading locations from the "AJOT" panel
         */}

          <Map
            path="map-only-transactions/:destinationId"
            geolocation={geolocation}
            destinations={destinations}
            dismissCancelledDestination={this.dismissCancelledDestination}
            refreshRoutes={refreshRoutes}
            destinationsUpdating={destinationsUpdating}
            currentVehicle={currentVehicle}
            refreshStyles={refreshStyles}
            mapMode={mapMode}
            onlyTransactions={true}
            deviceType={deviceType}
          />
        
        {/* Excpetions for routes will need multiple routes to handle the different path parameters */}
        {deviceType === DeviceType.VEHICLE &&
          ["exception", "exception/:destinationId"].map(path =>
            <LogRouteException
              key={path}
              path={path}
              geolocation={geolocation}
              currentUser={currentUser}
              currentVehicle={currentVehicle}
              destinations={destinations}
              refreshStyles={refreshStyles}
            />
          )}
        {/* Security excpetions will need multiple routes to handle the different path parameters */}
        {deviceType === DeviceType.VEHICLE &&
          ["security", "security/:destinationId"].map(path =>
            <LogSecurityException
              key={path}
              path={path}
              geolocation={geolocation}
              currentUser={currentUser}
              currentVehicle={currentVehicle}
              destinations={destinations}
              refreshStyles={refreshStyles}
            />
          )}
        <Redirect noThrow from="*" to={`/routes/schedule/upcoming`} />
      </Router>
    );
  }
}

Routes.propTypes = {
  destinations: PropTypes.array.isRequired,
  refreshRoutes: PropTypes.func.isRequired,
  currentLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  })
};

export default Routes;

// Find the correct destination to show and render the Destination component including all the relevant orders
const Destination = class extends React.Component {
  componentDidMount() {
    const { destinationId, dismissStates } = this.props;
    dismissStates(destinationId);
  }

  render() {
    const {
      destinations,
      destinationId,
      invalidTransactions,
      destinationComplete,
      isLoading,
      setLoadingState,
      onClosePopup,
      allowActions,
      allowExceptions,
      refreshRoutes,
      onInvalidTransactions,
      refreshStyles
    } = this.props;

    const destination = getDestination(destinations, destinationId);

    // if there's no destination for the given id, redirect to schedule
    if (!destination) {
      navigate("/routes");
      return null;
    }

    // Transactions do not contain the routing order number in them
    // We need them for the schedule view
    // Destinations do however have the routing order
    // Let's enrich transactions with the routing order by pulling it from the destinations 
    // for properly sorting the unload transactions later in the schedule view
    destination.transactions = destination.transactions.map(item => {

      let order = null;

      // Go through the destinations array and each destination transaction for same orderNum - and on hit take the destination order
      destinations.forEach(d => {
        d.transactions.forEach(t => {
          if(t.orderNum === item.orderNum){order = d.order}
        });
      })

      // Return the original item enriched with the order
      return {
        ...item,
        order
      }

    })

    return (
      <React.Fragment>
        {invalidTransactions && invalidTransactions.length > 0 && (
          <InvalidLoad
            destination={destination}
            invalidTransactions={invalidTransactions}
            onClose={onClosePopup}
          />
        )}
        {destinationComplete && (
          <Finished
            type={destination.type}
            eta={destination.eta}
            etd={destination.etd}
            ata={destination.ata}
            atd={destination.atd}
            onClose={onClosePopup}
          />
        )}
        <DestinationWrapper
          headerLinkTarget={"../.."}
          headerLinkText="Takaisin ajoihin"
          destination={destination}
          isLoading={isLoading}
          setLoadingState={setLoadingState}
          allowActions={allowActions}
          allowExceptions={allowExceptions}
          refreshRoutes={refreshRoutes}
          onInvalidTransactions={onInvalidTransactions}
          refreshStyles={refreshStyles}
        >
          <DestinationComponent destination={destination}>
            <DestinationOrders
              destination={destination}
              transactionLoadInputs={fetchTransactionInputs()}
              refreshStyles={refreshStyles}
            />
          </DestinationComponent>
        </DestinationWrapper>
      </React.Fragment>
    );
  }
};

const Order = ({
  destinations,
  destinationId,
  isLoading,
  setLoadingState,
  orderNum,
  updateTransactionLoadInput,
  updateTransactionUnloadInput,
  allowActions,
  allowExceptions,
  refreshRoutes,
  onInvalidTransactions,
  refreshStyles
}) => {
  const destination = getDestination(destinations, destinationId);

  // if there's no destination for the given id, redirect to schedule
  if (!destination) {
    navigate("/routes");
    return null;
  }

  const destinationTransactions = destination.transactions;
  const destinationTransactionIds = getUniqueProps(
    destinationTransactions,
    "transactionId"
  );

  return (
    <DestinationWrapper
      headerLinkTarget="../"
      headerLinkText="Takaisin käyntipaikkaan"
      destination={destination}
      isLoading={isLoading}
      setLoadingState={setLoadingState}
      className={destination.status}
      allowActions={allowActions}
      allowExceptions={allowExceptions}
      refreshRoutes={refreshRoutes}
      onInvalidTransactions={onInvalidTransactions}
      refreshStyles={refreshStyles}
    >
      <React.Fragment>
        <Query
          query={getOrderQuery}
          variables={{ orderId: `${orderNum}` }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, cached, error, data }) => {
            if (loading && !cached) return <Loading />;
            if(error) {
              Sentry.captureEvent({
                message: "Query error - Routes",
                extra: {
                  'error': error,
                  'data': data,
                  'variableOrderId': orderNum
                },
              });          
            }
            if (error) return <Error error={error} />;

            const order = getOrderProps(data);

            // if there's no valid order for the given id, redirect to parent
            if (!order) {
              navigate("/routes");
              return null;
            }

            // Fetch entered transaction  data
            const transactionLoadInputs = fetchTransactionInputs();

            const isOngoingLoad =
              destination.type === "load" &&
              !!destination.ata &&
              !destination.atd;
            const isOngoingUnload =
              !isOngoingLoad &&
              destination.type === "unload" &&
              !!destination.ata &&
              !destination.atd;
            const isIncompleteUnload =
              destination.type === "unload" &&
              !destination.atd;

            if (isOngoingLoad || isIncompleteUnload) {
              // loading is ongoing. Use the user input values (if available)
              get(order, 'rows', []).forEach(row =>
                row.transactions.forEach(transaction => {
                  // The apporach here is a bit weird and a little redundant with the improved caching 
                  // in the app. We use local storage to record inputs (waybiull numbers and amounts) 
                  // for each transaction. This data is then retrieved when the transactionStatus is changed 
                  // via the Mutation. I think it has originally been used so that users could enter data on 
                  // various screens and it would be retreived when the Mutation was submitted.

                  // At some point this approach may need reworked.

                  // First for the transaction in the orderRow, check if there is any waybill/amount data 
                  // in localstorage for either pickup transaction ID or unload transaction ID.
                  const pickupInput = transactionLoadInputs[transaction.pickupTransactionId] || {}
                  const unloadInput = transactionLoadInputs[transaction.unloadTransactionId] || {}

                  // For pickups users can change BOTH the waybill and amount.
                  // For unloading, users can ONLY change the amnount - the waybill is locked to whatever 
                  // was stored when the goods were loaded into the truck.

                  // To set the waybill, first check if the waybill was updated when the goods were loaded (from localstorage).
                  // If there is no record in local storage, use the waybill in the data returned from SISU. This value can only 
                  // be changed by users when goods are loaded. It cannot be changed when goods are unloaded.
                  transaction.waybillNum = (pickupInput.waybill) ? pickupInput.waybill : transaction.waybillNum;

                  // For the amount, users can update this when loading & unloading goods. This means a priority order is used 
                  // to determine which value to use:
                  // 1. Use data eneterd for unloading transaction id (only when unloading)
                  // 2. Use data eneterd for loading transaction id (both when loading & unloading)
                  // 3. Use data from SISU if available.
                  transaction.actualAmount =
                    (isOngoingUnload && unloadInput.amountLoaded !== undefined && `${unloadInput.amountLoaded}`.length > 0) ? unloadInput.amountLoaded :
                      (pickupInput.amountLoaded !== undefined && `${pickupInput.amountLoaded}`.length > 0) ? pickupInput.amountLoaded :
                        transaction.actualAmount || undefined;

                  // WeightNoteNumbers are not loaded from SISU. It is an open non-required field for users to fill in. Load from localstorage if available.
                  transaction.weightNoteNumberLoading = (pickupInput.weightNoteNumberLoading) ? pickupInput.weightNoteNumberLoading : transaction.weightNoteNumberLoading ? transaction.weightNoteNumberLoading : '';
                  transaction.weightNoteNumberUnloading = (unloadInput.weightNoteNumberUnloading) ? unloadInput.weightNoteNumberUnloading : transaction.weightNoteNumberUnloading ? transaction.weightNoteNumberUnloading : '';

                })
              );
            }

            return (
              <OrderDetails
                order={order}
                numRows={destinationTransactionIds.length}
              >
                <OrderTransactions
                  rows={order.rows}
                  type={destination.type}
                  transactionIds={destinationTransactionIds}
                  isOngoingLoad={isOngoingLoad}
                  isOngoingUnload={isOngoingUnload}
                  allowActions={allowActions}
                  onUpdateLoad={(pickupTransactionId, values) =>
                    updateTransactionLoadInput(pickupTransactionId, values)
                  }
                  onUpdateUnload={(unloadTransactionId, values) =>
                    updateTransactionUnloadInput(unloadTransactionId, values)
                  }
                />
              </OrderDetails>
            );
          }}
        </Query>
      </React.Fragment>
    </DestinationWrapper>
  );
};

const DestinationWrapper = props => {
  
  useEffect(() => {
    props.refreshStyles();
  });

  return (
    <Mutation
      mutation={setTransactionStatusMutation}
      ignoreResults
    >
      {(setTransactionStatus, { client }) => (
        <DestinationWrapperComponent
          {...props}
          toggleState={() => {
            const {
              destination,
              allowActions,
              refreshRoutes,
              onInvalidTransactions,
              setLoadingState
            } = props;
            
            if (!allowActions) return;

            setLoadingState(true);

            const nextStatus =
              destination.type === "load"
                ? !destination.ata
                  ? "beginLoading"
                  : "finishLoading"
                : !destination.ata
                  ? "beginUnloading"
                  : "finishUnloading";

            // if the user is trying to finish loading ...
            if (
              nextStatus === "finishLoading" ||
              nextStatus === "finishUnloading"
            ) {
              // ... navigate to destination view
              navigate(`/routes/schedule/upcoming/destination/${destination.id}`);
              // }


              // if (
              //   nextStatus === "finishLoading" ||
              //   nextStatus === "finishUnloading"
              // ) {
              // ... check if all the load details are given for all the transactions
              const invalidTransactions = getInvalidTransactions(destination);

              if (invalidTransactions.length > 0) {
                // there are invalid transactions! update state and return (as loading can't be finished yet!)
                onInvalidTransactions(invalidTransactions);
                setLoadingState(false);
                return;
              }
            }
            const transactionLoadInputs = fetchTransactionInputs();

            // Built mutation variables that are sent to SISU
            const variables = {
              routeId: destination.routeId,
              status: nextStatus,
              timestamp: getTimeStamp(),
              transactions: destination.transactions.map(transaction => {
                const { transactionId, orderedUnit } = transaction;
                const transactionLoadInput = transactionLoadInputs[
                  transactionId
                ] || {
                  amountLoaded: 0,
                  waybill: "",
                  weightNoteNumberLoading: "",
                  weightNoteNumberUnloading: ""
                };

                const { amountLoaded, waybill, weightNoteNumberLoading, weightNoteNumberUnloading } = transactionLoadInput;

                return {
                  transactionId,
                  loadUnit: orderedUnit,
                  loadAmount:
                    // loading starts, no amount yet
                    ["beginLoading", "beginUnloading"].includes(nextStatus)
                      ? null
                      : // loading finishes, driver input value
                      ["finishLoading", "finishUnloading"].includes(nextStatus)
                        ? amountLoaded
                        : // loading is already done, actual load
                        Math.abs(transaction.actualAmount),
                  waybillNumber:
                    ["finishLoading"].includes(nextStatus)
                      ? // loading finishes, driver input value
                      waybill
                      : // loading is already done, actual waybillnum
                      transaction.waybillNum,
                  weightNoteNumberLoading: weightNoteNumberLoading ? weightNoteNumberLoading : "",
                  weightNoteNumberUnloading: weightNoteNumberUnloading ? weightNoteNumberUnloading : ""
                };
              })
            };

            // Submit mutation
            setTransactionStatus({
              variables
            });

            // Optimistcally update transactions for Routes & Orders in the cache
            optimisticallyUpdateCache(client, destination.id, variables);

            refreshRoutes();

            // If starting load/unload and there's only one order, redirect:
            if (
              nextStatus === "beginLoading" ||
              nextStatus === "beginUnloading"
            ) {
              const destinationOrderIds = getUniqueProps(
                props.destination.transactions,
                "orderNum"
              );
              // How many orders are there for this destination?
              if (destinationOrderIds.length === 1) {
                // ... only one order, so redirect to that automatically
                navigate(
                  `/routes/schedule/upcoming/destination/${destination.id}/${head(
                    destinationOrderIds
                  )}`
                );
              } else {
                // Go to the destination containing the order lines
                navigate(
                  `/routes/schedule/upcoming/destination/${destination.id}`
                );
              }
            }
            setLoadingState(false);
          }}
        >
          {props.children}
        </DestinationWrapperComponent>
      )}
    </Mutation>
  );
};

// get single destination from routes
const getDestination = memoize((destinations, destinationId) => {
  return destinations.find(
    destination => `${destination.id}` === `${destinationId}`
  );
}, deepEqual);

const getInvalidTransactions = destination => {
  const transactionLoadInputs = fetchTransactionInputs();
  const transactions = destination.transactions;
  const invalidTransactions = transactions.filter(transaction => {
    const transactionLoadInput =
      transactionLoadInputs[transaction.transactionId];

    const amountValid = transactionLoadInput && !amountValidation(transactionLoadInput.amountLoaded, transaction.orderedUnit);

    if (destination.type === "unload") {
      return (
        !transactionLoadInput || !amountValid
      );
    }
    return (
      !transactionLoadInput ||
      !transactionLoadInput.waybill ||
      !amountValid
    );
  });

  return invalidTransactions;
};


const optimisticallyUpdateCache = (client, destinationId, transactionUpdateMutation) => {

  /*
    Perform an optimistic Cache update. This is done via Query fragraments
    as the 'Optimitic Response' provided by the mutation doesn't merge into 
    the cache, but overwrites cache entries.

    Using the Query fragements, first look up the cached Route data, merge 
    in the updated values and then write the cache. Second look up the Orders 
    data, overwrite updated values and write the cache.

    Both Routes & Orders need updated, as these contain duplicated data returned 
    by SISU and it appears both are used throughout the app :(

    Once the actual Query is fetched from the backend after the Mutation, it will 
    overwrite any optimistically cached values

    This single function is bascially everything that allows the app to work without 
    a network connection.

  */

  /*
      1. get the cached Routes
  */
  const currentRouteCache = client.readFragment({
    fragmentName: "Route",
    fragment: fragmentRoute,
    id: `Route:${transactionUpdateMutation.routeId}`
  });

  /*
    2. Fetch the relevent order numbers for the transactions
  */
  const orderNums = get(currentRouteCache, 'destinations', []).map(dest => {
    return get(dest, 'transactions', []).map(transaction => {
      // Check if the transaction ID matches a transaction that is being updated
      const isUpdatedTransaction = find(transactionUpdateMutation.transactions, (t) => {
        return t.transactionId === transaction.transactionId
      });
      return (isUpdatedTransaction) ? transaction.orderNum : null
    });
  });

  /*
    3. Get all orders that need updated
  */
  const currentOrdersCache = filter(orderNums, orderNum => !!orderNum).map((orderNum) => {
    try {
      return client.readFragment({
        fragmentName: "Order",
        fragment: fragmentOrder,
        id: `Order:${orderNum}`
      });
    } catch (err) {
      return {}
    }
  });


  const isStarting = (["beginLoading", "beginUnloading"].includes(transactionUpdateMutation.status));
  const isFinishing = (["finishLoading", "finishUnloading"].includes(transactionUpdateMutation.status));

  /*
    4. Create the updated cache for the Routes.
  */
  const optimisticResponseTimestamp = reformatTimeStamp(transactionUpdateMutation.timestamp, "YYYY-MM-DD HH:mm:ss", "DD.MM.YYYY HH:mm");

  let optimisticRouteResp = {
    ...currentRouteCache,
    //status: this is set after all transactions have been updated
    destinations: currentRouteCache.destinations.map(cachedDest => {
      const isDestInMutation = (destinationId === cachedDest.id);
      return {
        ...cachedDest,
        ...(isDestInMutation && isStarting) && { ata: optimisticResponseTimestamp },
        ...(isDestInMutation && isFinishing) && { atd: optimisticResponseTimestamp },
        transactions: cachedDest.transactions.map(cachedTransaction => {
          // Get cached ID, check if it is in the and merge, otherwise just return it
          const updatedTransaction = find(transactionUpdateMutation.transactions, (t) => {
            return t.transactionId === cachedTransaction.transactionId
          });
          return {
            ...cachedTransaction,
            ...(updatedTransaction) && {
              waybillNum: updatedTransaction.waybillNumber,
              actualAmount: updatedTransaction.loadAmount,
              actualUnit: updatedTransaction.loadUnit,
              ...(isStarting) && { timeStarted: optimisticResponseTimestamp },
              ...(isFinishing) && { timeEnded: optimisticResponseTimestamp },
            }
          }
        })
      }
    }),
  };

  /*
  5. After updating the transaction statuses, check if there are any 
  remaining transactions that are not complete (A completed route has no pending transactions).

  Use this to set the Route status to either "upcoming" or "completed". */

  const isRouteIncomplete = reduce(
    optimisticRouteResp.destinations,
    (incompleteDests, destination) => {
      // Once one transaction is still pending (incompleteDests to true), 
      // the whole route is still ongoing so don't bother with any further evaluations 
      if (incompleteDests) return incompleteDests;
      // Next check if there are any incomplete transactions
      const isIncompleteTxns = reduce(
        get(destination, "transactions", []),
        (incompleteTxns, transaction) => {
          if (incompleteTxns) return incompleteTxns; // Same as for destinations.
          // Else, if timeEnded is defined, transaction is complete
          return (!get(transaction, "timeEnded")) ? true : false;
        },
        false
      );
      return isIncompleteTxns
    },
    false
  );

  // Update the optimistic response with the correct status
  optimisticRouteResp.status = (isRouteIncomplete) ? "upcoming" : "completed";

  /*
    6. Build optimistic responses for the Orders

  */
  const optimisticOrderResps = currentOrdersCache.map(currentOrderCache => {
    return {
      ...currentOrderCache,
      rows: get(currentOrderCache, "rows", []).map(orderRow => {
        return {
          ...orderRow,
          transactions: get(orderRow, "transactions", []).map(orderRowTransaction => {
            // Get cached ID, check if it is in the and merge, otherwise just return 
            // the original transaction data

            // Order Row Transaction have the fun of two transaction IDs (pickup & unload) 
            // so there is a need to match both
            const updatedPickupTransaction = find(transactionUpdateMutation.transactions, (t) => {
              return t.transactionId === orderRowTransaction.pickupTransactionId
            });
            const updatedUnloadTransaction = find(transactionUpdateMutation.transactions, (t) => {
              return t.transactionId === orderRowTransaction.unloadTransactionId
            });
            return {
              ...orderRowTransaction,
              ...(updatedPickupTransaction) && {
                waybillNum: updatedPickupTransaction.waybillNumber,
                actualAmount: updatedPickupTransaction.loadAmount,
                actualUnit: updatedPickupTransaction.loadUnit,
                ...(isStarting) && { actualPickupStartTime: optimisticResponseTimestamp },
                ...(isFinishing) && { actualPickupEndTime: optimisticResponseTimestamp },
              },
              ...(updatedUnloadTransaction) && {
                waybillNum: updatedUnloadTransaction.waybillNumber,
                actualAmount: updatedUnloadTransaction.loadAmount,
                actualUnit: updatedUnloadTransaction.loadUnit,
                ...(isStarting) && { actualUnloadStartTime: optimisticResponseTimestamp },
                ...(isFinishing) && { actualUnloadEndTime: optimisticResponseTimestamp },
              },
            }
          })
        }
      })
    }
  });

  /*
   7. Perform writes to cache
  */

  client.writeFragment({
    fragmentName: "Route",
    fragment: fragmentRoute,
    id: `Route:${transactionUpdateMutation.routeId}`,
    data: optimisticRouteResp
  });

  optimisticOrderResps.forEach(optimisticOrderResp => {
    client.writeFragment({
      fragmentName: "Order",
      fragment: fragmentOrder,
      id: `Order:${optimisticOrderResp.orderNum}`,
      data: optimisticOrderResp
    });
  });

  // Finally, update the transaction inputs that have been stored to the cache. 



  return { optimisticRouteResp, optimisticOrderResps }
}