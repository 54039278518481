import React from "react";
import PropTypes from "prop-types";

import styles from "./IconButton.module.scss";

class IconButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { clicked: false };
  }

  onClick(e) {
    this.setState({ clicked: true });
    if (this.props.onClick) this.props.onClick(e);
  }

  render() {
    const { className, isUpdating, icon, text, ...props } = this.props;


    return (
      <button
        {...props}
        className={`${className} ${styles.root} ${
          className ? styles[className] : ""
        }`}
        disabled={
          // outside prop always overrides internal state
          isUpdating !== undefined ? isUpdating : this.state.clicked
        }
        onClick={props.type !== "submit" ? e => this.onClick(e) : null}
      >
        <div className={styles.buttonContent}>
          <img className={styles.buttonImg} src={icon} alt={text || "button"}></img>
          <div className={styles.buttonText}><span>{text}</span></div>
        </div>
      </button>
    );
  }
}

IconButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  isUpdating: PropTypes.bool
};

IconButton.defaultProps = {
  type: "button",
  className: ""
};

export default IconButton;
//

//