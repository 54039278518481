import gql from "graphql-tag";

export const updateVehicleLocationMutation = gql`
  mutation UpdateVehicleLocation(
    $vehicleId: ID!
    $timestamp: String
    $locX: Float
    $locY: Float
  ) {
    updateVehicleLocation(
      location: {
        vehicleId: $vehicleId
        timestamp: $timestamp
        locX: $locX
        locY: $locY
      }
    )
  }
`;
