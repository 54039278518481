import cogoToast from 'cogo-toast';
import { playNotification } from './beeper';

const defaultOptions = {
  hideAfter: 3,
  position: 'top-right',
  heading: '',
  onClick: null,
  playSound: true,
}

const extendOptions = (options) => {
  return Object.assign(
    Object.assign({}, defaultOptions),
    options
  );
}

const toast = (func, message, options) => {
  const opt = extendOptions(options);
  if(opt.playSound) { setTimeout(playNotification, 800); }
  return func(message, opt);
}

const success = (message, options) => toast(cogoToast.success, message, options);
const info = (message, options) => toast(cogoToast.info, message, options);
const loading = (message, options) => toast(cogoToast.loading, message, options);
const warn = (message, options) => toast(cogoToast.warn, message, options);
const error = (message, options) => toast(cogoToast.error, message, options);

export default {
  success,
  info,
  loading,
  warn,
  error,
};