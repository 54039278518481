export default id => {
  let data;
  try {
    data = JSON.parse(localStorage.getItem(id)) || {};
  } catch (e) {
    console.log("LocalStorage not supported!");
    data = {};
  }

  return data;
};