import React from "react";
import PropTypes from "prop-types";

import styles from "./OrderTransaction.module.scss";
import Panel from "../../layout/Panel";
import AdditionalInfo from "../AdditionalInfo";
import LoadingDetailsInput from "../../input/LoadingDetailsInput";
import UnloadingDetailsInput from "../../input/UnloadingDetailsInput";

const OrderTransaction = props => {

  return (
  <Panel className={styles.root} multiple="true">
    <div className={styles.dataTable}>
      <span className={styles.dataProductName}>{props.commodityName}</span>
      <span className={styles.dataAmount}>
        {props.type === "unload" && props.actualAmount
          ? `${Number(props.actualAmount).toLocaleString(
              "fi-FI"
            )} ${props.actualUnit || ""}`
          : `${
              !isNaN(props.orderedAmount)
                ? Number(props.orderedAmount).toLocaleString("fi-FI")
                : "-"
            } ${props.orderedUnit}`}
      </span>
      <span className={styles.dataWeight}>
        {props.weight
          ? `${Number(props.weight).toLocaleString("fi-FI")} kg`
          : "–"}
      </span>
      <span className={styles.dataItems}>
        {props.items ? props.items : "–"}
      </span>
      <span className={styles.dataDimensions}>
        {props.width && props.height && props.length
          ? `${props.width} x ${props.height} x ${props.length}`
          : "–"}
      </span>
    </div>
    <div className={styles.addresses}>
      <div className={styles.destination}>
        {!(props.allowActions && props.isOngoingLoad) && props.waybillNum && (
          <div className={styles.waybill}>Rahtikirja: {props.waybillNum}</div>
        )}
        <div className={styles.address}>
          {[
            props.receiver, 
            props.unloadAddress, 
            `${props.unloadZipcode} ${props.unloadCity}`
            ].filter(item => item)
             .join(", ")}
        </div>
        {(props.unloadPerson || props.unloadPhone) && (
          <div className={styles.person}>

            <span>
              {props.unloadPerson && props.unloadPerson}
              {(props.unloadPerson && props.unloadPhone) && ', '}
              {(props.unloadPhone && props.unloadPhone.length > 6) ? <a href={`tel:${props.unloadPhone}`}>{props.unloadPhone}</a> : ''}
            </span>

          </div>
        )}
        {props.unloadAddressAdditionalInfo && (
          <div className={styles.additionalInfo}>
            {props.unloadAddressAdditionalInfo}
          </div>
        )}
      </div>
    </div>
    {props.additionalInfo.length > 0 && (
      <AdditionalInfo>{props.additionalInfo}</AdditionalInfo>
    )}
    {props.allowActions &&
      props.isOngoingLoad ? (
        <LoadingDetailsInput
          onChange={values =>
            props.onUpdateLoad(props.pickupTransactionId, values)
          }
          loadUnit={props.orderedUnit}
          waybillNum={props.waybillNum}
          actualAmount={
            props.actualAmount !== undefined
              ? props.actualAmount
              : props.orderedAmount || 0
          }
          weightNoteNumberLoading={props.weightNoteNumberLoading}
        />
      )
      : 
      props.isOngoingUnload ? (
        <UnloadingDetailsInput
          onChange={values => {
              props.onUpdateUnload(props.unloadTransactionId, values)
            }
          }
          loadUnit={props.orderedUnit}
          waybillNum={props.waybillNum}
          actualAmount={
            props.actualAmount !== undefined
              ? props.actualAmount
              : props.orderedAmount || 0
          }
          weightNoteNumberUnloading={props.weightNoteNumberUnloading}
        />
      )
      :
      null
    }
  </Panel>
)};

OrderTransaction.propTypes = {
  // transaction
  actualAmount: PropTypes.number,
  actualUnit: PropTypes.string,
  orderedAmount: PropTypes.number.isRequired,
  orderedUnit: PropTypes.string.isRequired,
  pickupTransactionId: PropTypes.string.isRequired,
  receiver: PropTypes.string.isRequired,
  unloadAddress: PropTypes.string,
  unloadAddressAdditionalInfo: PropTypes.string,
  unloadCity: PropTypes.string.isRequired,
  unloadPerson: PropTypes.string,
  unloadPhone: PropTypes.string,
  unloadZipcode: PropTypes.string,
  waybillNum: PropTypes.string,
  weightNoteNumberLoading: PropTypes.string,
  weightNoteNumberUnloading: PropTypes.string,

  // row
  additionalInfo: PropTypes.array,
  commodityName: PropTypes.string.isRequired,
  items: PropTypes.number,
  height: PropTypes.number,
  length: PropTypes.number,
  weight: PropTypes.number,
  width: PropTypes.number,

  // other
  allowActions: PropTypes.bool.isRequired,
  isOngoingLoad: PropTypes.bool.isRequired,
  onUpdateLoad: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default OrderTransaction;