const toRad = (num) => num * Math.PI / 180;
const R = 6371; // Radius of the earth in km

export default (lon1, lat1, lon2, lat2) => {
  const dLat = toRad(lat2-lat1);
  const dLon = toRad(lon2-lon1); 
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c; // Distance in km
  return d;
}