import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import * as Sentry from '@sentry/browser';
import {
  getUserSettingsQuery,
  getUserSettingsProps
} from "../../api/graphql/getUserSettings";

import Loading from "../../components/layout/Loading";
import NoPermission from "../../components/layout/NoPermission";
import Error from "../../components/layout/Error";
import CenteredContent from "../../components/layout/CenteredContent";
import SignOutButton from "../../components/SignOutButton";

const CurrentUser = ({ children }) => (
  <Query query={getUserSettingsQuery}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if(error) {
        Sentry.captureEvent({
          message: "Query error - CurrentUser",
          extra: {
            'error': error,
            'data': data,
          },
        });          
      }
      if (error && error.message.startsWith("GraphQL error: Not Authorized ")) return <NoPermission error={error} />;
      if (error) return <Error error={error} />;

      const currentUser = getUserSettingsProps(data);

      // Cognito userid is not found in the db!
      if (!currentUser) {
        return (
          <CenteredContent>
            <div>
              No user in database!
              <br />
              <SignOutButton>Continue</SignOutButton>
            </div>
          </CenteredContent>
        );
      }

      return children(currentUser);
    }}
  </Query>
);

CurrentUser.propTypes = {
  children: PropTypes.func.isRequired
};

export default CurrentUser;
