import gql from "graphql-tag";

export const logRouteExceptionMutation = gql`
  mutation logRouteException(
    $routeException: RouteException
  ) {
    logRouteException(
      routeException: $routeException
    )
  }
`;

