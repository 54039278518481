import React from "react";
import PropTypes from "prop-types";
import NoSleep from "nosleep.js";

import Button from "../components/input/Button";
import Modal from "../components/layout/Modal";

class StayAwake extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasUserInteracted: false };
  }

  componentWillMount() {
    document.body.addEventListener("mousedown", this.onUserInteraction);
    document.addEventListener("visibilitychange", this.onVisiblityChange);
  }

  componentWillUnmount() {
    document.body.removeEventListener("mousedown", this.onUserInteraction);
    document.removeEventListener("visibilitychange", this.onVisiblityChange);
  }

  onUserInteraction = () => {
    document.body.removeEventListener("mousedown", this.onUserInteraction);
    this.setState({ hasUserInteracted: true });
    this.toggleNoSleep(true);
  };

  onVisiblityChange = () => {
    this.toggleNoSleep(!document.hidden);
  };

  toggleNoSleep(noSleep) {
    // nosleep requires user to have interacted with the app before initialization
    if (!this.state.hasUserInteracted) return;

    if (noSleep) {
      this.noSleep && this.noSleep.disable();
      this.noSleep = new NoSleep();
      this.noSleep.enable();
    } else {
      this.noSleep && this.noSleep.disable();
      this.noSleep = null;
    }
  }

  render() {
    if (this.props.geolocation && this.props.geolocation.geolocated && !this.state.hasUserInteracted) {
      return (
        <Modal uncloseable>
          GPS-seuranta käynnissä.
          <br />
          <br />
          <Button onClick={this.props.geolocation.restart}>OK</Button>
        </Modal>
      );
    }
    return null;
  }
}

StayAwake.propTypes = {
  geolocation: PropTypes.shape({
    restart: PropTypes.func.isRequired,
    geolocated: PropTypes.bool.isRequired,
    getLocation: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
  }),
};

StayAwake.defaultProps = {
  geolocation: {
    geolocated: false
  }
};

export default StayAwake;
