import moment from "moment-timezone";

export default (tz='europe/helsinki') => {
    const datetime = moment().tz(tz);
    return {
        year: datetime.format("YYYY"),
        month: datetime.format("MM"),
        day: datetime.format("DD"),
        hour: datetime.format("HH"),
        minute: datetime.format("mm"),
        second: datetime.format("ss")
    }
}
    
