import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import * as Sentry from '@sentry/browser';

import { getRouteQuery, getRouteProps } from "../../api/graphql/getRoute";

import Loading from "../../components/layout/Loading";
import parseDestinations from "./utils/parseDestinations";
import RouteSelection from "../../components/RouteSelection";
import SignOutButton from "../../components/SignOutButton";

const RouteDestinations = ({ routeId, children }) => {
  return (
    <Query
      query={getRouteQuery}
      variables={{
        routeId
      }}
      pollInterval={15*60*1000} //refresh every 15 minutes
      notifyOnNetworkStatusChange
      fetchPolicy="cache-and-network"
    >
      {({ error, data, refetch, networkStatus }) => {
        if (networkStatus === 1) return <Loading />;
        if(error) {
          Sentry.captureEvent({
            message: "Query error - RouteDestinations",
            extra: {
              'error': error,
              'data': data,
              'networkStatus': networkStatus,
              'variableRouteId': routeId
            },
          });          
        }
        if (error)
          return (
            <React.Fragment>
              <RouteSelection
                heading={`Reittiä ${routeId} ei löytynyt. Yritä uudelleen!`}
              />
              <SignOutButton>Kirjaudu ulos</SignOutButton>
            </React.Fragment>
          );

        const route = getRouteProps(data);
        const destinations = parseDestinations([route]);

        return children({
          destinations,
          refetch,
          loadingDestinations: networkStatus < 7
        });
      }}
    </Query>
  );
};

RouteDestinations.propTypes = {
  routeId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default RouteDestinations;
