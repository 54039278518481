import React from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

import styles from "./Modal.module.scss";

const Modal = ({ target, children, uncloseable, contained, zIndex }) => (
  <div className={styles.root} style={{zIndex: zIndex}}>
    <div
      className={contained ? styles.veilContained : styles.veil}
      onClick={
        uncloseable
          ? null
          : () => {
              target ? navigate(target) : window.history.back();
            }
      }
    >
      <div
        className={styles.modal}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
  uncloseable: PropTypes.bool,
  zIndex: PropTypes.number,
};

Modal.defaultPropTypes = {
  target: null,
  uncloseable: false,
  contained: false,
  zIndex: 999,
};

export default Modal;
