import retrieveLocalData from "./retrieveLocalData";
import storeLocalData from "./storeLocalData";
import parseNumber from "./parseNumber";
import * as R from 'ramda';

export default (
    transactionId,
    { waybill, amountLoaded, weightNoteNumberLoading, weightNoteNumberUnloading }
  ) => {

    // Use cache as basis
    let transactionInputs = {
      ...retrieveLocalData("transactionLoadInputs")
    };

    //Overwrite values if new value is provided, otherwise leave as is
    
    if(waybill){
    	transactionInputs = R.set(R.lensPath([transactionId, 'waybill']), waybill, transactionInputs);
    }
    if(amountLoaded){
    	transactionInputs = R.set(R.lensPath([transactionId, 'amountLoaded']), parseNumber(amountLoaded), transactionInputs);
    }
    if(weightNoteNumberLoading){
    	transactionInputs = R.set(R.lensPath([transactionId, 'weightNoteNumberLoading']), weightNoteNumberLoading, transactionInputs);
    }
    if(waybill){
    	transactionInputs = R.set(R.lensPath([transactionId, 'weightNoteNumberUnloading']), weightNoteNumberUnloading, transactionInputs);
    }
  
    storeLocalData("transactionLoadInputs", transactionInputs);
};

