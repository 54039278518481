import React from "react";
import PropTypes from "prop-types";

import styles from "./Panel.module.scss";

const Panel = ({ multiple, children, className }) => (
  <div
    className={`${styles.root} ${className} ${multiple ? styles.multiple : ""}`}
  >
    {children}
  </div>
);

Panel.propTypes = {
  multiple: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Panel.defaultPropTypes = {
  className: ""
};

export default Panel;
