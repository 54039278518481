import gql from "graphql-tag";
import get from "lodash/get";

export const adminAddUserMutation = gql`
  mutation AdminAddUser(
    $username: String!
    $contractor_id: String!
    $is_enabled: Boolean
    $is_driver: Boolean
    $is_contractor: Boolean
    $name: String
    $email: String
    $phone_number: String
  ) {
    adminAddUser(
      user: {
        username: $username
        contractor_id: $contractor_id
        is_enabled: $is_enabled
        is_driver: $is_driver
        is_contractor: $is_contractor
        name: $name
        email: $email
        phone_number: $phone_number
      }
    ) {
      id: username,
      name
      username
      initial_password
      is_enabled
      is_driver
      is_contractor
      email
      phone_number
      sub
      contractor_id
      status
    }
    adminAddUserToCache @client 
  }
`;

export const adminAddUserProps = (data = { addUser: {} }) => {
  const userData = get(data, "adminAddUser", {});
  const { username, name, initial_password } = userData;

  return {
    name,
    username,
    initialPassword: initial_password
  };
};
