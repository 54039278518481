import React from "react";
import ChangePassword from "../components/auth/ChangePassword";
import SignOutButton from "../components/SignOutButton";
import Panel from "../components/layout/Panel";
import { Router, Link } from "@reach/router";

import styles from "./UserSettings.module.scss";

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false
    };
  }

  componentDidUpdate() {
    this.props.refreshStyles();
  }

  changePassword = () => {
    this.setState({ changePassword: true });
  };

  render() {
    const { user, refreshStyles} = this.props;
    return (
      <React.Fragment>
        <Router>
          <ChangePassword path="password" />
        </Router>
        <div className={styles.root}>          
          <header>
            <h2>{user.name}</h2>
            {/*<span className={styles.company}>Veskun Kuljetus Oy</span>*/}
            <SignOutButton className="logout" refreshStyles={refreshStyles}>Kirjaudu ulos</SignOutButton>
          </header>

          <div className={styles.fieldset}>
            <span className={styles.legend}>Kirjautuminen</span>
            <div className={styles.fields}>
              <Panel multiple="true">
                <div className={styles.field}>
                  <div className={styles.fieldContent}>Käyttäjätunnus</div>
                  <span className={styles.disabled}>{user.username}</span>
                </div>
              </Panel>
              <Panel multiple="true">
                <div className={styles.field}>
                  <div className={styles.fieldContent}>Salasana</div>
                  <Link to="password" className={styles.link}>Vaihda salasana</Link>
                </div>
              </Panel>
            </div>
          </div>

          <div className={styles.fieldset}>
            <span className={styles.legend}>Käyttäjätiedot</span>
            <div className={styles.fields}>
              <Panel>
                <div className={styles.field}>
                  <div className={styles.fieldContent}>
                    <span className={styles.heading}>{user.name}</span>
                    <span className={styles.meta}>{user.email}</span>
                    <span className={styles.meta}>{user.phone}</span>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserSettings;
