import React from "react";
import PropTypes from "prop-types";

import styles from "./Statusbar.module.scss";

const Statusbar = ({ isOnline, children }) => (
  <div className={`${styles.root} ${(isOnline)? "":styles.offline}`}>{children}</div>
);

Statusbar.propTypes = {
  isOnline: PropTypes.bool,
  children: PropTypes.node
};

Statusbar.defaultPropTypes = {
  isOnline: true,
  children: null
};

export default Statusbar;
