import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { navigate } from "@reach/router";
import sortBy from "lodash/sortBy";

import * as Sentry from '@sentry/browser';

import { getUsersQuery, getUsersProps } from "../../api/graphql/getUsers";
import { adminGetUsersQuery, adminGetUsersProps } from "../../api/graphql/adminGetUsers";

import CurrentUser from "../data/CurrentUser";
import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import AddNew from "../../components/input/AddNew";
import DriverList from "../../components/usermanagement/DriverList";
import DriverListRow from "../../components/usermanagement/DriverListRow";

const Drivers = ({ children, refreshStyles }) => (
  <React.Fragment>
    <CurrentUser>
      {currentUser => {
        const { isAdmin } = currentUser;
        const usersQuery = isAdmin ? adminGetUsersQuery : getUsersQuery;
        const usersPropsResolver = isAdmin
          ? adminGetUsersProps
          : getUsersProps;

        return (
          <Query query={usersQuery} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if(error) {
                Sentry.captureEvent({
                  message: "Query error - Drivers",
                  extra: {
                    'error': error,
                    'data': data,
                  },
                });          
              }
              if (error) return <Error error={error} />;

              const users = usersPropsResolver(data);

              return (
                <React.Fragment>
                  <AddNew onClick={() => navigate("/admin/driver/add")} refreshStyles={refreshStyles}>
                    {isAdmin ? "Lisää uusi käyttäjä" : "Lisää uusi kuljettaja"}
                  </AddNew>
                  <DriverList isAdmin={isAdmin}>
                    {sortBy(users, user => user.name.toLowerCase()).map(
                      (user, i) => {
                        // Return updated row if driver exists, else hide the row
                        return (
                          <DriverListRow
                            key={user.userId}
                            loading={loading}
                            driver={user}
                            isAdmin={isAdmin}
                            isInvalid={!(user.username && user.status)}
                            index={i}
                          />
                        );
                      }
                    )}
                  </DriverList>
                </React.Fragment>
              );
            }}
          </Query>
        );
      }}
    </CurrentUser>
    {children}
  </React.Fragment>
);

Drivers.propTypes = {
  children: PropTypes.node
};

Drivers.defaultPropTypes = {
  children: null
};

export default Drivers;
