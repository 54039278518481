import React, { useState } from "react";
import styles from "./VehicleList.module.scss";
import DeviceType from "../../../enum/DeviceType";
import Vehicles, { CurrentVehicle } from "../../../containers/data/Vehicles";
import { selectVehicleMutation } from "../../../api/graphql/selectVehicle";
import VehicleDetails from "./VehicleDetails";
import { Mutation } from "react-apollo";
import CurrentUser from "../../../containers/data/CurrentUser";
import Device from "../../../containers/data/Device";

export default (props) => {
  const [open, setOpen] = useState(null);
  const toggle = (id) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  return (
    <Device>
      {({ deviceType }) => (
        <CurrentUser>
          {({isContractor}) => (
            <Mutation mutation={selectVehicleMutation}>
              {(selectVehicle) => (
                <div className={styles.root}>
                  <Vehicles withDetails={true}>
                    {({ vehicles }) => (
                      <CurrentVehicle vehicles={vehicles}>
                        {({currentVehicle}) => {
                          const drivingMode = deviceType === DeviceType.VEHICLE;
                          const _vehicles = drivingMode ? vehicles.filter(v => `${v.vehicleId}` === `${currentVehicle.vehicleId}`) : vehicles;
                          return _vehicles.map(vehicle => (
                            <VehicleDetails
                              id={vehicle.vehicleId}
                              vehicle={vehicle}
                              isCurrent={currentVehicle && `${currentVehicle.vehicleId}` === `${vehicle.vehicleId}`}
                              isOpen={drivingMode || open === vehicle.vehicleId}
                              onOpen={drivingMode ? null : () => toggle(vehicle.vehicleId)}
                              onSelect={drivingMode ? null : () => {
                                selectVehicle({
                                  variables: { vehicleId: vehicle.vehicleId, licenseNum: vehicle.licenseNum }
                                });
                              }}
                              key={vehicle.vehicleId}
                              refreshStyles={props.refreshStyles}
                            />
                          ));
                        }}
                      </CurrentVehicle>
                    )}
                  </Vehicles>
                </div>
              )}
            </Mutation>
          )}
        </CurrentUser>
      )}
    </Device>
  );
};