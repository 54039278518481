import React from "react";
import PropTypes from "prop-types";

import styles from "./DigitInput.module.scss";

const validateDigit = e => {
  const input = e.currentTarget;
  input.value = input.value.replace(/[^0-9]/g,'');
}

const validateLicense = e => {
  const input = e.currentTarget;
  const i = parseInt(input.name.split('_')[1]);
  if (i < 3) {
    input.value = input.value.replace(/[^a-z|åäö]/gi, '');
  }
  if (i > 2) {
    input.value = input.value.replace(/[^0-9]/g,'');
  }
}

class DigitInput extends React.Component {
  clearDigit = e => {
    const input = e.currentTarget;
    input.value = "";
    this.onChange(e);
  }

  onChange = e => {
    const input = e.currentTarget;
    if (input.value.length > 0) {
      input.blur();

      // moving focus to next input
      const next = this.refs[input.name].nextSibling;
      if (next) {
        next.focus();
      }
    }

    // targeting this component instead of individual digit input
    e.target = this;
    if(this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onKeyDown = e => {
    const input = e.currentTarget;

    if (e.key === 'Backspace') {
      input.blur();

      // moving focus to previous input
      const prev = this.refs[input.name].previousSibling;
      if (prev) {
        prev.focus();
      }
    }

    // targeting this component instead of individual digit input
    e.target = this;
    if(this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  onBlur = e => {
    // duplicating the event and targeting component instead of individual digit input
    const event = {...e, target: this};
    
    // rae to allow for the focus to shift
    requestAnimationFrame(() => {
      // are we still focused on one of the digit fields?
      if (this.props.onBlur && !Object.values(this.refs).find(ref => ref === document.activeElement)) {
        // nope. the component is blurred, so trigger the event.
        this.props.onBlur(event);
      }
    });
  }

  onPaste = e => {
    // check if the clipboard includes the correct number of digits
    const text = e.clipboardData.getData('Text');
    const regex = new RegExp(`^\\d{${this.props.digitCount}}`);
    if (regex.test(text)) {
      this.value = text;

      // targeting this component instead of individual digit input
      e.target = this;
      this.props.onChange(e);
    }
  }

  set value (val) {
    // combine all the digits into a string
    Object.values(this.refs).forEach((input, i) => {
      input.value = val.charAt(i);
    });
  }

  get name () {
    return this.props.name;
  }

  get value () {
    // combine all the digits into a string
    return Object.values(this.refs).reduce(
      (str, input) => str += input.value
    , '');
  }

  render() {
    return (
      <div className={`${styles.root} ${this.props.license ? styles.license : ''}`}>
        {[...Array(this.props.digitCount)].map((e, i) => (
          <input 
            ref={`digit_${i}`}
            key={`digit_${i}`}
            name={`digit_${i}`}
            type="text"
            size="1"
            maxLength="1"
            required={this.props.required}
            onFocus={this.clearDigit}
            onInput={this.props.license ? validateLicense : validateDigit}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            onPaste={this.onPaste}
          />
        ))}
      </div>
    );
  }
}


DigitInput.propTypes = {
  digitCount: PropTypes.number.isRequired,
  required: PropTypes.bool.isRequired,
  license: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string,
};

DigitInput.defaultProps = {
  digitCount: 6,
  required: false,
  license: false
};

export default DigitInput;