import Coords from "./utils/Coords";
import pinTruckGreenSVG from "./assets/vr-truck-circle-green.svg";
import pinTruckOrangeSVG from "./assets/vr-truck-circle-orange.svg";
import pinTruckRedSVG from "./assets/vr-truck-circle-red.svg";
import pinBlackSVG from "./assets/vr-pin-black.svg";

import H from "@here/maps-api-for-javascript";
export default class Truck {
  static pinBlack = new H.map.Icon(pinBlackSVG, {
    size: { w: 30, h: 30 }
  });
  static pinTruckGreen = new H.map.Icon(pinTruckGreenSVG, {
    size: { w: 30, h: 30 },
    anchor: { x: 15, y: 15 }
  });
  static pinTruckOrange = new H.map.Icon(pinTruckOrangeSVG, {
    size: { w: 30, h: 30 },
    anchor: { x: 15, y: 15 }
  });
  static pinTruckRed = new H.map.Icon(pinTruckRedSVG, {
    size: { w: 30, h: 30 },
    anchor: { x: 15, y: 15 }
  });
  static Status = { driving: 0, loading: 1, stopped: 2 };

  positionHistory = [];
  position = new Coords(0, 0);
  bearing = 0;
  status = Truck.Status.stopped;
  marker = null;
  zIndex = 20;

  constructor(coords, options) {
    const {status, zIndex} = options;
    this.setPosition(coords);
    this.setStatus(status);
    this.zIndex = isNaN(zIndex) ? this.zIndex : zIndex;
  }

  setStatus(status) {
    if (Object.values(Truck.Status).includes(status)) {
      this.status = status;
      this.refreshMarker();
      return true;
    } else {
      // Invalid state, set default
      this.status = this.status || Truck.Status.default;
      return false;
    }
  }

  setPosition(coords) {
    if (coords && !isNaN(coords.lat) && !isNaN(coords.lng)) {
      let newPosition = new Coords(coords.lat, coords.lng);
      // When the coodinates have changed, calculate a new bearing and set the new position
      if (
        newPosition.lat !== this.position.lat ||
        newPosition.lng !== this.position.lng
      ) {
        let bearing = Truck.calculateBearing(this.position, newPosition);
        this.bearing = Truck.lerpAngle(this.bearing, bearing, 0.25);
        this.position = newPosition;
        this.refreshMarker();
        return true;
      }
    }
    return false;
  }

  /** calculateBearing(a,b)
   *
   * Calculates the bearing from two {lat, lng} coordinate points.
   *
   * Parameters:
   *  a   Start point coordinates {lat,lng}
   *  b   End point coordinates {lat, lng}
   *
   * Return:
   *  Bearing in degrees
   */
  static calculateBearing(a, b) {
    const Math = window.Math;
    let y = Math.sin(b.lng - a.lng) * Math.cos(b.lat),
      x =
        Math.cos(a.lat) * Math.sin(b.lat) -
        Math.sin(a.lat) * Math.cos(b.lat) * Math.cos(b.lng - a.lng);
    return Math.atan2(y, x) * (180 / Math.PI);
  }

  /** lerpAngle(a,b,amount)
   *
   * Parameters
   *  a       Start angle in degrees
   *  b       Target angle in degrees
   *  amount  The ratio of a compared to b (0.0 - 1.0)
   *
   * Return
   *  A lerped angle between a and b
   *  */
  static lerpAngle(a, b, amount) {
    let shortest_angle = ((((b - a) % 360) + 540) % 360) - 180;
    return a + ((shortest_angle * amount) % 360);
  }

  setMarker(marker) {
    this.marker = marker;
    this.refreshMarker();
  }

  getMarker() {
    if (!this.marker) {
      let marker = new H.map.Marker(this.position, {
        icon: this.getIcon(),
        zIndex: this.zIndex
      });
      this.setMarker(marker);
      return marker;
    }
    return this.marker;
  }

  refreshMarker() {
    if (this.marker) {
      this.marker.setGeometry(
        new H.geo.Point(this.position.lat, this.position.lng)
      );
      this.marker.setIcon(this.getIcon());
      this.marker.setZIndex(this.zIndex);
    }
  }

  getCoords() {
    return this.position;
  }

  getIcon() {
    let icon;
    switch (this.status) {
      case Truck.Status.driving:
        icon = Truck.pinTruckGreen;
        break;
      case Truck.Status.loading:
        icon = Truck.pinTruckOrange;
        break;
      case Truck.Status.stopped:
      default:
        icon = Truck.pinTruckRed;
        break;
    }
    return icon;
  }
}