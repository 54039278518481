import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

import DestinationFooter from "./DestinationFooter";

import styles from "./DestinationWrapper.module.scss";

const DestinationWrapper = ({
  headerLinkTarget,
  headerLinkText,
  className,
  children,
  toggleState,
  destination,
  allowActions,
  allowExceptions,
  isLoading,
}) => {
  const headerText = `${destination.type === "load" ? "Lastaus" : "Purku"} – ${
    destination.name
  }`;

  return (
    <div
      className={`${styles.wrapper} ${styles[className]} ${
        destination.state === "completed"
          ? styles.completed
          : destination.state === "ongoing"
          ? destination.type === "load"
            ? styles.loading
            : styles.unloading
          : null
      }`}
    >
      <header>
        {headerLinkTarget && (
          <Link to={headerLinkTarget}>{headerLinkText}</Link>
        )}
        <span>{headerText}</span>
      </header>
      <div className={`${styles.root}`}>
        <div className={styles.scroll}>{children}</div>
      </div>

      <DestinationFooter
        {...destination}
        allowActions={allowActions}
        allowExceptions={allowExceptions}
        isLoading={isLoading}
        onAction={() => {
          toggleState(destination);
        }}
      />
    </div>
  );
};

DestinationWrapper.propTypes = {
  headerLinkTarget: PropTypes.string,
  headerLinkText: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  toggleState: PropTypes.func.isRequired,
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool,
  destination: PropTypes.shape({
    type: PropTypes.oneOf(["load", "unload"]).isRequired,
    name: PropTypes.string,
    state: PropTypes.oneOf([
      "new",
      "updated",
      "cancelled",
      "completed",
      "ongoing"
    ])
  }).isRequired,
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
};

DestinationWrapper.defaultPropTypes = {
  headerLink: "../",
  headerLinkText: "Takaisin",
  className: "",
  isLoading: false,
  setLoading: () => {},
};

export default DestinationWrapper;
