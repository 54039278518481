import Coords from "./utils/Coords";
import parseCommoditiesFromTransactions from "./utils/parseCommoditiesFromTransactions";
import getRestString from "../../utils/getRestString";
import sortBy from "lodash/sortBy";

import H from "@here/maps-api-for-javascript";
export default class Waypoint {
  static pinSize = {
    default: { w: 30, h: 30 },
    big: { w: 40, h: 40 }
  };
  static pinAnchor = {
    default: { x: 15, y: 15 },
    big: { x: 20, y: 20 }
  };
  static Accuracy = { none: 0, area: 1, address: 2 }
  static Status = { default: 0, current: 1, done: 2, cancelled: 3 };
  static Type = { start: 0, load: 1, unload: 2 };
  static zIndexDefault = 1;

  id = null;
  status = Waypoint.Status.default;
  type = Waypoint.Type.loading;
  accuracy = Waypoint.Accuracy.none;
  position = new Coords(0, 0);
  marker = null;
  isDefected = false;
  order = null;
  onlyTransactions = false;

  constructor(id, coords, type, status, accuracy, order, information) {

    this.id = id;
    this.setPosition(coords);
    this.setType(type);
    this.setStatus(status);
    this.setAccuracy(accuracy);
    this.setOrder(order);
    this.setInformation(information, order);
  }

  /**
   * setInformation
   * @param {*} i         Marker item to be parsed
   * @param {*} order     Nth stop on the route
   */
  setInformation(i, order){

    this.onlyTransactions = i.onlyTransactions;

    const type = `<b>${i.type}</b><br />`;
    const name = `${i.name}<br/>`;
    const address = `${i.address} ${i.zipcode} ${i.city}<br/>`;
    const additionalInfo = i.addressAdditionalInfo ? `${i.addressAdditionalInfo}<br/>` : '';
    const contactPerson = i.contactPerson ? i.contactPerson : '';
    const contactPhone = (i.contactPhone && i.contactPhone.length > 6) ? `<b><a href="tel:${i.contactPhone}">${i.contactPhone}</a></b>` : '';
    const contacts = (contactPhone.length > 6 || contactPerson.length > 6) ? `${contactPerson} ${contactPhone}<br/>` : '';

    const commodities = sortBy(
      parseCommoditiesFromTransactions(i.transactions),
      "name"
    );
    const transactions = `${commodities[0].name}: ${commodities[0].amount.toLocaleString("fi-FI")} ${commodities[0].unit} ${getRestString(commodities)}<br/>`;

    this.information = `
    <div style="white-space: nowrap; font-size: 15px;">
     ${type}
     Järjestysnumero reitillä: <b>${order}</b><br/>
     <b>${name}
     ${address}
     ${additionalInfo}
     ${contacts}</b>
     ${transactions}
    </div>    
    `;
  }

  setOrder(order){
    this.order = order;
  }

  setType(type) {
    if (Object.values(Waypoint.Type).includes(type)) {
      this.type = type;
      return true;
    } else {
      // Invalid state, set default
      this.type = Waypoint.Type.start;
      return false;
    }
  }

  setStatus(status) {
    if (Object.values(Waypoint.Status).includes(status)) {
      this.status = status;
      this.refreshMarker();
      return true;
    } else {
      // Invalid state, set default
      this.status = Waypoint.Status.default;
      return false;
    }
  }

  setPosition(coords) {
    this.isDefected = !(coords && !isNaN(coords.lat) && !isNaN(coords.lng));
    if (!this.isDefected) {
      let newPosition = new Coords(coords.lat, coords.lng);
      // When the coodinates have changed, calculate a new bearing and set the new position
      if (
        newPosition.lat !== this.position.lat ||
        newPosition.lng !== this.position.lng
      ) {
        this.position = newPosition;
        this.refreshMarker();
      }
    }
  }

  setAccuracy(accuracy) {
    if (Object.values(Waypoint.Accuracy).includes(accuracy)) {
      this.accuracy = accuracy;
      return true;
    } else {
      // Invalid state, set default
      this.accuracy = Waypoint.Accuracy.none;
      return false;
    }
  }

  getMarker() {
    if(this.isDefected) {
      return null;
    }
    if (!this.marker) {
      this.marker = new H.map.Marker(this.getCoords(), {
        icon: this.getIcon(),
        zIndex: Waypoint.zIndexDefault + (this.isCurrent() ? 10 : 9)
      });
      this.marker.setData(this.information)
    }
    return this.marker;
  }

  refreshMarker() {
    if (this.marker) {
      this.marker.setGeometry(
        new H.geo.Point(this.position.lat, this.position.lng)
      );
      this.marker.setIcon(this.getIcon());
    }
  }

  isCurrent(){
    return this.status === Waypoint.Status.current;
  }

  getCoords() {
    return new Coords(this.position.lat, this.position.lng);
  }

  getIcon() {
    let icon;
    if (this.type === Waypoint.Type.start) {
      icon = new H.map.Icon(this.returnPin('start', this.order), {
        size: Waypoint.pinSize.default,
        anchor: Waypoint.pinAnchor.default
      });
    } else {
      switch (this.status) {
        case Waypoint.Status.current:
          icon =
            this.type === Waypoint.Type.load
              ? new H.map.Icon(this.returnPin('loadingCurrent', this.order), {
                size: Waypoint.pinSize.big,
                anchor: Waypoint.pinAnchor.big
              })
              : new H.map.Icon(this.returnPin('unloadingCurrent', this.order), {
                size: Waypoint.pinSize.big,
                anchor: Waypoint.pinAnchor.big
              })
          break;
        case Waypoint.Status.done:
          icon =
            this.type === Waypoint.Type.load
              ? new H.map.Icon(this.returnPin('completeloading', this.order), {
                size: Waypoint.pinSize.default,
                anchor: Waypoint.pinAnchor.default
              })
              : new H.map.Icon(this.returnPin('completeunloading', this.order), {
                size: Waypoint.pinSize.default,
                anchor: Waypoint.pinAnchor.default
              })
          break;
        case Waypoint.Status.cancelled:
          icon = new H.map.Icon(this.returnPin('cancelled', this.order), {
            size: Waypoint.pinSize.default,
            anchor: Waypoint.pinAnchor.default
          })
          break;
        case Waypoint.Status.default:
        default:
          icon =
            this.type === Waypoint.Type.load
              ? new H.map.Icon(this.returnPin('loading', this.order), {
                size: Waypoint.pinSize.default,
                anchor: Waypoint.pinAnchor.default
              })
              : new H.map.Icon(this.returnPin('unloading', this.order), {
                size: Waypoint.pinSize.default,
                anchor: Waypoint.pinAnchor.default
              })
          break;
      }
    }
    return icon;
  }

  

  /**
   * returnPin
   * Return a relevant pin icon as SVG with route stop numbering embedded
   * 
   * @param {string} type       Type of destination event (start, completeloading etc)
   * @param {int} order         Destination order (nth stop on the route) in Sisu
   */
  returnPin(type, order){

    let pin;

    const returnFlag = (color, order) => 
    `<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style>
    .order { font: bold 13px sans-serif; fill: #555;}
    </style>
    <title>kuljetusmaali</title>
    <desc>Created with Sketch.</desc>
    <g id="kuljetusmaali" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <circle id="Oval" fill="${color}" cx="20" cy="20" r="20"></circle>
        <g id="lippu" transform="translate(-2.000000, 1.000000) scale(2 2)" fill="#FFFFFF" fill-rule="nonzero">
            <polygon id="Path" points="8.0 3.5 5.5 3.0 5.5 18.0 7.0 18.8 7.0 13.5 18.0 13.0 17.0 9.0 18.0 5.0"></polygon>
        </g>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" class="order">${order}</text>
    </g>
    </svg>`;

    const returnCompleteUnloading = (color, order) => 
    `<svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style>
              .order { font: bold 16px sans-serif; fill: #fff;}
              </style>
    <title>purku valmis</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Tablet" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="5-Lastaus,-yksi-yksi---3-Lastaus-/-4-Valmis" transform="translate(-551.000000, -112.000000)">
            <g id="Group-2" transform="translate(402.000000, 100.371094)">
                <g id="button-big--blue" transform="translate(149.199219, 11.828125)">
                    <rect id="Rectangle-3" fill="${color}" x="0.0214908918" y="0.0634843508" width="49.4745938" height="49.4745938" rx="24.7372969"></rect>
                    <polygon transform="translate(-5 -5)
                    scale(1.3 1.3)" id="Shape" stroke="#FFFFFF" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="round" stroke-linejoin="round" points="22.007349 31.788761 16.4811 26.262512 17.8976499 24.8459622 22.007349 28.9453444 32.6480696 18.3149407 34.0646194 19.7314906"></polygon>
                </g>
            </g>
        </g>
    </g>
    <text x="14" y="21" class="order">${order}</text>
    </svg>`;

    const returnCompleteLoading = (color, order) =>
    `<svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style>
              .order { font: bold 16px sans-serif; fill: #fff;}
              </style>
    <title>purku valmis</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Tablet" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="5-Lastaus,-yksi-yksi---3-Lastaus-/-4-Valmis" transform="translate(-551.000000, -112.000000)">
            <g id="Group-2" transform="translate(402.000000, 100.371094)">
                <g id="button-big--blue" transform="translate(149.199219, 11.828125)">
                    <rect id="Rectangle-3" fill="${color}" x="0.0214908918" y="0.0634843508" width="49.4745938" height="49.4745938" rx="24.7372969"></rect>
                    <polygon transform="translate(-5 -5)
            scale(1.3 1.3)" id="Shape" stroke="#FFFFFF" fill="#FFFFFF" fill-rule="nonzero" stroke-linecap="round" stroke-linejoin="round" points="22.007349 31.788761 16.4811 26.262512 17.8976499 24.8459622 22.007349 28.9453444 32.6480696 18.3149407 34.0646194 19.7314906"></polygon>
                </g>
            </g>
        </g>
    </g>
    <text x="14" y="21" class="order">${order}</text>
    </svg>`;

    const returnStart = (color, order) =>
    `<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <style>
                .order { font: bold 14px sans-serif; fill: #fff;}
                </style>
      <title>starttipaikka</title>
      <desc>Created with Sketch.</desc>
      <g id="starttipaikka" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Oval">
              <circle stroke="${color}" stroke-width="3" fill="#FFFFFF" cx="14" cy="14" r="12.5"></circle>
              <circle fill="${color}" cx="14" cy="14" r="8"></circle>
          </g>
      <text x="10" y="19" class="order">${order}</text>
          </g>
      </svg>
    `;

    const cancelledColour = '#45555F';
    const loadingColour = '#2479b3';
    const unloadingColour = '#cc5198';
    const currentColourTransactions = '#ebcc34';
    const startColour = '#02B943';

    switch (type) {

      case 'start':
        pin = returnStart(startColour, order);
        break;
      case 'completeloading':
        pin = returnCompleteLoading(loadingColour, order);
        break;
      case 'completeunloading':
        pin = returnCompleteUnloading(unloadingColour, order);
        break;
      case 'cancelled':
        pin = returnFlag(cancelledColour, order);
        break;
      case 'unloading':
        pin = returnFlag(unloadingColour, order);
        break;
      case 'loading':
        pin = returnFlag(loadingColour, order);
          break;
      case 'unloadingCurrent':
        pin = returnFlag(this.onlyTransactions ? currentColourTransactions : unloadingColour, order);
        break;
      case 'loadingCurrent':
        pin = returnFlag(this.onlyTransactions ? currentColourTransactions : loadingColour, order);
          break;
      default:
        pin = returnFlag(loadingColour, order);
          break;

    }

    return pin;

  }

}

