import deepEqual from "lodash/isEqual";
import memoize from "memoize-one";

// Filter destinations based on a routeId
export default memoize((destinations, routeId) => {
  return destinations.reduce((acc, cur) => {
    if(cur.routeId === routeId){
      acc.push(cur);
    }
    return acc;
  }, []);
}, deepEqual);