import get from "lodash/get";

export default (destinations=[])=>{
    let orders = {};
    destinations.forEach((dest)=>{
        const { id, routeId, state, status, type } = dest;
        get(dest, "transactions", []).forEach((txn)=>{
            const { timeEnded,
                    timeStarted,
                    transactionId,
                    orderNum } = txn;
            
            // IF no entry for the orderNum, initialise it
            if (!orders[orderNum]) {
                orders[orderNum] = {
                    orderNum: orderNum,
                    routeId: routeId,
                    transactions: []
                };
            }

            orders[orderNum].transactions.push({
                id: transactionId,
                timeStarted: timeStarted,
                timeEnded: timeEnded,
                destId: id,
                destState: state,
                destStatus: status,
                destType: type,
                routeId: routeId
            })
        });
    });
    return orders;
}
