import React from "react";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { TextField } from "formik-mui";

import authAPI from "../../api/authAPI";
import Button from "../input/Button";
import Modal from "../layout/Modal";

import styles from "./ChangePassword.module.scss";

const STATE_PROMPT_PASSWORDS = "state_prompt_passwords";
const STATE_SUCCESS = "state_success";

const initialValues = {
  oldPassword: "",
  newPassword: ""
};

const validationSchema = object().shape({
  oldPassword: string().required("Pakollinen kenttä"),
  newPassword: string().required("Pakollinen kenttä")
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isSubmitting: false,
      currentState: STATE_PROMPT_PASSWORDS
    };
  }

  onSubmit = ({ oldPassword, newPassword }, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ error: null, isSubmitting: true });

    authAPI
      .changePassword(oldPassword, newPassword)
      .then(() => {
        this.setState({
          loading: false,
          currentState: STATE_SUCCESS
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error
        });
        setSubmitting(false);
      });
  };

  render() {
    const { currentState } = this.state;

    return (
      <Modal>
        {this.state.error && <div className={styles.error}>{this.state.error.message}</div>}
        {currentState === STATE_PROMPT_PASSWORDS && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
          >
            {({ isValid }) => (
              <Form className={styles.root}>
                <Field
                  type="password"
                  name="oldPassword"
                  placeholder="Nykyinen salasana"
                  autoFocus
                  className={styles.field}
                  component={TextField}
                />
                <Field
                  type="password"
                  name="newPassword"
                  placeholder="Uusi salasana"
                  className={styles.field}
                  component={TextField}
                />
                <Button type="submit" disabled={!isValid}>
                  Vaihda salasana
                </Button>
              </Form>
            )}
          </Formik>
        )}
        {currentState === STATE_SUCCESS && <div>Password updated!</div>}
      </Modal>
    );
  }
}

export default Login;
