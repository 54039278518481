import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { selectRouteMutation } from "../api/graphql/selectRoute";
import DigitInput from "../components/input/DigitInput";
import styles from "./RouteSelection.module.scss";
import Button from "./input/Button";

class RouteSelection extends React.Component {
  constructor() {
    super();
    this.state = {
      routeId: ""
    };
  }

  render() {
    const { onSelected, heading, onBack } = this.props;
    const { routeId } = this.state;

    return (
      <Mutation mutation={selectRouteMutation}>
        {selectRoute => (
          <div className={styles.root}>
            <h2>{heading}</h2>
            <h3>Syötä reittitunnus</h3>
            <DigitInput
              required={true}
              onChange={e => this.setState({ routeId: e.target.value })}
            />
            <Button
              disabled={routeId.length < 6}
              onClick={() => {
                selectRoute({
                  variables: { routeId }
                });
                if (onSelected) onSelected();
              }}
            >
              Valmis
            </Button>
            {onBack &&
            <Button
              onClick={() => {
                onBack();
              }}
              cancel="true"
            >
              Takaisin
            </Button>}
          </div>
        )}
      </Mutation>
    );
  }
}

RouteSelection.propTypes = {
  heading: PropTypes.string,
  onSelected: PropTypes.func
};

RouteSelection.defaultProps = {
  heading: "Mitä reittiä ajat?"
};

export default RouteSelection;
