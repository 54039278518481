import gql from "graphql-tag";
import get from "lodash/get";

export const getUserSettingsQuery = gql`
  query GetCurrentUser {
    getCurrentUser {
      name
      username
      email
      phone_number
      is_admin
      is_contractor
    }
    vehicle: currentVehicle @client {
      vehicleId 
    }
    routeId @client
    deviceType @client
  }
`;

export const getUserSettingsProps = (
  data = {
    getCurrentUser: {},
    vehicleId: null,
    deviceType: null,
    routeId: null
  }
) => {
  const userData = get(data, "getCurrentUser", {});
  const {
    name,
    username,
    email,
    phone_number,
    is_admin,
    is_contractor
  } = userData;
  return {
    name: name,
    username: username,
    email: email,
    phone: phone_number,
    isAdmin: is_admin,
    isContractor: is_contractor,
    vehicleId: get(data, "vehicle.vehicleId", null),
    routeId: data.routeId,
    deviceType: data.deviceType
  };
};
