import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import styles from "./Field.module.scss";

const BasicField = props => {
  const { id, unit, className, valid } = props;

  return (
    <div
      className={`${styles.fieldWrapper} ${
        valid === "true" ? styles.valid : ""
      } ${className}`}
    >
      <Field
        {...props}
        name={id}
        className={`${styles.field} ${unit ? styles.unit : ""} ${className}`}
      />
    </div>
  );
};

BasicField.propTypes = {
  id: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  unit: PropTypes.string,
  valid: PropTypes.string,
};

BasicField.defaultProps = {
  autoFocus: false,
  className: "",
  disabled: false,
  type: "text",
  unit: null,
  valid: "false",
};

export default BasicField;
