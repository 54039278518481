import gql from "graphql-tag";

export const selectVehicleMutation = gql`
  mutation SelectVehicle($vehicleId: String!, $licenseNum: String) {
    selectVehicle(vehicleId: $vehicleId, licenseNum: $licenseNum) @client {
      currentVehicle @client {
        vehicleId
        licenseNum
      }
    }
  }
`;
