import React from "react";

import CenteredContent from "./CenteredContent";
import Panel from "./Panel";
import SignOutButton from "../SignOutButton";

import styles from "./Error.module.scss";

const Error = ({ error }) => (
  <CenteredContent>
    <Panel className={styles.root}>
      Järjestelmässä tapahtui virhe.
      
      <pre>{error.message}</pre>
      
      <SignOutButton>Kirjaudu ulos</SignOutButton>
    </Panel>
  </CenteredContent>
);

export default Error;
