import memoize from "memoize-one";

export default memoize(routes =>
  routes.reduce(
    (destinations, route) => [
      ...destinations,
      ...route.destinations.map(destination => ({
        ...destination,
        status: route.status,
        routeId: route.id
      }))
    ],
    []
  )
);
