import gql from "graphql-tag";
import routeDestinationFragment from './routeDestination'

export const fragmentRoute = gql`
    fragment Route on Route {
        id
        routeId
        routeName
        status
        contractorId
        vehicleId
        additionalInfo
        destinations {
            ...RouteDestination
        }
    }
    ${routeDestinationFragment}
`;

export default fragmentRoute;

