import gql from "graphql-tag";

export const setTransactionStatusMutation = gql`
  mutation setTransactionStatus(
    $routeId: ID!
    $status: String!
    $timestamp: String!
    $transactions: [TransactionRow]
  ) {
    setTransactionStatus(
      routeId: $routeId
      status: $status
      timestamp: $timestamp
      transactions: $transactions
    ) {
      id
      routeId
      routeName
      status
      destinations {
        id
        destinationId
        order
        type
        eta
        etd
        ata
        atd
        transactions {
          id
          transactionId
          waybillNum
          orderedAmount
          orderedUnit
          actualAmount
          actualUnit
          timeStarted
          timeEnded
        }
      }
    }
  }
`;
