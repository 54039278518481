import memoize from "memoize-one";
import deepEqual from "lodash/isEqual";

export default memoize(transactions => {
  const commodities = transactions.reduce((acc, transaction) => {
    const key = `${transaction.commodityName}${
      transaction.commodityUnit
    }`.toLowerCase();

    const {
      commodityName,
      actualUnit,
      actualAmount,
      orderedUnit,
      orderedAmount
    } = transaction;

    acc[key] = {
      name: commodityName,
      unit: actualUnit || orderedUnit,
      amount:
        Math.abs(
          !!actualAmount || actualAmount === 0 ? actualAmount : orderedAmount
        ) + (acc[key] ? acc[key].amount : 0)
    };

    return acc;
  }, {});

  return Object.keys(commodities).map(key => commodities[key]);
}, deepEqual);
