import React from "react";
import PropTypes from "prop-types";

const IconNotice = ({ color }) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>notice</title>
    <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons" transform="translate(-265.000000, -565.000000)">
        <g id="notice" transform="translate(250.000000, 550.000000)">
          <rect id="Rectangle-6" x="0" y="0" width="50" height="50" />
          <g
            id="Group"
            transform="translate(16.000000, 16.000000)"
            fill={color}
          >
            <path
              d="M9,2.31869098 L1.81352549,16.69164 L16.1864745,16.69164 L9,2.31869098 Z M17.4,18.19164 L0.6,18.19164 C0.0424634842,18.19164 -0.320158303,17.6049056 -0.0708203932,17.1062298 L8.32917961,0.306229803 C8.60557281,-0.246556601 9.39442719,-0.246556601 9.67082039,0.306229803 L18.0708204,17.1062298 C18.3201583,17.6049056 17.9575365,18.19164 17.4,18.19164 Z"
              id="Stroke-5535"
              fillRule="nonzero"
            />
            <path
              d="M8.25,7.51284 C8.25,7.09862644 8.58578644,6.76284 9,6.76284 C9.41421356,6.76284 9.75,7.09862644 9.75,7.51284 L9.75,12.09444 C9.75,12.5086536 9.41421356,12.84444 9,12.84444 C8.58578644,12.84444 8.25,12.5086536 8.25,12.09444 L8.25,7.51284 Z"
              id="Stroke-5536"
              fillRule="nonzero"
            />
            <path
              d="M9,13.623 C8.5776,13.623 8.2356,13.965 8.2356,14.3862 C8.2356,14.8086 8.5776,15.1494 9,15.1494 C9.4224,15.1494 9.7632,14.8086 9.7632,14.3862 C9.7632,13.965 9.4224,13.623 9,13.623"
              id="Fill-5537"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconNotice.propTypes = {
  color: PropTypes.string
};

IconNotice.defaultProps = {
  color: "#45555F"
};

export default IconNotice;
