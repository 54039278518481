import gql from "graphql-tag";

export const resetPasswordMutation = gql`
  mutation ResetPassword($username: String!) {
    resetPassword(user: { username: $username }) {
      sub
      username
      initial_password
    }
  }
`;
