import gql from "graphql-tag";
import { getUsersQuery } from "../api/graphql/getUsers";
import { adminGetUsersQuery } from "../api/graphql/adminGetUsers";
import get from "lodash/get";

/** addUserToCache(user,cache,userQuery,key)
 * Appends or updates a user to a root query (usersQuery)
 */
const addUserToCache = (user, cache, usersQuery, key) => {
  try {
    // Get all users from the cache
    let users = get(cache.readQuery({query: usersQuery}), key, []);
    let found = false;
    if(user){
      users = users.map(_user => {
        if(user.id === _user.id){
          // Update user if already cached
          found = true;
          return {..._user, ...user};
        }
        return _user;
      });
      if(!found){
        // Add user to cache
        users.push(user);
      }
      // Write modified query result back to cache
      cache.writeQuery({query: usersQuery, data: { [key]: users }});
      return true;
    }
    return false;
  }
  catch (error) {
    console.log("Adding user to cache failed: ", error);
    return false;
  }
}

export default {
  Mutation: {
    addUserToCache: (data, variables, {cache}) => {
      return addUserToCache(
          get(data, "addUser", null),
          cache,
          getUsersQuery,
          "getUsers"
      );
    },
    adminAddUserToCache: (data, variables, { cache }) => {
      return addUserToCache(
        get(data, "adminAddUser", null),
        cache,
        adminGetUsersQuery,
        "adminGetUsers"
      );
    },
    selectVehicle: (_, { vehicleId, licenseNum }, { cache }) => {
      const data = { currentVehicle: { vehicleId, licenseNum, __typename: "Vehicle" }, __typename: "CurrentVehicle" };
      cache.writeData({ data });
      return data;
    },
    selectRoute: (_, { routeId }, { cache }) => {
      const data = { routeId };
      cache.writeData({ data });
      return data;
    },
    selectDeviceType: (_, { deviceType }, { cache }) => {
      const data = { deviceType };
      cache.writeData({ data });
      return data;
    },
    setTransactionInput: (_, { transactionId, waybill, amount, weightNoteNumberLoading, weightNoteNumberUnloading }, { cache }) => {
      transactionId = `${transactionId}`;
      const data = {
        __typename: "TransactionInput",
        id: transactionId,
        transactionId,
        waybill,
        amount,
        weightNoteNumberLoading,
        weightNoteNumberUnloading
      };
      cache.writeData({ data: { transactionInput: data } });
      return data;
    }
  },
  Query: {
    currentVehicle: (_, variables, { cache }) => {
      const query = gql`
        query CurrentVehicle {
          currentVehicle @client {
            vehicleId
            licenseNum
          }
        }
      `;
      try {
        const vehicle = cache.readQuery({ query });
        return vehicle;
      }
      catch{
        return null;
      }
    },
    getTransactionInput: (_, { transactionId }, { cache, getCacheKey }) => {
      transactionId = `${transactionId}`;
      const id = getCacheKey({
        __typename: "TransactionInput",
        id: transactionId
      });

      const fragment = gql`
        fragment item on TransactionInput {
          id
          transactionId
          waybill
          amount
          weightNoteNumberLoading
          weightNoteNumberUnloading
        }
      `;

      try {
        return cache.readFragment({ fragment, id });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
