import parseNumber from "../../../utils/parseNumber";

export default (amount, unit = "KG") => {
  // UNIT	  MAX. DATA
  // T	    xx,xxxx
  // KG	    xx xxx,xxxx
  // M3	    xxx,xxxx
  // LM	    xx,xxxx
  // KPL    xxx,xxxx
  // ERÄ	  x,xxxx
  // KRM    x,xxxx <=1
  // LV     xxx,xxxx
  let error;
  if(amount === null || amount === undefined || (typeof(amount) === 'string' && amount.length === 0)){
    error = "Pakollinen";
    return error;
  }

  let value = parseNumber(amount);
  if(isNaN(value)){
    error = "Ei ole kelvollinen numero";
    return error;
  }

  const simplifiedUnit = unit.replace(/[^a-zäö]/gi, '').toLowerCase();
  let max;
  switch(simplifiedUnit){
    case "krm": // KRM, Kuorma
      max = 1.0;
      break;
    case "erä": // ERÄ
      max = 9.9999;
      break;
    case "t": // T, Tonni
    case "lm": // LM, Lavametri
      max = 99.9999;
      break;
    case "m": // m³, Kuutiometri
    case "kpl": // KPL, Kappale
    case "lv": // Lv, Lavaa
      max = 999.9999;
      break;
    case "kg": // KG, Kilogramma
    default:
      max = 99999.9999;
      break;
  }
  if(value > max){
    error = `Maksimiarvo on ${max} ${unit}`;
  }
  else if(value < 0){
    error = `Minimiarvo on 0 ${unit}`;
  }
  else if(!/^\d+(\.\d{1,4})?$/.test(value.toString())){
    error = `Maksimitarkkuus neljä (4) desimaalia`;
  }
  return error;
}