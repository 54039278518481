import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";

import CurrentUser from "./CurrentUser";
import VehicleSelection from "../../components/VehicleSelection";
import RouteSelection from "../../components/RouteSelection";
import Button from "../../components/input/Button";
import CenteredContent from "../../components/layout/CenteredContent";

import { selectDeviceTypeMutation } from "../../api/graphql/selectDeviceType";
import DeviceType from "../../enum/DeviceType";
import sendMessageToNativeApp from "../../utils/sendMessageToNativeApp";

class RouteSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spotDriver: false
    };
  }

  render() {
    const { children } = this.props;

    return (
      <CurrentUser>
        {currentUser => {
          const { routeId, vehicleId } = currentUser;
          if (!!routeId) {
            // route selected
            return children({ routeId });
          }

          if (!!vehicleId) {
            // vehicle selected
            sendMessageToNativeApp({
              'operation': 'vehicleUpdate',
              'data': {'vehicleId': vehicleId}
            });
            return children({ vehicleId });
          }

          // neither selected yet
          return this.state.spotDriver ? (
            <React.Fragment>
              <RouteSelection
                onBack={() => this.setState({ spotDriver: false })}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <VehicleSelection />
              <CenteredContent>
                <Mutation mutation={selectDeviceTypeMutation}>
                {selectDeviceType => (
                  <Button onClick={() => { 
                    selectDeviceType({variables: { deviceType: DeviceType.VEHICLE }});
                    this.setState({ spotDriver: true })
                  }}>Spot-reitti</Button>
                )}
                </Mutation>
              </CenteredContent>
            </React.Fragment>
          );
        }}
      </CurrentUser>
    );
  }
}

RouteSource.propTypes = {
  children: PropTypes.func.isRequired
};

export default RouteSource;
