import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";

import styles from "./ScheduleDestinationLabel.module.scss";
import IconNotice from "../../layout/icons/IconNotice";

const timestamp = momentTime => momentTime.format("D.M.YYYY, [klo] HH:mm");

const ScheduleDestinationLabel = ({
  isVisited,
  isOngoing,
  type,
  arrival,
  departure,
  className
}) => (
  <div className={`${styles.root} ${className}`}>
    {isOngoing && (
      <IconNotice color={type === "load" ? "#2479b3" : "#cc5198"} />
    )}
    {type === "load"
      ? isVisited
        ? `Lastattu: ${timestamp(departure)}`
        : "Lastaus"
      : isVisited
      ? `Purettu: ${timestamp(departure)}`
      : "Purku"}
    {isOngoing && ` kesken (aloitettu ${timestamp(arrival)})`}
  </div>
);

ScheduleDestinationLabel.propTypes = {
  isVisited: PropTypes.bool.isRequired,
  isOngoing: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["load", "unload"]).isRequired,
  arrival: PropTypes.instanceOf(Moment).isRequired,
  departure: PropTypes.instanceOf(Moment).isRequired,
  className: PropTypes.string
};

export default ScheduleDestinationLabel;
