import React from "react";
import PropTypes from "prop-types";

import styles from "./DestinationOrdersSummary.module.scss";

const DestinationOrdersSummary = ({
  numOrders,
  numRows,
  commodities,
  children
}) => (
  <div className={styles.root}>
    <header>
      <div>
        <h2>
          {numOrders &&
            <React.Fragment>
              {numRows &&
              `${numRows} ${
                numRows > 1 ? "tilausriviä" : "tilausrivi"
              } `}
              {`${numOrders} tilauksella`}
            </React.Fragment>
          }
        </h2>

        {commodities.map(commodity => (
          <span key={commodity} className={styles.commodity}>
            {commodity}
          </span>
        ))}
      </div>
    </header>
    {children}
  </div>
);

DestinationOrdersSummary.propTypes = {
  numRows: PropTypes.number.isRequired,
  numOrders: PropTypes.number.isRequired,
  commodities: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DestinationOrdersSummary;
