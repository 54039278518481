import React from "react";
import PropTypes from "prop-types";

import styles from "./Completed.module.scss";

const Completed = ({ className }) => (
  <span className={`${styles.root} ${className}`} />
);

Completed.propTypes = {
  className: PropTypes.string
};

Completed.defaultPropTypes = {
  className: null
};

export default Completed;
