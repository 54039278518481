import React from 'react'
import EnrichDestinations from '../../../containers/data/EnrichDestinations';
import HEREMap from '../../maps/HEREMap.js'
import DestinationSummary from './DestinationSummary';
import Loading from '../../layout/Loading.js'
import PropTypes from "prop-types";
import styles from "./Map.module.scss";
import RoadHazards from "../../../containers/data/RoadHazards";
import appConfig from "../../../app.config"
import LogRoadHazard from "../../roadhazards/LogRoadHazard";
import { Router } from "@reach/router";
import WatchArray from "../../../containers/data/WatchArray";
import toast from "../../../utils/toast";
import { get } from "lodash";
import getNextDestination from "../utils/getNextDestination";
import getDestinationById from "../utils/getDestinationById";
import getRouteDestinations from "../utils/getRouteDestinations";
import calcDistance from "../utils/calcDistance";

class Map extends React.Component {
  constructor(props){
    super(props);

    const { deviceType } = this.props;

    this.state = {
      geolocated: deviceType === 'DeviceType.PERSONAL' ? false : this.props.geolocation.geolocated,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    let nextState = {}

    if(nextProps.deviceType === 'DeviceType.PERSONAL'){
      nextState.geolocated = false;
    }else{
      if(nextProps.geolocation.geolocated === true && !prevState.geolocated){
        nextState.geolocated = true;
      }
    }
    
    return nextState;
  }

  render(){
    const {
      geolocation,
      destinations,
      currentVehicle,
      refreshStyles,
      mapMode,
      onlyTransactions,
      destinationId,
      deviceType,
      handleSnackbarClose,
      showSnackbar
    } = this.props;

    const { geolocated } = this.state;

    // Get the next destination to determine current route
    const currentDestination = destinationId ? getDestinationById(destinations, destinationId) : getNextDestination(destinations);

    const routeId = currentDestination && currentDestination.routeId;
    // Get only the destinations for the current route
    const routeDestinations = routeId ? getRouteDestinations(destinations, routeId) : [];

    return (
      <React.Fragment>
        {(!geolocated && deviceType !== 'DeviceType.PERSONAL') ? (
          <Loading>
            Odotetaan GPS-paikannusta…
          </Loading>
        ) : (
          <EnrichDestinations destinations={routeDestinations}>
            {({destinations}) => (
              <div className={onlyTransactions ? styles.mapWrapperOnlyTransactions : styles.mapWrapper}>
                <RoadHazards logSource={appConfig.roadHazards.logSource.driver}>
                  {({roadHazards}) => (
                    <WatchArray 
                      array={roadHazards} 
                      uniqueKey={"sub"}
                      onUpdate={({ added }) => {
                        const location = deviceType === 'DeviceType.PERSONAL' ? false : geolocation.getLocation();
                        const vehicleId = get(currentVehicle, "vehicleId", undefined);
                        if(location){
                          added.forEach(hazard => {
                            const distance = calcDistance(location.longitude, location.latitude, hazard.longitude, hazard.latitude)
                            // TODO: Currently spot drivers will get notified of their own road hazard loggings.
                            // This is because they don't have the vehicle id.
                            if((!vehicleId || hazard.vehicleId !== vehicleId) && distance < 10){
                              let message = "";
                              switch(hazard.type){
                                case appConfig.roadHazards.types.reindeers:
                                  message = `Porohavainto `;
                                  break;
                                case appConfig.roadHazards.types.slippery:
                                  message = `Liukasta `;
                                  break;
                                case appConfig.roadHazards.types.accident:
                                  message = `Onnettomuus `;
                                  break;
                                default:
                                  message = `Muu havainto `;
                                  break;
                              }
                              
                              if(distance < 1){
                                message += `${parseInt(distance*1000)} metrin etäisyydellä.`
                              }
                              else {
                                message += `${distance.toFixed(1)} kilometrin etäisyydellä.`
                              }
                              setTimeout(() => {
                                const { hide } = toast.warn(message, { onClick: () => hide(), hideAfter: 20 });
                              }, 100);
                            }
                          });
                        }
                      }}
                    >
                      {({ current }) => (
                        <HEREMap
                          app_apikey={process.env.REACT_APP_HEREMAPS_API_KEY}
                          style={{background: 'grey' }}
                          geolocated={geolocated}
                          geolocation={geolocation}
                          destinations={destinations}
                          currentDestination={currentDestination}
                          roadHazards={current}
                          refreshStyles={refreshStyles}
                          mapMode={mapMode}
                          onlyTransactions={onlyTransactions}
                          destinationIdFragment={destinationId ? destinationId : null}
                          handleSnackbarClose={handleSnackbarClose}
                          showSnackbar={showSnackbar}
                        />
                      )} 
                    </WatchArray>
                  )}
                </RoadHazards>
                {currentDestination &&
                  <DestinationSummary
                    destination={currentDestination}
                    transitionDuration={100}
                    onlyTransactions={onlyTransactions}
                  />
                }
                {!onlyTransactions && 
                  <Router>
                    <LogRoadHazard path="road-hazard" getLocation={geolocation.getLocation} />
                  </Router>
                }
                
              </div>
            )}
          </EnrichDestinations>
        )}
      </React.Fragment>
    );
  }
}

Map.propTypes = {
  refreshRoutes: PropTypes.func.isRequired,
  destinationsUpdating: PropTypes.bool.isRequired,
  geolocation: PropTypes.shape({
    restart: PropTypes.func.isRequired,
    geolocated: PropTypes.bool.isRequired,
    getLocation: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
  }),
  destinations: PropTypes.array.isRequired,
  currentVehicle: PropTypes.object,
};

export default Map;