import React from 'react';
import PropTypes from 'prop-types';
import uniqBy from "lodash/uniqBy";
import deepEqual from "lodash/isEqual";

class WatchArray extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      current: [],
      added: [],
      updated: [],
      removed: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { uniqueKey } = props;

    // Check for changes
    const current = props.array ? [...props.array] : [];
    const previous = [...state.current];
    const added = [];
    const updated = [];
    const removed = [];

    if(!deepEqual(current, previous)){
      const all = uniqBy([...current, ...previous], uniqueKey);

      if (previous.length > 0) {
        all.forEach(item => {
          const id = item[uniqueKey];
          const prevMatch = previous.find(potential => potential[uniqueKey] === id);
          const newMatch = current.find(potential => potential[uniqueKey] === id);

          // not in previous destinations => a new addition to the route
          if (!prevMatch) {
            added.push(item);
          }
          // not in added destinations => a cancelled destination
          else if (!newMatch) {
            removed.push(item);
          }
          // in previous destinations, but not equal => an updated destination
          else if (
            prevMatch &&
            JSON.stringify(prevMatch) !== JSON.stringify(newMatch)
          ) {
            updated.push(item);
          }
        });
      }
      else {
        added.push(...current);
      }
    }
    
    // return lists of road hazard items
    return {
      current,
      added,
      updated,
      removed
    };
  }

  componentDidUpdate(){
    this.props.onUpdate && this.props.onUpdate(this.state);
  }

  render() {
    return this.props.children(this.state);
  }
};

WatchArray.propTypes = {
  children: PropTypes.func.isRequired,
  array: PropTypes.array.isRequired,
  uniqueKey: PropTypes.string.isRequired,
  onUpdate: PropTypes.func
};

export default WatchArray;