import gql from "graphql-tag";
import get from "lodash/get";

export const getUserQuery = gql`
  query GetUser($username: String!) {
    getUser(user: { username: $username }) {
      id: username
      email
      is_enabled
      name
      phone_number
      status
      sub
      username
    }
  }
`;

export const getUserProps = (data = { getUser: {} }) => {
  const userData = get(data, "getUser", {});

  const { username, name, email, status } = userData;

  return {
    email,
    isEnabled: userData.is_enabled,
    name,
    phone: userData.phone_number,
    status,
    userId: userData.sub,
    username
  };
};
