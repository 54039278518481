import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { selectVehicleMutation } from "../api/graphql/selectVehicle";
import Panel from "../components/layout/Panel";
import DigitInput from "../components/input/DigitInput";
import Error from "../components/layout/Error";
import Vehicles from "../containers/data/Vehicles";

import styles from "./VehicleSelection.module.scss";

class VehicleSelection extends React.Component {
  state = {
    licenseFilter: ""
  };

  render() {
    const { licenseFilter } = this.state;
    const { onSelected } = this.props;

    return (
      <Vehicles>
        {({ vehicles }) => (
          <Mutation mutation={selectVehicleMutation}>
            {selectVehicle => (
              <React.Fragment>
                <div className={styles.root}>
                  {vehicles.length > 0 && (
                    <React.Fragment>
                      <h2>Mitä rekkaa ajat?</h2>
                      <h3>Hae rekka rekisterinumerolla.</h3>
                      <DigitInput
                        license={true}
                        onChange={e =>
                          this.setState({ licenseFilter: e.target.value })
                        }
                      />
                      <Panel>
                        {vehicles
                          .filter(function(vehicle) {
                            if (licenseFilter.length > 0) {
                              return (
                                vehicle.licenseNum
                                  .replace("-", "")
                                  .includes(licenseFilter.toUpperCase()) ===
                                true
                              );
                            }
                            return true;
                          })
                          .map(vehicle => (
                            <div
                              className={styles.vehicle}
                              onClick={() => {
                                selectVehicle({
                                  variables: { vehicleId: vehicle.vehicleId, licenseNum: vehicle.licenseNum }
                                });
                                if (onSelected) onSelected();
                              }}
                              key={vehicle.vehicleId}
                            >
                              {vehicle.licenseNum}
                            </div>
                          ))}
                      </Panel>
                    </React.Fragment>
                  )}
                  {vehicles.length < 1 && (
                    <Error
                      error={{
                        message:
                          "Liikennöitsijällä ei ole aktiivisia ajoneuvoja."
                      }}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </Mutation>
        )}
      </Vehicles>
    );
  }
}

VehicleSelection.propTypes = {
  onSelected: PropTypes.func
};

export default VehicleSelection;
