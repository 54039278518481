export default {
  currentVehicle: {
    vehicleId: null,
    licenseNum: null,
    __typename: "Vehicle",
  },
  deviceType: null,
  routeId: null,
  transactionInput: [
    {
      __typename: "TransactionInput",
      id: "636087",
      waybill: "bar",
      transactionAmount: 300
    },
    {
      __typename: "TransactionInput",
      id: "636088",
      waybill: "far",
      transactionAmount: 200
    }
  ]
};
