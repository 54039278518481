import Coords from "./utils/Coords";
import pinPositionSVG from "./assets/pin-location-blue.svg";
import pinAddressSVG from "./assets/pin-location-orange.svg";
import pinWaypointSVG from "./assets/pin-location-green.svg";

import H from "@here/maps-api-for-javascript";

const w = 40
const h = 40;

export default class PositionMarker {
  static pinPosition = new H.map.Icon(pinPositionSVG, {
    size: { w: w, h: h }
  });

  static pinAddress = new H.map.Icon(pinAddressSVG, {
    size: { w: w, h: h }
  });

  static pinWaypoint = new H.map.Icon(pinWaypointSVG, {
    size: { w: w, h: h }
  });

  static Type = { position: 0, address: 1, waypoint: 2 };

  position = new Coords(0, 0);
  marker = null;
  type = PositionMarker.Type.position;
  zIndex = 10;

  constructor(coords, options) {
    const {type, zIndex} = options;
    this.setPosition(coords);
    this.setType(type);
    this.zIndex = isNaN(zIndex) ? this.zIndex : zIndex;
  }

  setType(type) {
    if (Object.values(PositionMarker.Type).includes(type)) {
      this.type = type;
      this.refreshMarker();
      return true;
    } else {
      // Invalid state, set default
      this.type = this.type || PositionMarker.Type.position;
      return false;
    }
  }

  setPosition(coords) {
    if (coords && !isNaN(coords.lat) && !isNaN(coords.lng)) {
      let newPosition = new Coords(coords.lat, coords.lng);
      if (
        newPosition.lat !== this.position.lat ||
        newPosition.lng !== this.position.lng
      ) {
        this.position = newPosition;
        this.refreshMarker();
        return true;
      }
    }
    return false;
  }

  setMarker(marker) {
    this.marker = marker;
    this.refreshMarker();
  }

  getMarker() {
    if (!this.marker) {
      let marker = new H.map.Marker(this.position, {
        icon: this.getIcon(),
        zIndex: this.zIndex
      });
      this.setMarker(marker);
      return marker;
    }
    return this.marker;
  }

  refreshMarker() {
    if (this.marker) {
      this.marker.setGeometry(
        new H.geo.Point(this.position.lat, this.position.lng)
      );
      this.marker.setIcon(this.getIcon());
      this.marker.setZIndex(this.zIndex);
    }
  }

  getCoords() {
    return this.position;
  }

  getIcon() {
    let icon;
    switch (this.type) {
      case PositionMarker.Type.address:
        icon = PositionMarker.pinAddress;
        break;
      case PositionMarker.Type.waypoint:
        icon = PositionMarker.pinWaypoint;
        break;
      default:
        icon = PositionMarker.pinPosition;
        break;
    }
    return icon;
  }
}