import React from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

import Button from "../input/Button";

import styles from "./AddDriverConfirmation.module.scss";

const ResetDriverPasswordConfirmation = ({ username, firstname, initialPassword }) => (
  <React.Fragment>
    <div className={styles.state}>
      <span>Salasana nollattu</span>
    </div>
    <div className={styles.confirmation}>
      <div className={styles.userInfo}>
        <span className={styles.infolabel}>Käyttäjätunnus:</span>
        <span className={styles.infovalue}>{username}</span>
        <span className={styles.infolabel}>PIN-koodi:</span>
        <span className={styles.infovalue}>{initialPassword}</span>
      </div>
      <p>
        Yllä on käyttäjätunnus ja PIN-koodi jotka <strong>{firstname} </strong>
        tarvitsee voidakseen vaihtaa käyttäjätilin salasanan.
      </p>
      <Button onClick={() => navigate("/admin")}>Valmis</Button>
    </div>
  </React.Fragment>
);

ResetDriverPasswordConfirmation.propTypes = {
  firstname: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  initialPassword: PropTypes.string.isRequired
};

export default ResetDriverPasswordConfirmation;
