import React from "react";
import PropTypes from "prop-types";
import { string, object } from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import Component from "@reach/component-component";

import generateUserName from "../../utils/generateUserName";

import Field from "../input/GreenField";
import Button from "../input/Button";

import styles from "./AddDriverForm.module.scss";

const initialValues = {
  contractorId: "",
  name: "",
  username: "",
  email: "",
  phoneprefix: "+358",
  phonebody: ""
};

class AddDriverForm extends React.Component {
  state = {
    error: null,
    isUsernameTouched: false
  };

  validationSchema = object().shape({
    name: string().required("Pakollinen kenttä"),
    email: string()
      .email("Tarkista sähköpostiosoite!")
      .required("Pakollinen kenttä"),
    phoneprefix: string().matches(
      /^\+\d+$/,
      "Anna maakoodi oikeassa muodossa (+358)"
    ),
    phonebody: string()
      .matches(/^[\d\s]+$/, "Tarkista puhelinnumero")
      .required("Pakollinen kenttä"),
    username: string()
      .required("Pakollinen kenttä")
      .test(
        "unique-username",
        "Käyttäjänimi on jo käytössä!",
        async value => await this.props.checkUsernameAvailability(value)
      )
  });

  onSubmit = userInfo => {
    // strip spaces from phonenumber body
    let phonebody = userInfo.phonebody.replace(/\s/g, "");
    // if the phone number includes the first zero, strip it (as the complete number will have a country code)
    phonebody = phonebody.charAt(0) === "0" ? phonebody.substr(1) : phonebody;
    // combine the phone prefix and body to a valid phone number
    const phone = `${userInfo.phoneprefix.trim()}${phonebody}`;

    // force username as lowercase. Username is not case-sensitive.
    const username = userInfo.username.toLowerCase();

    this.props.onSubmit({
      ...userInfo,
      phone,
      username
    });
  };

  render() {
    const { isAdmin } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.validationSchema}
        onSubmit={input => this.onSubmit(input)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formik => {
          const { isSubmitting, isValidating, setFieldValue} = formik;
          return (
            <React.Fragment>
              <div className={styles.state}>
                <span>Syötä tiedot</span>
              </div>
              <Form>
                <Component
                  formik={formik}
                  didUpdate={({ prevProps, props }) => {
                    const { values: prevValues } = prevProps.formik;
                    const { values } = props.formik;

                    // was the name value changed?
                    if (prevValues.name === values.name) { // it was not changed
                      return;
                    } else { // It was changed, check if username should be updated
                      if (
                        !values.username.length || // is the username field empty - dont overwrite if its already been set
                        ( generateUserName(prevValues.name) === prevValues.username &&
                          generateUserName(values.name) !== prevValues.username )// were the name and username fields in sync before the change?
                      ) {
                        values.username = generateUserName(values.name);
                        setFieldValue('username', values.username);
                      }
                    }  
                  }}
                />
                {isAdmin && (
                  <React.Fragment>
                    <label htmlFor="contractorId" className={styles.label}>
                      Liikennöitsijätunnus
                    </label>
                    <Field
                      id="contractorId"
                      disabled={isSubmitting}
                      autoFocus
                    />
                    <ErrorMessage name="contractorId" component="div" />
                    <br />
                    <br />
                    <br />
                    
                  </React.Fragment>
                )}
                <label htmlFor="name" className={styles.label}>
                  Nimi
                </label>
                <Field id="name"
                  disabled={isSubmitting} autoFocus
                />
                <ErrorMessage name="name" component="div" />
                <br />
                <label htmlFor="username" className={styles.label}>
                  Käyttäjätunnus
                </label>
                <Field id="username" name="username" disabled={isSubmitting} />
                <ErrorMessage name="username" component="div" />
                <br />
                <label htmlFor="email" className={styles.label}>
                  Sähköposti
                </label>
                <Field id="email" type="email" disabled={isSubmitting} />
                <ErrorMessage name="email" component="div" />
                <br />
                <label htmlFor="phonebody" className={styles.label}>
                  Puhelin
                </label>
                <Field
                  id="phoneprefix"
                  className={styles.phonePrefix}
                  disabled={isSubmitting}
                />
                <Field
                  id="phonebody"
                  className={styles.phoneBody}
                  disabled={isSubmitting}
                />
                <ErrorMessage name="phoneprefix" component="div" />
                <ErrorMessage name="phonebody" component="div" />
                <Button
                  className={styles.submit}
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  uppercase="uppercase"
                >
                  Lähetä kutsu
                </Button>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    );
  }
}

AddDriverForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  checkUsernameAvailability: PropTypes.func.isRequired
};

export default AddDriverForm;
