import gql from "graphql-tag";

export const getUsernameAvailability = gql`
  query GetUsernameAvailability($username: String!) {
    isUserNameAvailable(username: $username)
  }
`;

export const getUsernameAvailabilityProps = (
  response = { data: { isUserNameAvailable: false } }
) => response.data.isUserNameAvailable;
