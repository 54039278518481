import gql from "graphql-tag";

export const adminUpdateUserMutation = gql`
  mutation AdminUpdateUser(
    $email: String
    $is_enabled: Boolean
    $is_driver: Boolean
    $is_contractor: Boolean
    $name: String
    $phone_number: String
    $username: String!
    $contractor_id: String
  ) {
    adminUpdateUser(
      user: {
        email: $email
        is_enabled: $is_enabled
        is_driver: $is_driver
        is_contractor: $is_contractor
        name: $name
        phone_number: $phone_number
        username: $username
        contractor_id: $contractor_id
      }
    ) {
      id: username
      sub
      email
      is_enabled
      is_driver
      is_contractor
      is_admin
      contractor_id
      name
      phone_number
      username
    }
  }
`;
