import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./VehicleDetails.module.scss";
import Panel from "../../layout/Panel";
import get from "lodash/get";
import Button from "../../input/Button";
import { Select, MenuItem } from "@material-ui/core";

const VehicleHeader = ({licenseNum, onClick, onSelect, isOpen, isCurrent}) => (
  <div className={styles.heading} onClick={onClick}>
    <span className={''}>{licenseNum}</span>
    <div className={styles.headingRight}>
      {onSelect && (
        <Button 
          className={styles.selectButton}
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); onSelect && onSelect(); }}
          disabled={isCurrent}
        >
          {isCurrent ? 'Valittu' : 'Valitse'}
        </Button>
      )}
      {onClick && (
        <span className={styles.infoToggle}>{isOpen ? 'Piilota' : 'Näytä'} tiedot</span>
      )}
    </div>
  </div>
)

const getWithUnit = (obj, selector, unit, defaultValue) => {
  let str = get(obj, selector, null);
  if(str === null || `${str}` === '0'){
    return defaultValue;
  }
  return `${str}${unit ? ` ${unit}` : ""}`;
}

const statTypes = {
  VEHICLE: "Vehicle",
  TRAILER: "Trailer",
  COMBINATION: "Combination"
};
const statValues = {
  CONTAINER_COUNT: "ContainerCount",
  QUBICMETERS: "Qubicmeters",
  OWN_WEIGHT: "OwnWeight",
  CAPACITY: "Capacity",
  AXLE_COUNT: "AxleCount",
  TOTAL_WEIGHT: "TotalWeight"
}

const getVehicleStats = (vehicle) => {
  return {
    Labels: {
      [statValues.CONTAINER_COUNT]: "Konttien lkm",
      [statValues.QUBICMETERS]: "Kuutiometrit",
      [statValues.OWN_WEIGHT]: "Omapaino",
      [statValues.CAPACITY]: "Kantavuus kg",
      [statValues.AXLE_COUNT]: "Akselien lkm",
      [statValues.TOTAL_WEIGHT]: "Kokonaispaino",
    },
    Entries: {
      [statTypes.VEHICLE]: {
        name: "Auto",
        values: {
          [statValues.CONTAINER_COUNT]: getWithUnit(vehicle, "vehicle_containerCount", null, "–"),
          [statValues.QUBICMETERS]: getWithUnit(vehicle, "vehicle_cubicmeters", "m³", "–"),
          [statValues.OWN_WEIGHT]: getWithUnit(vehicle, "vehicle_ownWeight", "kg", "–"), 
          [statValues.CAPACITY]: getWithUnit(vehicle, "vehicle_capacityKg", "kg", "–"),
          [statValues.AXLE_COUNT]: getWithUnit(vehicle, "vehicle_axleCount", null, "–"),
          [statValues.TOTAL_WEIGHT]: getWithUnit(vehicle, "vehicle_totalWeight", "kg", "–"),
        }
      },
      [statTypes.TRAILER]: {
        name: "Perävaunu",
        values: {
          [statValues.CONTAINER_COUNT]: getWithUnit(vehicle, "trailer_containerCount", null, "–"),
          [statValues.QUBICMETERS]: getWithUnit(vehicle, "trailer_cubicmeters", "m³", "–"),
          [statValues.OWN_WEIGHT]: getWithUnit(vehicle, "trailer_ownWeight", "kg", "–"), 
          [statValues.CAPACITY]: getWithUnit(vehicle, "trailer_capacityKg", "kg", "–"),
          [statValues.AXLE_COUNT]: getWithUnit(vehicle, "trailer_axleCount", null, "–"),
          [statValues.TOTAL_WEIGHT]: getWithUnit(vehicle, "trailer_totalWeight", "kg", "–"),
        }
      },
      [statTypes.COMBINATION]: {
        name: "Yhdistelmä",
        values: {
          [statValues.CONTAINER_COUNT]: getWithUnit(vehicle, "combination_containerCount", null, "–"),
          [statValues.QUBICMETERS]: getWithUnit(vehicle, "combination_cubicmeters", "m³", "–"),
          [statValues.OWN_WEIGHT]: getWithUnit(vehicle, "combination_ownWeight", "kg", "–"), 
          [statValues.CAPACITY]: getWithUnit(vehicle, "combination_capacityKg", "kg", "–"),
          [statValues.AXLE_COUNT]: getWithUnit(vehicle, "combination_axleCount", null, "–"),
          [statValues.TOTAL_WEIGHT]: getWithUnit(vehicle, "combination_totalWeight", "kg", "–"),
        }
      }
    }
  }
}



const VehicleDetails = ({ vehicle, onOpen, isOpen, onSelect, isCurrent, refreshStyles }) => {
  const [activeStat, setActiveStat] = useState(statTypes.VEHICLE);
  const vehicleStats = getVehicleStats(vehicle);

  useEffect(() => {
    refreshStyles();
  });

  return (
    <Panel className={`${styles.root} ${isOpen ? styles.open : styles.closed} ${isCurrent ? styles.current : ''}`}>
      <VehicleHeader licenseNum={vehicle.licenseNum} onSelect={onSelect} onClick={onOpen} isOpen={isOpen} isCurrent={isCurrent}/>

      <div className={styles.detailsGridWrapper}>
        <div className={styles.detailsGrid}>

          {/* Header row desktop */}
          <div className={[styles.grid, styles.grid_m, styles.desktop].join(" ")}>
            <div className={[styles.gridElement, styles.grid25, styles.grid50m].join(" ")}>
              <span className={styles.gridTitle}>
                &nbsp;
              </span>
            </div>
            {Object.entries(vehicleStats.Entries).map((entry) => (
              // Add value from each entry for the row
              <div key={entry[0]} className={[styles.gridElement, styles.grid25, styles.grid50m].join(" ")}>
                <span className={styles.gridTitle}>
                  {entry[1].name}
                </span>
              </div>
            ))}
          </div>

          {/* Header row Mobile */}
          <div className={[styles.grid, styles.grid_m, styles.mobile].join(" ")}>
            <div className={[styles.gridElement, styles.grid25, styles.grid50m].join(" ")}>
              <span className={styles.gridTitle}>
                &nbsp;
              </span>
            </div>
            <div className={[styles.gridElement, styles.grid25, styles.grid50m].join(" ")}>
              <Select value={activeStat} className={styles.select} onChange={event => setActiveStat(event.target.value)}>
              {/* <select className={styles.gridSelect}> */}
                {Object.entries(vehicleStats.Entries).map((entry) => (
                  // Add value from each entry for the row
                  <MenuItem key={entry[0]} value={entry[0]}>
                      {entry[1].name}
                  </MenuItem>
                ))}
              {/* </select> */}
              </Select>
            </div>
          </div>

          {/* Data rows */}
          {Object.entries(vehicleStats.Labels).map((row) => (
            // Add row label
            <div key={row[0]} className={[styles.grid, styles.grid_m].join(" ")}>
              <div className={[styles.gridElement, styles.grid25, styles.grid50m].join(" ")}>
                <span className={styles.gridTitle}>
                  {row[1]}
                </span>
              </div>
              {Object.entries(vehicleStats.Entries).map((entry) => (
                // Add value from each entry for the row
                <div key={entry[0]} className={[
                  styles.gridElement, 
                  styles.grid25, 
                  styles.grid50m,
                  entry[0] !== activeStat ? styles.desktop : ''
                ].join(" ")}>
                  <span className={styles.number}>
                    {entry[1].values[row[0]]}
                  </span>
                </div>
              ))}
            </div>
          ))}

        </div>
      </div>

      <h3>Lisätiedot</h3>

      <div className={styles.grid}>
        <div className={[styles.gridElement, styles.grid50].join(" ")}>
          <span className={styles.gridTitle}>Liikennöitsijä</span>
          <span className={''}>{vehicle.contractorName || "-"}</span>
        </div>
        <div className={[styles.gridElement, styles.grid50].join(" ")}>
          <span className={styles.gridTitle}>Auton puhelinnumero</span>
          <span className={styles.number}>{vehicle.phonenumber || "-"}</span>
        </div>
      </div>
      <div className={styles.grid}>
        <div className={[styles.gridElement, styles.grid50].join(" ")}>
          <span className={styles.gridTitle}>Ajojärjestelijä</span>
          <span className={''}>{vehicle.routePlanner || "-"}</span>
        </div>
        <div className={[styles.gridElement, styles.grid50].join(" ")}>
          <span className={styles.gridTitle}>Päätteen puhelinnumero</span>
          <span className={styles.number}>{vehicle.terminal_phonenumber || "-"}</span>
        </div>
      </div>
      
    </Panel>
  );
}

VehicleDetails.propTypes = {
  vehicle: PropTypes.shape({
    licenseNum: PropTypes.string.isRequired,
    vehicleId: PropTypes.string.isRequired
  }).isRequired,
  toggleItem: PropTypes.func
};

export default VehicleDetails;