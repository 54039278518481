module.exports = {
  roadHazards: {
    logSource: {
      driver: "rahtari-app-driver",
    },
    types: {
      reindeers: "reindeer-sighting",
      slippery: "slippery-road",
      accident: "accident",
      other: "other"
    },
    timeframe: 4
  }
}