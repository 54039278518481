import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

const UnstyledLink = ({ to, children }) => {
  return (
    <Link to={to} style={{ textDecoration: "inherit", color: "inherit" }}>
      {children}
    </Link>
  );
};

UnstyledLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default UnstyledLink;
