import moment from "moment";

export default (destinations=[])=>{
    // Only take the upcoming destinations & sort these by eta
    return destinations
    .filter( (d) => d.status === "upcoming")
    .sort((b, a) =>
        moment(b.eta, "DD-MM-YYYY HH-mm").diff(
        moment(a.eta, "DD-MM-YYYY HH-mm")
        )
    );
}