import gql from "graphql-tag";
//import get from "lodash/get";

export const adminGetUsersQuery = gql`
  query AdminGetUsers {
    adminGetUsers {
      id: username
      name
      username
      initial_password
      is_enabled
      is_driver
      is_contractor
      email
      phone_number
      sub
      contractor_id
      status
    }
  }
`;

export const adminGetUsersProps = (data) => {
  return data.adminGetUsers.map(user => ({
      email: user.email,
      name: user.name || '',
      phone: user.phone_number,
      status: user.status,
      userId: user.sub,
      username: user.username,
      contractorId: user.contractor_id,
      isEnabled: user.is_enabled,
      isDriver: user.is_driver,
      isContractor: user.is_contractor,
      isAdmin: user.is_admin,
  }));
}