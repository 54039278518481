import React, {useEffect} from "react";

import styles from "./AddNew.module.scss";

const AddNew = ({ onClick, children, refreshStyles }) => {
  
  useEffect(() => {
    if (refreshStyles) {
      refreshStyles();
    }
  });

  return (
  <span className={styles.root} onClick={onClick}>
    {children}
  </span>
)};

export default AddNew;