import gql from "graphql-tag";

export const updateDriverMutation = gql`
  mutation UpdateDriver(
    $email: String
    $is_enabled: Boolean
    $name: String
    $phone_number: String
    $username: String!
  ) {
    updateUser(
      user: {
        email: $email
        is_enabled: $is_enabled
        name: $name
        phone_number: $phone_number
        username: $username
      }
    ) {
      id: username
      sub
      email
      is_enabled
      name
      phone_number
      username
    }
  }
`;
