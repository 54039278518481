import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import { Link } from "@reach/router";

import styles from "./DestinationDetails.module.scss";
import AdditionalInfo from "../AdditionalInfo";

const DestinationDetails = ({ destination }) => {

  return (
  <div className={styles.summary}>
    <div className={`${styles.type} ${styles[destination.type]}`}>
      {destination.type === "load" ? "Lastaus" : "Purku"}
    </div>
    <h2>{destination.name}</h2>
    <div className={styles.contactDetails}>
      <div className={styles.location}>
        {(get(destination, "address", "") || "").trim()} {destination.zipcode}{" "}
        {(get(destination, "city", "") || "")
          .trim()
          .toLowerCase()
          .replace(/^\w/, c => c.toUpperCase())}
        {destination.addressAdditionalInfo &&
          `, ${destination.addressAdditionalInfo}`}
      </div>
      {(destination.contactPerson || destination.contactPhone) && (
        <div className={styles.contact}>
          {destination.contactPerson && <span>{`${destination.contactPerson}`}&nbsp;</span>} {(destination.contactPhone && destination.contactPhone.length > 6) && <a href={`tel:${destination.contactPhone}`}>{destination.contactPhone}</a>}
        </div>
      )}
    </div>
    {destination.additionalInfo && (
      <div className={styles.additionalInfo}>
        <AdditionalInfo>{destination.additionalInfo}</AdditionalInfo>
      </div>
    )}
    <br/>
    <Link className={styles.showOnMapButton} to={`/routes/map-only-transactions/${destination.id}`}>Kartta</Link>
                      
  </div>
)};

DestinationDetails.propTypes = {
  destination: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    addressAdditionalInfo: PropTypes.string,
    zipcode: PropTypes.string.isRequired,
    city: PropTypes.string,
    contactPerson: PropTypes.string,
    contactPhone: PropTypes.string,
    additionalInfo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  }).isRequired
};

export default DestinationDetails;
