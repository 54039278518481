import React from "react";
import PropTypes from "prop-types";

import Button from "../input/Button";

import styles from "./LogExceptionButton.module.scss";

const LogExceptionButton = ({onClick, children }) => {
  
  return (
    <Button
      className={styles.exceptionButton}
      onClick={onClick}
      exception={"true"}
    >
      {children}
    </Button>
  )
};

LogExceptionButton.propTypes = {
  children: PropTypes.node.isRequired,
  isUpdating: PropTypes.bool,
  onClick: PropTypes.func
};

export default LogExceptionButton;
