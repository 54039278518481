import React from "react";

import styles from "./Loading.module.scss";
import Spinner from "./Spinner";

const Loading = (props) => (
  <div className={styles.root}>
    <Spinner />
    {
      props.children && props.children.length > 0 ? 
        props.children
        :
        "Ladataan…"
    }
  </div>
);

export default Loading;
