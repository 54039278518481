export default class Cache {
  cache = [];
  cacheMap = {};
  maxSize = 1000;

  limit() {
    if (this.cache.length > this.maxSize) {
      while (this.cache.length > this.maxSize) {
        this.cache.shift();
      }
      let result = {};
      for (let i = 0; i < this.cache.length; i++) {
        result[this.cache[i].key] = i;
      }

      this.cacheMap = result;
    }
  }

  create(key, value) {
    this.cacheMap[key] = this.cache.length;
    this.cache.push({ key: key, value: value });
    this.limit();
    return value;
  }

  get(key) {
    if (this.cacheMap[key]) {
      return this.cache[this.cacheMap[key]].value;
    }
    return null;
  }
}

export class DumbCache extends Cache {
  async get(key, func) {
    if (!this.cacheMap[key]) {
      let result = await func();
      if (result) {
        return this.create(key, result);
      } else {
        return null;
      }
    }
    return this.cache[this.cacheMap[key]].value;
  }
}
