import React from "react";
import PropTypes from "prop-types";
import Button from "../input/Button";
import icon from "./assets/hazard-symbol.svg";
import plus from "./assets/plus-symbol.svg";
import styles from "./LogRoadHazardButton.module.scss";

const LogRoadHazardButton = ({onClick, disabled}) => {
  return (
    <Button
      onClick={onClick}
      className={styles.root}
      disabled={disabled}
    >
      <img src={plus} alt="plus" className={styles.plus} />
      <img src={icon} alt="Ilmoita liikennehuomiosta" className={styles.icon} />
    </Button>
  )
};

LogRoadHazardButton.propTypes = {
  children: PropTypes.node.isRequired,
  isUpdating: PropTypes.bool,
  routeId: PropTypes.string,
  destiantionId: PropTypes.string
};

export default LogRoadHazardButton;
