// return a list of destinations with states
export default (destinations, states) => {
  const { added, updated, cancelled } = states;

  return destinations.map(destination => {
    const id = destination.id;

    const enriched = {
      ...destination,
      ...{
        // note the order here is the prio of the states shown in Rahtari
        state: cancelled.includes(id)
          ? "cancelled"
          : // destination has actual time of arrival and actual time of departure => a completed destination
          destination.ata && destination.atd
          ? "completed"
          : destination.ata
          ? "ongoing"
          : updated.includes(id)
          ? "updated"
          : added.includes(id)
          ? "new"
          : null
      }
    };

    return enriched;
  });
};
