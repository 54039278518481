import React from "react";
import PropTypes from "prop-types";
import { Mutation, withApollo } from "react-apollo";
import AWSAppSyncClient from "aws-appsync";
import get from "lodash/get";
import forEach from "lodash/forEach";

import getTimeStamp from "../../utils/getTimeStamp";

import getInProgressOrders from "../../components/routes/utils/getInProgressOrders";
import getDestinationsWithState from "../../components/routes/utils/getDestinationsWithState";
import getCurrentDestination from "../../components/routes/utils/getCurrentDestination";

import RouteExceptionForm from "../../components/exceptions/RouteExceptionForm";
/* Keeping these here so they are with other SISU codes */
import RouteExceptionVehicleStatuses from "../../components/exceptions/RouteExceptionStatuses";

import Modal from "../../components/layout/Modal";
import Loading from "../../components/layout/Loading";
import Button from "../../components/input/Button";
import { logRouteExceptionMutation } from "../../api/graphql/logRouteException";

/*
  There is a set of logic behind how exceptions are logged which is 
  described in much more detail in this repository here: doc/ExceptionLogic.md
*/

class LogRouteException extends React.Component {

  async getGeocoords() {
    const geoSettings = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 10000
    };

    return new Promise( (resolve,reject) => {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve({locX:pos.coords.longitude,locY:pos.coords.latitude})
        },
        (err) => {
          console.log(`Error during geolocation - ${err.code} / ${err.message}`); // DEBUG statement
          reject(err);
        },
        geoSettings
      );
    });
  }

  buildExceptionsDataForDest = (vehicleLicense, destination, destinations) => {
    // For this, the exception will be logged for the destination & active orders at that destination
    // This requires checking each transaction at the destination, 
    // - for unfinished transactions (not started or in progress) exceptions will be sent for each.
    // - for completed transactions, excpetions will be sent for the transaction if the order is still active

    let exceptions = [];

    const inProgressOrderNums = getInProgressOrders(destinations).map(order=>{
      return order.orderNum;
    });

    // For each txn at the destiantion, check if it is completed
    // - If no, log exception for the txn 
    // - If yes, check if there are futher transactions for the order
    //   - If no do not send an excpetion as order is complete.
    //   - If yes send exception for the transaction as the order is still in progress 
    get(destination, "transactions", []).forEach((txn)=>{
        // Is the transaction completed? 
        // If so check if the order has additional transactions planned for the route
        if (txn.timeEnded && txn.timeStarted) { 
          if (inProgressOrderNums.includes(txn.orderNum)) {
            // Transaction is for an order that is in progress, so log exception
            exceptions.push({
              vehicleLicense: vehicleLicense,
              status: (destination.type === "load") ? RouteExceptionVehicleStatuses.loading_completed : RouteExceptionVehicleStatuses.unloading_completed,
              routeId: destination.routeId,
              destinationId: (destination.id) ? destination.id : null,
              destinationName: (destination.name) ? destination.name : null,
              orderId: txn.orderNum,
              transactionId: txn.transactionId
            })
          } // Else transaction is for a completed order, so log nothing
        } else {  // Else transaction is started or upcoming -  so log exception
          exceptions.push({
            vehicleLicense: vehicleLicense,
            status: (destination.type === "load") ? RouteExceptionVehicleStatuses.loading_started : RouteExceptionVehicleStatuses.unloading_started,
            routeId: destination.routeId,
            destinationId: (destination.id) ? destination.id : null,
            destinationName: (destination.name) ? destination.name : null,
            orderId: txn.orderNum,
            transactionId: txn.transactionId
          })
        }

    });
    return exceptions;
  };

  buildExceptionsDataForRoute = (vehicleLicense, destinations) => {
    let exceptions = [];

    // If truck is driving, log exceptions for all active orders (orders which are partially completed)
    const inProgressOrders = getInProgressOrders(destinations);
    forEach(inProgressOrders,(order)=>{
      exceptions.push({
        vehicleLicense: vehicleLicense,
        status: RouteExceptionVehicleStatuses.driving,
        routeId: order.routeId,
        destinationId: null,
        destinationName: null,
        orderId: order.orderNum,
        transactionId: null
      });
    });

    // If there are no orders in progress, log the exception for the routeId 
    // of the next destination as the truck is on the way to start the route.
    if (!inProgressOrders.length) {
      const nextDest = getCurrentDestination(destinations);
      if (nextDest && nextDest.routeId ) {
        exceptions.push({
          vehicleLicense: vehicleLicense,
          status: RouteExceptionVehicleStatuses.other,
          routeId: nextDest.routeId,
          destinationId: null,
          destinationName: null,
          orderId: null,
          transactionId: null
        });
      } else { // else if no next destination then there is nothing to log an exception for
        exceptions.push({
          vehicleLicense: vehicleLicense,
          status: RouteExceptionVehicleStatuses.other,
          routeId: null,
          destinationId: null,
          destinationName: null,
          orderId: null,
          transactionId: null
        });
      }
    }
    return exceptions;
  }; 

  setFormInfo(destination) {
    return {
      destinationId: destination.id,
      destinationType: destination.type,
      destinationState: destination.state,
      destinationAddress: destination.address,
    }
  }


  async logRouteException(logException,exceptionsToLog,exceptionInfo,geolocated=false) {
    // Only get geolocation if truck is geolocated
    let geocoords = {};
    try {
      geocoords = (geolocated) ? await this.getGeocoords() : {};
    } catch (err) {
      console.error(`Geolocation error ${err}`);
    }
    
    // For each active routeId build & send an exception
    const routeExceptions = exceptionsToLog.map((ex)=>{
      const routeException = {
        ...ex,
        timestamp: getTimeStamp(),
        ...geocoords,
        exception:  {
          ...exceptionInfo
        }
      };
      /* This could be combined to one AppSync call with all exceptions */
      logException({
        variables: {
          routeException: routeException
        }
      });
      return routeException;
    });
    console.log('Logged exceptions', routeExceptions);
  }

  render() {
    const {
      geolocation,
      destinationId,
      destinations,
      refreshStyles
    } = this.props;

    return (
      <Mutation
        mutation={logRouteExceptionMutation}
      >
        {(logException, { loading, error, called }) => {

          //This logic will build the internal exception data (non-user defined data) 
          // which will be combined with the user entered form data before being pushed to the backend.
          let exceptionsToLog = []; 
          
          // This contains destination info that can be passed to the form for logging exceptions.
          let destination = {}
          let vehicleStatus = "other";

          const vehicleLicense = get(this.props, "currentVehicle.licenseNum", null);

          if (destinationId) { // If destinationId is defined then the exception was logged on a specific destination view (or one of the lower order/transaction views).
            destination = destinations.reduce((acc,dest) => (dest.id.toString()===destinationId) ? dest : acc, {});
            console.log('Excep dest', destination);
          }

          // If destination is upcoming or completed, then exception is logged for the destination & destination type.
          if (destination && (destination.state === "completed" || destination.state === "ongoing")) {
            exceptionsToLog = this.buildExceptionsDataForDest(vehicleLicense,destination,destinations);
            vehicleStatus = (destination.type === "load") ? "loading" : "unloading";
          } else { // Else the truck is inbetween destinations, fall back the schedule view handling

            if (!getDestinationsWithState(destinations,"ongoing").length) { // The truck driving (No "ongoing" destination states)
     
              exceptionsToLog = this.buildExceptionsDataForRoute(vehicleLicense,destinations);
              // If there is at least ONE in progeress order (goods loaded) then trhe truck is driving, else it is in other state
              vehicleStatus = (getInProgressOrders(destinations).length) ? "driving" : "other"; 

            } else { // The truck has at least one (& hopefully only one) "ongoing" destination (which means it is loading/unloading)
              // For the current "ongoing" destination build the exception data.
              destination = getDestinationsWithState(destinations,"ongoing")[0];
              this.buildExceptionsDataForDest(vehicleLicense,destination,destinations).forEach((ex)=>{
                exceptionsToLog.push(ex);
              });
              vehicleStatus = (destination.type === "load") ? "loading" : "unloading";
            }
          }

          /*
          // Error should be shown to the user but not block the app - error handling should use this component
          : error ? (
                <Error error={error} />
              ) : called && !loading && !error ? (
          */

          return (
            <Modal uncloseable={true}>
              <h3>POIKKEAMA</h3>
              {loading ? (
                <Loading />
              ) : called && !loading ? (
                <React.Fragment>
                  <span>Poikkeama lähetetty</span>
                  <br/>
                  <br/>
                  <Button 
                    onClick={() => window.history.back()}
                  >OK</Button>
                </React.Fragment>
              ) : (
                <RouteExceptionForm
                  destination={destination}
                  vehicleStatus={vehicleStatus}
                  refreshStyles={refreshStyles}
                  onSubmit={submittedExceptionInfo => this.logRouteException(logException,exceptionsToLog,submittedExceptionInfo,geolocation.geolocated)}
                />
              )}
            </Modal>
          );
        }}
      </Mutation>
    );
  }
}

LogRouteException.propTypes = {
  client: PropTypes.instanceOf(AWSAppSyncClient).isRequired,
  geolocation: PropTypes.shape({
    restart: PropTypes.func.isRequired,
    geolocated: PropTypes.bool.isRequired,
    getLocation: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
  }),
  destination: PropTypes.object
};

export default withApollo(LogRouteException);
