import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/fi.js";

import styles from "./DateLabel.module.scss";

const DateLabel = ({ dateString, status }) => {
  moment.locale("fi");
  const date = moment(dateString, "DD-MM-YYYY");
  const weekday = moment.weekdays(false, date.day());

  return (
    <div className={`${styles.root} ${styles[status]}`}>
      <span>{weekday.substr(0, 2)}</span> {date.date()}.{date.month() + 1}.
      {status === 'upcoming' && date.year()} <span>{weekday}</span>
    </div>
  );
};

DateLabel.propTypes = { dateString: PropTypes.string.isRequired };

export default DateLabel;
