import React from "react";

import CenteredContent from "./CenteredContent";
import Panel from "./Panel";
import SignOutButton from "../SignOutButton";

import styles from "./NoPermission.module.scss";

const NoPermission = ({ error }) => (
  <CenteredContent>
    <Panel className={styles.root}>
      You currently are not assigned to any groups that grant you access to the system - please contact a VR Administrator.
      <SignOutButton>Kirjaudu ulos</SignOutButton>
    </Panel>
  </CenteredContent>
);

export default NoPermission;
