import React from "react";
import PropTypes from "prop-types";

import styles from "./CenteredContent.module.scss";

const CenteredContent = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

CenteredContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default CenteredContent;
