import React from "react";
import PropTypes from "prop-types";

import styles from "./NavBar.module.scss";

const NavBar = ({ children }) => (
  <div className={styles.root}>
    <nav className={styles.nav}>{children}</nav>
  </div>
);

NavBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavBar;
