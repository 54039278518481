import gql from "graphql-tag";
import get from "lodash/get";

export const getRouteQuery = gql`
  query GetRoute($routeId: ID!) {
    getRoute(routeId: $routeId) {
      id
      routeId
      routeName
      status
      contractorId
      vehicleId
      additionalInfo
      destinations {
        id
        destinationId
        order
        type
        name
        address
        addressAdditionalInfo
        zipcode
        city
        additionalInfo
        eta
        etd
        contactPerson
        contactPhone
        ata
        atd
        transactions {
          id
          transactionId
          orderNum
          orderRefNum
          customerOrderNum
          customerRefNum
          waybillNum
          commodityName
          commodityAmount
          commodityUnit
          units
          kilos
          items
          orderedAmount
          orderedUnit
          actualAmount
          actualUnit
          timeStarted
          timeEnded
          plannedStartTime
          plannedEndTime
          customerCommodityName
          length
          width
          height
        }
      }
    }
  }
`;

// This is a legacy function that needs removed, it was originally here when all data was passed in as a string.
// Now the API returns a ful GraphQL response so this can be removed from teh codebase.
// I am leaving this here whilst testing the response to avoid breaking anything in the codebase.
export const getRouteProps = (data = { getRoute: {} }) => {
  try {
    const route = get(data, "getRoute", {});
    return route;
  } catch (e) {
    console.log("Error parsing props:", e);
    return {};
  }
};
