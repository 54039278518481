import gql from "graphql-tag";

export const getRoadHazards = gql`
  query GetRoadHazards($logSource: String!, $timeframe: Float) {
    getRoadHazards(logSource: $logSource, timeframe: $timeframe) {
      sub
      type
      vehicleId
      longitude: locX
      latitude: locY
    }
  }
`;