import gql from "graphql-tag";

export const addRoadHazardMutation = gql`
  mutation addRoadHazard($roadHazard: RoadHazardInput) {
    addRoadHazard(roadHazard: $roadHazard) {
      sub
      type
      vehicleId
      longitude: locX
      latitude: locY
    }
  }
`;