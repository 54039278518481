import React from "react";
import PropTypes from "prop-types";

import DeviceType from "../../enum/DeviceType";
import DeviceTypeSelection from "../../components/DeviceTypeSelection";
import CurrentUser from "./CurrentUser";

const Device = ({ children }) => (
  <CurrentUser>
    {currentUser =>
      !DeviceType.isValid(currentUser.deviceType) ? (
        // invalid (or undefined) device type
        <DeviceTypeSelection />
      ) : (
        children({ deviceType: currentUser.deviceType })
      )
    }
  </CurrentUser>
);

Device.propTypes = {
  children: PropTypes.func.isRequired
};

export default Device;
