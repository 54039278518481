import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Field from "./GreenField";

import styles from "./LoadingDetailsInput.module.scss";
import amountValidation from "../../containers/data/utils/amountValidation";

class LoadingDetailsInput extends React.Component {
  componentDidMount() {
    // store the initial values as user input to enable validation
    const { waybillNum, actualAmount, weightNoteNumberLoading } = this.props;
    this.props.onChange({ waybill: waybillNum, amountLoaded: actualAmount, weightNoteNumberLoading: weightNoteNumberLoading });
  }

  validate(values) {
    // This function call updates the state of the parent component 
    // so the form input is stored correctly.
    this.props.onChange(values);
    let errors = {
      amountLoaded: this.validateAmount(values.amountLoaded),
      waybill: this.validateWaybill(values.waybill),
      weightNoteNumberLoading: false
    };
    return errors;
  }

  validateWaybill(value){
    return value && value.toString().length > 0 ? undefined : "Pakollinen";
  }

  validateAmount(value){
    const {loadUnit} = this.props;
    return amountValidation(value, loadUnit);
  }

  render() {
    const { loadUnit, waybillNum, weightNoteNumberLoading } = this.props;
    const actualAmount = !isNaN(this.props.actualAmount) ? this.props.actualAmount : "";
    return (
      <Formik
        initialValues={{
          waybill: waybillNum || "",
          amountLoaded: actualAmount,
          weightNoteNumberLoading: weightNoteNumberLoading || ""
        }}

        // This is hack to store the values entered in the Formik form to 
        // the context of the main order - it's not actually for validating 
        // the form
        validate={values => this.validate(values)}
      >
        {formik => {
          const { 
            //isSubmitting, 
            values,
            //errors,
            //touched
            //setFieldError
          } = formik;
          let { errors } = formik;

          errors = errors || {};
          errors.waybill = this.validateWaybill(values.waybill);
          errors.amountLoaded = this.validateAmount(values.amountLoaded);

          return (
            <Form>
              <div className={styles.fields}>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Rahtikirja</span>
                    <Field
                      id="waybill"
                      valid={!errors.waybill}
                      maxLength={20}
                    />
                  </div>
                  {errors && errors.waybill && <span className={styles.errorLabel}>{errors.waybill}</span>}
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lastattu</span>
                    <Field
                      id="amountLoaded"
                      type="text"
                      unit={loadUnit}
                      valid={!errors.amountLoaded}
                      inputMode="text"
                      pattern="[0-9,.]*"
                    />
                    {loadUnit && <span className={styles.unitLabel}>{loadUnit}</span>}
                  </div>
                  {errors && errors.amountLoaded && <span className={styles.errorLabel}>{errors.amountLoaded}</span>}
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lastauksen punnitustosite</span>
                    <Field
                      id="weightNoteNumberLoading"
                      valid={!errors.weightNoteNumberLoading}
                      maxLength={20}
                    />
                  </div>
                  {errors && errors.weightNoteNumberLoading && <span className={styles.errorLabel}>{errors.weightNoteNumberLoading}</span>}
                </div>
              </div>

            </Form>
          )
        }}
      </Formik>
    );
  }
}

LoadingDetailsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  loadUnit: PropTypes.string,
  waybillNum: PropTypes.string,
  weightNoteNumberLoading: PropTypes.string,
  actualAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default LoadingDetailsInput;
