/*
It appears that when using cache-and-netowrk policy from Apollo client, 
"loading" is set to true whilst the query is fetching data from the network
even if the data can be fetched first from the cache (which makes the cache-and-network polkicy kind of useless)

What this means is that even though there is cached data available, the app will still display "loading" 
whilst the network is queried. This especially is apparant on slow network connections. 

If the device is offline then the cache is read directly and the behaviour is as expected.

This issue has a wrapper, which only returns loading if the data is not in the cache:
https://github.com/apollographql/react-apollo/issues/1217#issuecomment-567691399

This is a wrapper for the Apollo query components to return an extra value when the cache 
needs to be used to make sure the app has quick responses.

THIS DOES NOT HAVE THE FULL FUNCTIONALITY OF THE BASE APOLLO <QUERY>! 

It just has what was needed, modify this is further functionality is needed.
*/

import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";

const CachedQuery = ({ query, variables, fetchPolicy, children }) => {
    return (
        <Query
            query={query}
            variables={variables}
            fetchPolicy={fetchPolicy}
        >
            {({ loading, error, data, client }) => {

                // Check if result is already in the cache
                if (loading) {
                    try {
                        client.readQuery({ query, variables }); // Read from cache. Error thrown if empty
                        return children({ loading: true, cached: true, data, client });
                    } catch (err) {
                        return children({ loading: true, cached: false, client});
                    }
                }
                // If other error, show error message
                if (error) return children({ error: error });

                return children({ loading: false, cached: true, data, client });
            }}
        </Query>
    );
};

CachedQuery.propTypes = {
    query: PropTypes.object.isRequired,
    variables: PropTypes.object,
    fetchPolicy: PropTypes.string,
    children: PropTypes.func.isRequired
};

CachedQuery.defaultProps = {
    fetchPolicy: "cache-and-network"
};

export default CachedQuery;
