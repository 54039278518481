import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import styles from "./GreenField.module.scss";

const GreenField = props => {
  const { id, unit, className, valid, ...rest} = props;
  return (
    <div
      className={`${styles.fieldWrapper} ${
        valid ? styles.valid : ""
      } ${className}`}
    >
      <Field
        {...rest}
        name={id}
        className={`${styles.field} ${unit ? styles.unit : ""} ${className}`}
      />
    </div>
  );
};

GreenField.propTypes = {
  id: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  unit: PropTypes.string,
  valid: PropTypes.bool
};

GreenField.defaultProps = {
  autoFocus: false,
  className: "",
  disabled: false,
  type: "text",
  unit: null,
  valid: false
};

export default GreenField;
