import memoize from "memoize-one";
import deepEqual from "lodash/isEqual";

export default memoize(
  items =>
    items.length > 1
      ? ` (+ ${items.length - 1} ${items.length > 2 ? "muuta" : "muu"})`
      : "",
  deepEqual
);
