import gql from "graphql-tag";
//import get from "lodash/get";

export const getUsersQuery = gql`
  query GetUsers {
    getUsers {
      id: username
      email
      is_enabled
      name
      phone_number
      status
      sub
      username
    }
  }
`;

export const getUsersProps = (data) => {
  return data.getUsers.map(user => ({
      email: user.email,
      isEnabled: user.is_enabled,
      name: user.name,
      phone: user.phone_number,
      status: user.status,
      userId: user.sub,
      username: user.username
  }));
}