import React from "react";
import PropTypes from "prop-types";

import styles from "./Dashboard.module.scss";

const Dashboard = ({ children }) => {
  return (
    <div className={styles.root}>
      <main className={styles.main}>{children}</main>
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired
};

export default Dashboard;
