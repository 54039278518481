import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import * as Sentry from '@sentry/browser';
import {
  getVehiclesProps,
  getVehiclesQuery,
  getVehiclesDetailsProps,
  getVehiclesDetailsQuery,
  getCurrentVehicleQuery
} from "../../api/graphql/getVehicles";

import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";

const Vehicles = ({ children, withDetails }) => (
  <Query query={withDetails ? getVehiclesDetailsQuery : getVehiclesQuery}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if(error) {
        Sentry.captureEvent({
          message: "Query error - Vehicles",
          extra: {
            'error': error,
            'data': data,
            'variableWithDetails': withDetails,
          },
        });          
      }
      if (error) return <Error error={error} />;

      const vehicles = withDetails ? getVehiclesDetailsProps(data) : getVehiclesProps(data);

      return children({ vehicles });
    }}
  </Query>
);

Vehicles.propTypes = {
  children: PropTypes.func.isRequired,
  withDetails: PropTypes.bool
};

export default Vehicles;

// CurrentVehicle
export const CurrentVehicle = ({ vehicles, children }) => (
  <Query query={getCurrentVehicleQuery}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if(error) {
        Sentry.captureEvent({
          message: "Query error - Vehicles - CurrentVehicle",
          extra: {
            'error': error,
            'data': data,
          },
        });          
      }
      if (error) return <Error error={error} />;

      const { currentVehicle } = data;
      return children({ currentVehicle });
    }}
  </Query>
);

CurrentVehicle.propTypes = {
  children: PropTypes.func.isRequired,
  vehicles: PropTypes.array.isRequired
};
