import Coords from "./utils/Coords";
import pinSlipperySVG from "../roadhazards/assets/road-hazard-slippery-shadow.svg";
import pinReindeerSVG from "../roadhazards/assets/road-hazard-reindeers-shadow.svg";
import pinAccidentSVG from "../roadhazards/assets/road-hazard-accident-shadow.svg";
import pinOtherSVG from "../roadhazards/assets/road-hazard-other-shadow.svg";

import H from "@here/maps-api-for-javascript";

const w = 40
const h = 40;

export default class roadHazardPin {
  static pinSlippery = new H.map.Icon(pinSlipperySVG, {
    size: { w: w, h: h }
  });
  static pinReindeer = new H.map.Icon(pinReindeerSVG, {
    size: { w: w, h: h },
  });
  static pinAccident = new H.map.Icon(pinAccidentSVG, {
    size: { w: w, h: h },
  });
  static pinOther = new H.map.Icon(pinOtherSVG, {
    size: { w: w, h: h },
  });
  static Type = { slippery: 0, reindeer: 1, accident: 2, other: 3 };

  position = new Coords(0, 0);
  marker = null;
  type = roadHazardPin.Type.other;
  zIndex = 1;

  constructor(coords, options) {
    const {type, zIndex} = options;
    this.setPosition(coords);
    this.setType(type);
    this.zIndex = isNaN(zIndex) ? this.zIndex : zIndex;
  }

  setType(type) {
    if (Object.values(roadHazardPin.Type).includes(type)) {
      this.type = type;
      this.refreshMarker();
      return true;
    } else {
      // Invalid state, set default
      this.type = this.type || roadHazardPin.Type.other;
      return false;
    }
  }

  setPosition(coords) {
    if (coords && !isNaN(coords.lat) && !isNaN(coords.lng)) {
      let newPosition = new Coords(coords.lat, coords.lng);
      // When the coodinates have changed, calculate a new bearing and set the new position
      if (
        newPosition.lat !== this.position.lat ||
        newPosition.lng !== this.position.lng
      ) {
        this.position = newPosition;
        this.refreshMarker();
        return true;
      }
    }
    return false;
  }

  setMarker(marker) {
    this.marker = marker;
    this.refreshMarker();
  }

  getMarker() {
    if (!this.marker) {
      let marker = new H.map.Marker(this.position, {
        icon: this.getIcon(),
        zIndex: this.zIndex
      });
      this.setMarker(marker);
      return marker;
    }
    return this.marker;
  }

  refreshMarker() {
    if (this.marker) {
      this.marker.setGeometry(
        new H.geo.Point(this.position.lat, this.position.lng)
      );
      this.marker.setIcon(this.getIcon());
      this.marker.setZIndex(this.zIndex);
    }
  }

  getCoords() {
    return this.position;
  }

  getIcon() {
    let icon;
    switch (this.type) {
      case roadHazardPin.Type.accident:
        icon = roadHazardPin.pinAccident;
        break;
      case roadHazardPin.Type.slippery:
        icon = roadHazardPin.pinSlippery;
        break;
      case roadHazardPin.Type.reindeer:
        icon = roadHazardPin.pinReindeer;
        break;
      default:
        icon = roadHazardPin.pinOther;
        break;
    }
    return icon;
  }
}