export default value => {
  if(typeof(value) == 'number'){
    return value;
  }
  else if(typeof(value) == 'string'){
    value = value.trim();
    if(/^\d+[,.]?\d*$/.test(value)){
      return parseFloat(value.replace(/,/g, '.'));
    }
  }
  return NaN;
}
