import React, {useEffect} from "react";
import PropTypes from "prop-types";

import authAPI from "../api/authAPI";

import styles from "./SignOutButton.module.scss";

const onLogout = () => {
  authAPI.signOut();
  // This reload is a quick 'fix' to make sure that all cached appsync data is discarded 
  // and on logout the index.html is refreshed to load the latest application code
  window.location.reload(true); 
};

const SignOutButton = ({ className, children, refreshStyles }) => {
  useEffect(() => {
    if(refreshStyles) {
      refreshStyles();
    }
  });
  return <span onClick={onLogout} className={`${className} ${styles.button}`}>{children}</span>;
};

SignOutButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default SignOutButton;
