import React from "react";
import PropTypes from "prop-types";

import styles from "./MultiSelectionButton.module.scss";

class MultiSelectionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { clicked: false };
  }

  onClick(e) {
    this.setState({ clicked: true });
    if (this.props.onClick) this.props.onClick(e);
  }

  render() {
    const { props } = this;

    return (
          <button
            {...props}
            className={`${"className"} ${styles.root} ${
              props.checked ? styles.checked : styles.unchecked 
            } ${
              props.disabled ? styles.disabled : "" 
            } ${ styles[props.appearance] }`}
            disabled={
              // outside prop always overrides internal state
              props.disabled !== undefined ? props.disabled : this.state.clicked
            }
            onClick={props.type !== "submit" ? e => this.onClick(e) : null}
          >
            {props.children}
          </button>
    );
  }
}

MultiSelectionButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  appearance: PropTypes.oneOf(['primary', 'secondary'])
};

MultiSelectionButton.defaultProps = {
  type: "button",
  className: "",
  checked: false,
  appearance: "primary"
};

export default MultiSelectionButton;
