import React from "react";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";

import styles from "./Button.module.scss";

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = { clicked: false };
  }

  onClick(e) {
    this.setState({ clicked: true });
    if(this.props.loadingOnClick){
      this.setState({ isLoading: true });
    }
    if (this.props.onClick) this.props.onClick(e);
  }

  render() {
    const { className, cancel, exception, color, block, loadingOnClick, isLoading, disabled, children, ...props } = this.props;

    const _isLoading = isLoading !== undefined ? isLoading : this.state.isLoading;
    const _isDisabled = disabled !== undefined ? disabled : this.state.clicked;

    return (
      <button
        {...props}
        className={`${className} ${styles.root} ${
          cancel ? styles.cancel : ""
        } ${exception ? styles.exception : ""} ${
          color ? styles[color] : ""
        } ${block ? styles.block : ""} ${
          className ? styles[className] : ""
        } ${
          isLoading ? styles.loading : ""
        }`}
        disabled={_isLoading || _isDisabled}
        onClick={props.type !== "submit" ? e => this.onClick(e) : null}
      >
        <div className={styles.content}>
          {children}
        </div>
        {_isLoading && <Spinner color={"#fff"} secondaryColor={"#000"} className={styles.loadingSpinner} />}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingOnClick: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  className: "",
  disabled: false,
  isLoading: false,
  loadingOnClick: false,
};

export default Button;
