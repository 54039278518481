import AWSAppSyncClient, { createAppSyncLink, AUTH_TYPE } from "aws-appsync";
import { ApolloLink, Observable } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import { onError } from 'apollo-link-error';

import get from "lodash/get";

import authAPI from "../api/authAPI";
import resolvers from "./resolvers";
import defaults from "./defaults";

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  defaults,
  resolvers
});

const authConfig = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    const session = await authAPI.getCurrentSession(); // Calling this refreshes the authorization token
    return session ? session.getIdToken().getJwtToken() : null;
  }
};

const onErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // If we get an type of unauthorized errors the refresh the token & try once more - if it still fails 
  let retry = false;
  
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (get(err, "errorType", null)) {
        case 'UnauthorizedException':
          retry = true;
          break;
        default:
          // Do nothing
          break;

      }
    }
  }
  if (networkError && networkError.message.includes('Received status code 401')) {
    retry = true;
  }

  if (retry) {
    return new Observable(observer => {
      authAPI.refreshSession().then((session) => {
        const oldHeaders = operation.getContext().headers;
        const token = (session) ? session.getIdToken().getJwtToken()
        : (oldHeaders.Authorization) ? oldHeaders.Authorization
        : null;
        operation.setContext({
          headers: {
            ...oldHeaders,
            Authorization: token,
          },
        });
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        }
        // Retry last failed request
        forward(operation).subscribe(subscriber)
      })
      .catch(error => {
        observer.error(error)
      });
    });
  } else {
    return;
  }
});

// set up AWS AppSync Client to be used with ApolloClient
const getAppSyncClient = (url, region) => {
  
  const appSyncConfig = {
    url,
    region,
    auth: authConfig,
    options: {
      fetchPolicy: "cache-and-network"
    }
  };

  return new AWSAppSyncClient(appSyncConfig, {
    cache,
    link: ApolloLink.from([
      onErrorLink,
      stateLink, 
      createAppSyncLink(appSyncConfig)]
    )
  });
};

export default getAppSyncClient;
