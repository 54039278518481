import React from "react";
import PropTypes from "prop-types";

import styles from "./AdditionalInfo.module.scss";

class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    }
  }

  collapse(e) {
    e.preventDefault();
    
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const { children } = this.props;

    return (
      <div className={styles.root}>
        {Array.isArray(children) ? (
          children.map((info,i) => <div key={i} className={this.state.collapsed ? styles.collapsed : ''} onClick={this.collapse.bind(this)}>{info}</div>)
        ) : (
            <div className={this.state.collapsed ? styles.collapsed : ''} onClick={this.collapse.bind(this)}>{children}</div>
        )}
      </div>
    );
  }
}

AdditionalInfo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

export default AdditionalInfo;
