import gql from "graphql-tag";

export const adminResetPasswordMutation = gql`
  mutation AdminResetPassword($username: String!) {
    adminResetPassword(user: { username: $username }) {
      sub
      username
      initial_password
    }
  }
`;
