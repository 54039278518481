import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import * as Sentry from '@sentry/browser';
import fromDate from "../../utils/fromDate";

import {
  getVehicleRoutesQuery,
  getVehicleRoutesProps
} from "../../api/graphql/getVehicleRoutes";

import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import parseDestinations from "./utils/parseDestinations";

const VehicleDestinations = ({ vehicleId, children }) => {
  return (
    <Query
      query={getVehicleRoutesQuery}
      variables={{
        vehicleId,
        fromDate: fromDate(4)
      }}
      pollInterval={15*60*1000} //refresh every 15 minutes
      notifyOnNetworkStatusChange
      fetchPolicy="cache-and-network"
      partialRefetch={true}
    >
      {({ error, data, refetch, networkStatus }) => {

        if (networkStatus === 1) return <Loading />;
        if(error) {
          Sentry.captureEvent({
            message: "Query error - VehicleDestinations",
            extra: {
              'error': error,
              'data': data,
              'networkStatus': networkStatus,
              'variableVehicleId': vehicleId,
              'variableFromDate': fromDate(4)
            },
          });          
        }
        if (error) return <Error error={error} />;

        const routes = getVehicleRoutesProps(data);

        // There has been an issue during Aug 2020 where all routes were being cancelled on occasing
        // Now, if there is a failure with the parsing of the route data an error will just be displayed
        if (!routes) {
          let parsed = "";
          try {
            parsed = JSON.stringify(data);
          } catch (e) {
            console.error('Could not parse JSON reponse for VehicleDestinations - ', e);
          }
          return <Error error={new Error(`Call to Rahtari API returned incorrect data! Returned data was: ${parsed}`)} />;
        }
        const destinations = parseDestinations(routes);

        return children({
          destinations,
          refetch,
          loadingDestinations: networkStatus < 7,
          completeVehicleDestinationsQueryData: {'data': data, 'vehicleId': vehicleId, 'fromDate': fromDate(4), 'networkStatus': networkStatus, 'error': error}
        });
      }}
    </Query>
  );
};

VehicleDestinations.propTypes = {
  vehicleId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default VehicleDestinations;
