import React from 'react';
import fetch from 'cross-fetch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Coords from "./utils/Coords.js";

const HEREMAPS_GEOCODE_BASE_URL = 'https://geocode.search.hereapi.com/v1/geocode';
const API_KEY = process.env.REACT_APP_HEREMAPS_API_KEY;

export default function AddressSearch(props) {
  const initialValue = '';
  const [inputValue, setOpen] = React.useState(initialValue);
  const [options, setOptions] = React.useState([]);
  const loading = !!inputValue && inputValue.length >= 2;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await fetch(HEREMAPS_GEOCODE_BASE_URL +
        '?q=' + inputValue +
        '&apiKey=' + API_KEY +
        '&in=countryCode:FIN');
      const addresses = await response.json();
      const items = addresses.items;
      if (active && items) {
        setOptions(items);
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue]);

  React.useEffect(() => {
    if (!inputValue) {
      setOptions([]);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      id="address-search"
      style={{ width: 300 }}
      open={!!inputValue}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(_event, value) => {
        setOpen(value);
      }}
      onChange={(_event, value, reason) => {
        if(value) {
          const location = new Coords(value.position.lat, value.position.lng);
          props.onSelect(location);
        }
        if(reason === 'clear') {
          props.onSelect(undefined);
        }
      }}
      getOptionSelected={(option, value) => option.title === value.name}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      forcePopupIcon={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Osoitehaku"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
