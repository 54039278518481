import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import * as Sentry from '@sentry/browser';
import { roadHazards } from "../../app.config";
import { getRoadHazards } from "../../api/graphql/getRoadHazards";
import { get } from "lodash";

const RoadHazards = ({children, logSource}) => (
  <Query
    query={getRoadHazards}
    variables={{
      logSource,
      timeframe: roadHazards.timeframe // timeframe in hours
    }}
    pollInterval={5 * 60 * 1000} //refresh every 5 minutes
    //notifyOnNetworkStatusChange
    fetchPolicy="cache-and-network"
  >
    {({ error, data }) => {
      if(error) {
        Sentry.captureEvent({
          message: "Query error - Roadhazards",
          extra: {
            'error': error,
            'data': data,
            'variableLogsource': logSource
          },
        });          
      }
      return children({roadHazards: get(data, "getRoadHazards", [])});
    }}
  </Query>
);

RoadHazards.propTypes = {
  children: PropTypes.func.isRequired,
  logSource: PropTypes.string.isRequired,
};

export default RoadHazards;
