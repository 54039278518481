import gql from "graphql-tag";

export const routeDestinationTransactionFragment = gql`
    fragment RouteDestinationTransaction on RouteDestinationTransaction {
        id
        transactionId
        orderNum
        orderRefNum
        customerOrderNum
        customerRefNum
        waybillNum
        commodityName
        commodityAmount
        commodityUnit
        units
        kilos
        items
        orderedAmount
        orderedUnit
        actualAmount
        actualUnit
        timeStarted
        timeEnded
        plannedStartTime
        plannedEndTime
        customerCommodityName
        length
        width
        height
    }
`;

export default routeDestinationTransactionFragment;