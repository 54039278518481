import Cache from "./Cache.js";
import Coords from "./Coords.js";

class GeocodeCache extends Cache {
  platform = null;
  geocoder = null;

  constructor(geocoder) {
    super();
    this.geocoder = geocoder;
  }

  setGeocoder(geocoder) {
    this.geocoder = geocoder;
  }

  async get(address, zipcode, city) {
    const _address = address ? `${address}`.split(",")[0] + ", " : "";
    const _zipcode = zipcode || "";
    const _city = city || "";
    const key = `${_address}${_zipcode} ${_city}`;
    if (this.cacheMap[key]) {
      return this.cache[this.cacheMap[key]].value;
    }

    return new Promise((resolve, reject) => {
      let geocodingParams = {
          searchText: key
        },
        onResult = result => {
          if (result.Response.View.length > 0) {
            const location =
              result.Response.View[0].Result[0].Location.DisplayPosition;
            const coords = new Coords(location.Latitude, location.Longitude);
            this.create(key, coords);
            resolve(coords);
          } else {
            console.log(`Could not geocode address: ${key}`);
            reject(`Could not geocode address: ${key}`);
          }
        },
        onError = error => {
          reject(error);
        };
      this.geocoder.geocode(geocodingParams, onResult, onError);
    });
  }
}

export default GeocodeCache;
