import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Panel from "../../layout/Panel";
import Button from "../../input/Button";

import styles from "./Finished.module.scss";

const Finished = ({ type, eta, etd, ata, atd, onClose }) => {
  const etaDate = moment(eta, "DD-MM-YYYY HH:mm");
  const etdDate = moment(etd, "DD-MM-YYYY HH:mm");
  const ataDate = moment(ata, "DD-MM-YYYY HH:mm");
  const atdDate = moment(atd, "DD-MM-YYYY HH:mm");
  const sameDay = ataDate.isSame(atdDate, "day");

  const diffHoursEstimated = etdDate.diff(
    moment(etaDate, "DD-MM-YYYY HH:mm"),
    "hours"
  );

  const diffMinutesEstimated =
    etdDate.diff(moment(etaDate, "DD-MM-YYYY HH:mm"), "minutes") % 60;

  const diffHoursActual = atdDate.diff(
    moment(ataDate, "DD-MM-YYYY HH:mm"),
    "hours"
  );

  const diffMinutesActual =
    atdDate.diff(moment(ataDate, "DD-MM-YYYY HH:mm"), "minutes") % 60;

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.content}>
          {type === "load" && <h1>Lastattu</h1>}
          {type === "unload" && <h1>Purettu</h1>}

          <span className={styles.date}>
            {ataDate.format("D.M.YYYY, [klo] HH:mm")} -{" "}
            {sameDay
              ? atdDate.format("HH:mm")
              : atdDate.format("D.M.YYYY, [klo] HH:mm")}
          </span>

          <Panel>
            {diffHoursEstimated > 0 && diffMinutesEstimated > 0 && (
              <div className={styles.time}>
                <span className={styles.title}>Suunniteltu</span>
                <span className={styles.number}>{`${
                  diffHoursEstimated > 0 ? `${diffHoursEstimated} h` : ""
                } ${diffMinutesEstimated} min`}</span>
              </div>
            )}
            <div className={styles.time}>
              <span className={styles.title}>Toteutunut</span>
              <span className={styles.number}>{`${
                diffHoursActual > 0 ? `${diffHoursActual} h` : ""
              } ${diffMinutesActual} min`}</span>
            </div>
          </Panel>

          <Button onClick={onClose} color="green">
            Valmis
          </Button>
        </div>
      </div>
    </div>
  );
};

Finished.propTypes = {
  type: PropTypes.oneOf(["load", "unload"]),
  eta: PropTypes.string.isRequired,
  etd: PropTypes.string.isRequired,
  ata: PropTypes.string.isRequired,
  atd: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Finished;
