import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { navigate } from "@reach/router";

import getTimeString from "../../../utils/getTimeString";

import styles from "./DestinationFooter.module.scss";
import Button from "../../input/Button";
import LogExceptionButton from "../../exceptions/LogExceptionButton";

class DestinationFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { elapsed: this.getElapsed(this.props.ata) };
  }

  componentDidMount() {
    this.tick = setInterval(() => {
      this.forceUpdate();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.tick);
  }

  getElapsed(timestamp) {
    if (!timestamp) return null;

    const hours = moment().diff(moment(timestamp, "DD-MM-YYYY HH:mm"), "hours");
    const minutes =
      moment().diff(moment(timestamp, "DD-MM-YYYY HH:mm"), "minutes") % 60;

    return `${hours} h ${minutes} min`;
  }

  render() {
    const {
      id,
      ata,
      atd,
      eta,
      etd,
      state,
      type,
      allowActions,
      allowExceptions,
      onAction,
      isLoading,
    } = this.props;

    const elapsed = this.getElapsed(ata);

    const etaDate = moment(eta, "DD-MM-YYYY HH:mm");
    const etaString = getTimeString(etaDate);
    const etdDate = moment(etd, "DD-MM-YYYY HH:mm");
    const etdString = getTimeString(etdDate);
    const ataDate = moment(ata, "DD-MM-YYYY HH:mm");
    const ataString = getTimeString(ataDate);
    const atdDate = moment(atd, "DD-MM-YYYY HH:mm");
    const atdString = getTimeString(atdDate);

    return (
      <footer className={`${styles.footer} ${styles[state]}`}>
        <React.Fragment>
          {state === "ongoing" ? (
            <span className={styles.time}>
              <span>
                <span className={styles.label}>Aikaa mennyt</span>
                {elapsed}
              </span>
            </span>
          ) : state === "completed" ? (
            <span className={styles.time}>
              <span>
                <span className={styles.label}>
                  {type === "load" ? "Lastattu" : "Purettu"}{" "}
                  {atdDate.format("D.M.YYYY")}
                </span>
                {`${ataString} –  ${atdString}`}
              </span>
              <span>
                <span className={styles.label}>
                  {type === "load" ? "Lastauksen kesto" : "Purun kesto"}
                </span>
                {`${atdDate.diff(
                  moment(ataDate, "DD-MM-YYYY HH:mm"),
                  "hours"
                )} h ${atdDate.diff(
                  moment(ataDate, "DD-MM-YYYY HH:mm"),
                  "minutes"
                ) % 60} min`}
              </span>
            </span>
          ) : (
            <span className={styles.time}>
              {`${etaString}${eta !== etd ? ` –  ${etdString}` : ""}`}
            </span>
          )}
        </React.Fragment>
        <div className={styles.buttonContainer}>
          {allowExceptions && state && (
            <LogExceptionButton 
              onClick={() => {
                navigate(`/routes/exception/${id}`);
              }}
            >
              Poikkeama
            </LogExceptionButton>
          )}
          {allowActions && state !== "completed" && (
            <Button onClick={onAction} isLoading={isLoading}>
              {state === "ongoing"
                ? type === "load"
                  ? "Lopeta lastaus"
                  : "Lopeta purku"
                : type === "load"
                  ? "Aloita lastaus"
                  : "Aloita purku"}
            </Button>
          )}
        </div>
      </footer>
    );
  }
}

DestinationFooter.propTypes = {
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool,
  ata: PropTypes.string,
  atd: PropTypes.string,
  eta: PropTypes.string.isRequired,
  etd: PropTypes.string,
  state: PropTypes.oneOf([
    "new",
    "updated",
    "cancelled",
    "completed",
    "ongoing"
  ]),
  type: PropTypes.oneOf(["load", "unload"]).isRequired,
  onAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default DestinationFooter;
