import React from "react";
import PropTypes from "prop-types";

import styles from "./OrderTransactions.module.scss";
import OrderTransactionsSummary from "./OrderTransactionsSummary";
import OrderTransaction from "./OrderTransaction";

const OrderTransactions = ({
  rows,
  transactionIds,
  isOngoingLoad,
  isOngoingUnload,
  onUpdateLoad,
  onUpdateUnload,
  allowActions,
  type
}) => {
  const transactions = [];

  rows && rows.forEach(row =>
    row.transactions.forEach(transaction => {
      if (
        transactionIds.includes(transaction.pickupTransactionId) ||
        transactionIds.includes(transaction.unloadTransactionId)
      ) {
        transactions.push(transaction);
      }
    })
  );

  return (
    <React.Fragment>
      <OrderTransactionsSummary numRows={transactions.length} />

      <div className={styles.dataHeader}>
        <span className={styles.dataProductName}>Tuotenimi</span>
        <span className={styles.dataAmount}>
          {type === "load" ? "Tilattu" : "Kuljetuksessa"}
        </span>
        <span className={styles.dataWeight}>Kilot</span>
        <span className={styles.dataItems}>Kollit</span>
        <span className={styles.dataDimensions}>Mitat</span>
      </div>

      <div>
        {rows && rows.map(row => {
          // Build a key for each row. The row does not included a UUID, but transactions do
          // This ID needs to be kept consistent so that focus is kept on any form fields whilst a user is typing (see defect FTL-186)
          const rowKey = row.transactions.reduce((ids,transaction)=>{
              return `${ids}${transaction.pickupTransactionId}${transaction.unloadTransactionId}`
            },""
          );
          return (
            <React.Fragment key={rowKey}>
              {row.transactions
                .filter(
                  transaction =>
                    transactionIds.includes(transaction.unloadTransactionId) ||
                    transactionIds.includes(transaction.pickupTransactionId)
                )
                .map(transaction => (
                  <OrderTransaction
                    key={`${transaction.pickupTransactionId}${
                      transaction.unloadTransactionId
                    }`}
                    additionalInfo={row.additionalInfo}
                    commodityName={row.commodityName}
                    items={row.items}
                    height={row.height}
                    length={row.length}
                    weight={row.weight}
                    width={row.width}
                    actualAmount={transaction.actualAmount}
                    actualUnit={transaction.actualUnit}
                    orderedAmount={transaction.orderedAmount}
                    orderedUnit={transaction.orderedUnit}
                    pickupTransactionId={transaction.pickupTransactionId}
                    unloadTransactionId={transaction.unloadTransactionId}
                    receiver={transaction.receiver}
                    unloadAddress={transaction.unloadAddress}
                    unloadAddressAdditionalInfo={
                      transaction.unloadAddressAdditionalInfo
                    }
                    unloadCity={transaction.unloadCity}
                    unloadPerson={transaction.unloadPerson}
                    unloadPhone={transaction.unloadPhone}
                    unloadZipcode={transaction.unloadZipcode}
                    waybillNum={transaction.waybillNum}
                    type={type}
                    allowActions={allowActions}
                    isOngoingLoad={isOngoingLoad}
                    isOngoingUnload={isOngoingUnload}
                    onUpdateLoad={onUpdateLoad}
                    onUpdateUnload={onUpdateUnload}
                    weightNoteNumberLoading={transaction.weightNoteNumberLoading}
                    weightNoteNumberUnloading={transaction.weightNoteNumberUnloading}
                  />
                ))}
            </React.Fragment>
          )}
        )}
      </div>
    </React.Fragment> 
  );
};

OrderTransactions.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      orderedAmount: PropTypes.number.isRequired,
      orderedUnit: PropTypes.string.isRequired,
      commodityName: PropTypes.string.isRequired,
      weight: PropTypes.number,
      items: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
      length: PropTypes.number,
      additionalInfo: PropTypes.array.isRequired,
      transactions: PropTypes.array.isRequired
    })
  ).isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOngoingLoad: PropTypes.bool.isRequired,
  isOngoingUnload: PropTypes.bool.isRequired,
  allowActions: PropTypes.bool.isRequired,
  onUpdateLoad: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["load", "unload"]).isRequired
};

export default OrderTransactions;
