import React from "react";
import { Mutation, graphql } from "react-apollo";
import { addRoadHazardMutation } from "../../api/graphql/addRoadHazard";
import { getRoadHazards } from "../../api/graphql/getRoadHazards";
import { getCurrentVehicleQuery } from "../../api/graphql/getVehicles";
import Modal from "../layout/Modal";
import styles from "./LogRoadHazard.module.scss";
import RoadHazardOptionButton from "./RoadHazardOptionButton";
import { roadHazards } from "../../app.config";
import PropTypes from "prop-types";
import authAPI from "../../api/authAPI";
import { get } from "lodash";
import uuidv4 from "uuid/v4";

class LogRoadHazard extends React.Component {
  constructor(props){
    super(props);
    this.submitRoadHazard = this.submitRoadHazard.bind(this);
  }

  async submitRoadHazard(type, mutation){
    const {attributes : user} = await authAPI.getCurrentUserInfo();
    const currentVehicle = get(this.props, "data.currentVehicle", null);
    const location = this.props.getLocation();
    const logSource = this.props.logSource || roadHazards.logSource.driver;
    const vehicleId = get(currentVehicle, "vehicleId", undefined);
    const sub = uuidv4();
    const options = {
      variables: {
        roadHazard: {
          sub: sub,
          logSource: logSource,
          type: type,
          locX: get(location, "longitude", undefined),
          locY: get(location, "latitude", undefined),
          userId: get(user, "sub", undefined),
          contractorId: get(user, "custom:contractor_id", undefined),
          vehicleId: vehicleId,
          vehicleLicense: get(currentVehicle, "licenseNum", undefined),
        }
      }, 
      optimisticResponse: {
        __typename: "Mutation",
        addRoadHazard: {
          sub: sub,
          vehicleId: vehicleId,
          longitude: location.longitude,
          latitude: location.latitude,
          type: type,
          __typename: "RoadHazard"
        }
      }
    };

    mutation(options);
  }

  getClickHandler(mutate){
    return (type) => {
      this.submitRoadHazard(type, mutate);
    }
  }

  render() {
    return (
      <Mutation 
        mutation={addRoadHazardMutation}
        ignoreResults
        onCompleted={() => window.history.back()}
        onError={() => window.history.back()}
        update = {(proxy, { data: { addRoadHazard } }) => {
          const query = getRoadHazards;
          const variables = {
            logSource: this.props.logSource || roadHazards.logSource.driver,
            timeframe: roadHazards.timeframe
          };
          // Read the data from the cache
          const data = proxy.readQuery({ 
            query,
            variables
          });
          // Add new Road Hazard to the data
          data.getRoadHazards.push(addRoadHazard);
          // Write data back to the cache
          proxy.writeQuery({ query, variables, data });
        }}
      >
        {(addRoadHazard) => {
          const onClick = this.getClickHandler(addRoadHazard);
          return (
            <Modal uncloseable={false} contained={true} zIndex={400}>
              <React.Fragment>
                <h3>Ilmoita tiehavainnosta:</h3>
                <div className={styles.options}>
                  <div className={styles.row}>
                    <RoadHazardOptionButton type={roadHazards.types.reindeers} onClick={onClick} />
                    <RoadHazardOptionButton type={roadHazards.types.slippery} onClick={onClick} />
                  </div>
                  <div className={styles.row}>
                    <RoadHazardOptionButton type={roadHazards.types.accident} onClick={onClick} />
                    <RoadHazardOptionButton type={roadHazards.types.other} onClick={onClick} />
                  </div>
                </div>
              </React.Fragment>
            </Modal>
          )
        }}
      </Mutation>
    );
  }
}

LogRoadHazard.propTypes = {
  getLocation: PropTypes.func.isRequired
}

export default graphql(
  getCurrentVehicleQuery
)(LogRoadHazard);