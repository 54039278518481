import gql from "graphql-tag";
import get from "lodash/get";

export const getOrderQuery = gql`
  query GetOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      id
      orderNum
      orderDate
      customerNum
      customerName
      customerOrderNum
      customerRefNum
      orderRefNum
      additionalInfo
      delivery
      rows {
        commodityName
        orderedAmount
        orderedUnit
        transportAmount
        transportUnit
        weight
        items
        length
        width
        height
        additionalInfo
        transactions {
          contractorId
          status
          vehicleId
          waybillNum
          routeId
          sender
          pickupTransactionId
          pickupLocation
          pickupAddress
          pickupAddressAdditionalInfo
          pickupZipcode
          pickupCity
          pickupDoor
          pickupHours
          pickupCoordinatesX
          pickupCoordinatesY
          pickupPerson
          pickupPhone
          pickupEmail
          receiver
          unloadTransactionId
          unloadLocation
          unloadAddress
          unloadAddressAdditionalInfo
          unloadZipcode
          unloadCity
          unloadDoor
          unloadHours
          unloadCoordinatesX
          unloadCoordinatesY
          unloadPerson
          unloadPhone
          unloadEmail
          orderedAmount
          orderedUnit
          actualUnloadEndTime
          actualAmount
          actualUnit
          actualPickupStartTime
          actualPickupEndTime
          actualUnloadStartTime
        }
      }
    }
  }
`;

// This is a legacy function that needs removed, it was originally here when all data was passed in as a string.
// Now the API returns a ful GraphQL response so this can be removed from teh codebase.
// I am leaving this here whilst testing the response to avoid breaking anything in the codebase.
export const getOrderProps = (data = { getOrder: {} }) => {
  try {
    const order = get(data, "getOrder", {});
    return order;
  } catch (e) {
    console.log("Error parsing props:", e);
    return null;
  }
};
