import React from "react";
import PropTypes from "prop-types";
import Panel from "../../layout/Panel";

import styles from "./InvalidOrders.module.scss";
import Button from "../../input/Button";

const InvalidOrders = ({ numOrders, children, onClose }) => {
  return (
    <div className={styles.root}>
      <Panel className={styles.content}>
        <div className={styles.heading}>
          Tilausriveissä on puutteita
        </div>
        {numOrders === 1 && (
          <div className={styles.subheading}>Tarkista vielä tämä tilaus:</div>
        )}
        {numOrders > 1 && (
          <div className={styles.subheading}>
            Tarkista vielä nämä tilaukset:
          </div>
        )}
        <div className={styles.orders} onClick={onClose}>
          {children}
        </div>
        <div className={styles.controls}>
          <Button onClick={onClose}>Jatka</Button>
        </div>
      </Panel>
    </div>
  );
};

InvalidOrders.propTypes = {
  numOrders: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
};

export default InvalidOrders;
