import gql from "graphql-tag";
import get from "lodash/get";

export const addDriverMutation = gql`
  mutation AddDriver(
    $username: String!
    $name: String
    $email: String
    $phone_number: String
  ) {
    addUser(
      user: {
        username: $username
        name: $name
        email: $email
        phone_number: $phone_number
      }
    ) {
      id: username
      name
      username
      initial_password
      is_enabled
      email
      phone_number
      sub
      contractor_id
      status
    }
    addUserToCache @client
  }
`;

export const addDriverProps = (data = { addUser: {} }) => {
  const userData = get(data, "addUser", {});

  const { username, name, initial_password } = userData;

  return {
    name,
    username,
    initialPassword: initial_password
  };
};
