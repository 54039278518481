import React from "react";
import { Link } from "@reach/router";

import "./NavLink.module.scss";

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isPartiallyCurrent }) => ({
      className: `${props.className ? props.className : ""} ${isPartiallyCurrent ? "active" : ""}`
    })}
  />
);

export default NavLink;
