import React, {useEffect} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from 'lodash/get';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

//import { string, array, object } from "yup";
import { Formik, Form } from "formik";
import { TextField } from "formik-mui";

import Field from "../input/Field";
import Button from "../input/Button";
import MultiSelectionButton from "../input/MultiSelectionButton";

import getTimeStamp from "../../utils/getDateTime";

import styles from "./ExceptionForm.module.scss";

/* Exceptions categories */
import RouteExceptionInfo from "./RouteExceptionInfo.json";


const InputGroup = ({
  value,
  error,
  touched,
  id,
  label,
  children
}) => {
  return (
    <div className={styles.inputGroup}>
      {/*touched && <ErrorMessage error={error} />*/}
      <legend>{label}</legend>
      <div className={styles.inputs}>
        {children}
      </div>
    </div>
  );
};

const FormFieldError = ({children}) => {
  return (
    <div className={styles.formFieldError}>
      {children}
    </div>
  );
};

const FormFieldStatus = ({valid,children}) => {
  return (
    <div className={styles.formFieldStatus}>
      {children}
    </div>
  );
};


// Input feedback
/*const InputFeedback = ({ error }) =>
  error ? <div className={styles.inputFeedback}>{error}</div> : null;*/

const RouteExceptionForm = (props) => {
  
  useEffect(() => {
    if(props.refreshStyles) {
      props.refreshStyles();
    }
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedExCategory, setSelectedExCategory] = React.useState(null);
  const [selectedExType, setSelectedExType] = React.useState(null);
  const [selectedExTypeCode, setSelectedExTypeCode] = React.useState(null);
  const [isOrderDelayed, setIsOrderDelayed] = React.useState(null);

  /*
    Validatiopns tart as true as defaults conform to the validation schema
  */
  const [isTimeValid, setIsTimeValid] = React.useState(true);
  const [isDateValid, setIsDateValid] = React.useState(true);
  const [isAddressValid, setIsAddressValid] = React.useState(true);
  const [isInfoValid, setIsInfoValid] = React.useState(true);

  const [exceptionInfo] = React.useState(RouteExceptionInfo);

  const steps = getSteps();

  function getSteps() {
    return ['Kuljetuksen vaihe', 'Poikkeama', 'Lisätiedot'];
  }

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleCancel() {
    window.history.back();
  }

  function validateInput(values) {
    /* First check user input is valid */
    validateDate(values.detailExDateDay,values.detailExDateMonth,values.detailExDateYear);
    validateTime(values.detailExTimeHour,values.detailExTimeMin);
    validateAddress(values.detailExPlace);
    validateInfo(values.detailExInfo);
  }

  function validateForm() {
    /* Check all required fields are filled & valid */
    return (selectedExCategory &&
        selectedExType &&
        isTimeValid &&
        isDateValid &&
        isAddressValid &&
        isInfoValid &&
        isOrderDelayed) ? true : false;
  }

  function validateTime(hour,min) {
    setIsTimeValid((parseInt(hour)>=0 && parseInt(hour)<=23 && parseInt(min)>=0 && parseInt(min)<=59 ) ? true : false);
  }

  // Date must be pp.kk.vvvv
  function validateDate(day,month,year) {
    setIsDateValid((moment(`${day}.${month}.${year}`, 'DD.MM.YYYY', true).isValid())? true : false);
  }

  function validateAddress(address) {
    setIsAddressValid((address.length<100) ? true : false);
  }

  function validateInfo(info) {
    setIsInfoValid((info.length<200) ? true : false);
  }

  function handleDateTimeInput(e,maxLength=2) {
    // Format input:
    // 1. Remove prefixing zero
    // 2. Ignore non-numeric input
    // 3a. Enforce maximum length
    // 3b. Add prefixing zero (if required)

    // 1 & 2
    let formatedValue = e.target.value 
      .replace(/^0+/, '')
      .replace(/[^0-9]/g, '');
    // 3 OR 4
    if (formatedValue > maxLength) {
      formatedValue = formatedValue.substr(-maxLength);
    } else {
      while (formatedValue.length < maxLength) formatedValue = "0" + formatedValue;
    }
    // Update value
    e.target.value = formatedValue;
  }

  function handleTextInput(e,maxLength=100) {
    // Block any data entry that is over the limit
    e.target.value = e.target.value.substr(0,maxLength);
  }

  /*
    Multi select button handling
  */
  function setExCategory(id) {
    setSelectedExCategory(id);
    setSelectedExTypeCode(null); // If the exception ID has changed, reset the category selection
    handleNext();
  }

  function setExType(id,code) {
    setSelectedExType(id);
    setSelectedExTypeCode(code);
    handleNext();
  }

  function setExDelay(code) {
    setIsOrderDelayed(code);
  }

  /* 
    The exception logging form is build around a Material UI stepper component
    Multiple choice steps will only move to the next step when a category/type is select
    The final form page (extra details) contains the form submit button
  */
  function getStepContent(stepIndex,values,errors,touched) {

    switch (stepIndex) {
      case 0:
        /*const destinationId = get(props, "destination.id", "");
        const destinationType = get(props, "destination.type", "");
        const destinationState = get(props, "destination.state", "");*/
        //const ongoingOrders = does truck have a load?

        /* 
          Some options are only available in certain states (e.g. order being unloaded at destination)
          Display the correct message depending on the properties of the form
        */

       const vehicleStatus = get(props, "vehicleStatus", "other");
        const msg = (vehicleStatus === 'loading') ? "PURKU -vaihtoehto on tarjolla ainoastaan purun aikana."
                  : (vehicleStatus === 'unloading') ? "LASTAUS -vaihtoehto on tarjolla ainoastaan lastauksen aikana."
                  : (vehicleStatus === 'driving') ? "Vaihtoehdot LASTAUS ja PURKU ovat tarjolla ainoastaan kyseisten vaiheiden ollessa käynnissä."
                  : "AJO -vaihtoehto on tarjolla ainoastaan tilaus on lastattu."; // vehicle status is 'other'

                  return (<InputGroup
                  id="selectedExCategory"
                  label="Valitse kuljetuksen vaihe"
                  value={selectedExCategory}
                  error={errors.selectedExCategory}
                  touched={touched.selectedExCategory}
                >
                <div className={styles.inputGroupInfo}>{msg}</div>
                {Object.keys(exceptionInfo).map((exCatId)=>{
                  return (
                        <MultiSelectionButton
                          key={exCatId}
                          name="selectedExCategory"
                          checked={exCatId === selectedExCategory}
                          id={exCatId}
                          appearance="primary"
                          onClick={()=>setExCategory(exCatId)}
                          disabled={!get(exceptionInfo[exCatId], ['active'], []).includes(vehicleStatus)}
                        >
                          {exceptionInfo[exCatId].label}
                        </MultiSelectionButton>
                  );
                })}
              </InputGroup>);
      case 1:
        return (<InputGroup
                  id="selectedExType"
                  label={`Valitse ${selectedExCategory} poikkeama`}
                  value={values.selectedExType}
                  error={errors.selectedExType}
                  touched={touched.selectedExType}
                > 
                  {Object.keys(exceptionInfo[selectedExCategory].types).map((exTypeId)=>{

                    const exTypeLabel = exceptionInfo[selectedExCategory]["types"][exTypeId]["label"];
                    const exTypeCode = exceptionInfo[selectedExCategory]["types"][exTypeId]["code"];

                    return (
                      <MultiSelectionButton
                        key={exTypeLabel}
                        name="selectedExCategory"
                        checked={exTypeId === selectedExType}
                        id={exTypeId}
                        onClick={()=>setExType(exTypeId,exTypeCode)}
                        appearance="secondary"
                      >
                      {exTypeLabel}
                      </MultiSelectionButton>
                    );
                  })}
                </InputGroup>);
      case 2:
        return (<React.Fragment>
                  <InputGroup
                    id="fieldGroupExDetails"
                    label={`${selectedExType}, ${selectedExCategory} poikkeama lisätiedot`}
                    value={values.fieldGroupExDetails}
                    error={errors.fieldGroupExDetails}
                    touched={touched.fieldGroupExDetails}
                  > 
                    <div className={styles.timeFieldWrapper}>
                      <label htmlFor="detailExTime" className={styles.fieldlabel}>
                      Tapahtuma-aika
                      </label>
                      <div id="detailExTime" className={`${styles.dateFieldContainer} ${(isTimeValid) ? "" : styles.invalid}`}>
                        <Field
                          id="detailExTimeHour"
                          name="detailExTimeHour"
                          disabled={false}
                          className={styles.dateTimeFieldShort}
                          component={TextField}
                          onInput={(e)=>handleDateTimeInput(e,2)}
                          InputProps={{ disableUnderline: true, maxLength: 2 }}
                        />
                        <span>:</span>
                        <Field
                          id="detailExTimeMin"
                          name="detailExTimeMin"
                          disabled={false}
                          className={styles.dateTimeFieldShort}
                          component={TextField}
                          onInput={(e)=>handleDateTimeInput(e,2)}
                          InputProps={{ disableUnderline: true, maxLength: 2 }}
                        />
                      </div>
                      {!isTimeValid && 
                        <FormFieldError>
                          Anna kellonaika muodossa tt:mm (esim. 12:30)
                        </FormFieldError>
                      }
                    </div>
                    <div className={styles.dateTimeFieldSpacer}></div>
                    <div className={styles.dateFieldWrapper}>
                      <label htmlFor="detailExDate" className={styles.fieldlabel}>
                      Tapahtuma-päivämäärä
                      </label>
                      <div id="detailExDate" className={`${styles.dateFieldContainer} ${(isDateValid) ? "" : styles.invalid}`}>
                        <Field
                          id="detailExDateDay"
                          name="detailExDateDay"
                          disabled={false}
                          className={styles.dateTimeFieldShort}
                          component={TextField}
                          onInput={(e)=>handleDateTimeInput(e,2)}
                          InputProps={{ disableUnderline: true, maxLength: 2 }}
                        />
                        <span>.</span>
                        <Field
                          id="detailExDateMonth"
                          name="detailExDateMonth"
                          disabled={false}
                          className={styles.dateTimeFieldShort}
                          component={TextField}
                          onInput={(e)=>handleDateTimeInput(e,2)}
                          InputProps={{ disableUnderline: true, maxLength: 2 }}
                        />
                        <span>.</span>
                        <Field
                          id="detailExDateYear"
                          name="detailExDateYear"
                          disabled={false}
                          className={styles.dateTimeFieldLong}
                          component={TextField}
                          onInput={(e)=>handleDateTimeInput(e,4)}
                          InputProps={{ disableUnderline: true, maxLength: 4 }}
                        />
                      </div>
                      {!isDateValid && 
                        <FormFieldError>
                          Anna päivämäärä muodossa pp.kk.vvvv (esim. 24.05.2018)
                        </FormFieldError>
                      }
                    </div>
                    <label htmlFor="detailExPlace" className={styles.fieldlabel}>
                    Tapahtumapaikka
                    </label>
                    <Field
                      id="detailExPlace"
                      name="detailExPlace"
                      disabled={false}
                      className={`${styles.fieldmultiline} ${(isAddressValid) ? "" : styles.invalid}`}
                      component={TextField}
                      onInput={(e)=>handleTextInput(e,100)}
                      InputProps={{ disableUnderline: true }}
                    />
                    <FormFieldStatus>
                      Kentän pituus {values.detailExPlace.length}/100
                    </FormFieldStatus>
                    <label htmlFor="detailExInfo" className={styles.fieldlabel}>
                    Mitä tapahtui?
                    </label>
                    <Field
                      id="detailExInfo"
                      name="detailExInfo"
                      disabled={false}
                      className={`${styles.fieldmultiline} ${(isInfoValid) ? "" : styles.invalid}`}
                      component={TextField}
                      onInput={(e)=>handleTextInput(e,200)}
                      multiline
                      rows={2}
                      InputProps={{ disableUnderline: true }}
                    />
                    <FormFieldStatus>
                      Kentän pituus {values.detailExInfo.length}/200
                    </FormFieldStatus>
                  </InputGroup>
                  <InputGroup
                  id="isOrderDelayed"
                  label="Onko Kuljetus Myöhässä?"
                  value={isOrderDelayed}
                  error={errors.isOrderDelayed}
                  touched={touched.isOrderDelayed}
                >
                {["KYLLÄ","EI"].map((choice)=>{
                  // This question will alaways be a binary YES or NO
                  return (
                        <MultiSelectionButton
                          key={choice}
                          name="isOrderDelayed"
                          checked={choice === isOrderDelayed}
                          id={choice}
                          appearance="secondary"
                          disabled={false}
                          onClick={()=>setExDelay(choice)}
                        >
                          {choice}
                        </MultiSelectionButton>
                  );
                })}
              </InputGroup>
            </React.Fragment>);        
      default:
        return <span>Error: Exception step not found! </span>;
    }
  }

  function onSubmit(exceptionData,{resetForm}) {
    props.onSubmit({
      category: selectedExCategory,
      type: selectedExType,
      code: selectedExTypeCode,
      info: exceptionData.detailExInfo,
      time: `${exceptionData.detailExDateYear}-${exceptionData.detailExDateMonth}-${exceptionData.detailExDateDay} ${exceptionData.detailExTimeHour}:${exceptionData.detailExTimeMin}`,
      place: exceptionData.detailExPlace
    });
  };

  /* This validation controls the form submission button */
  const isFormValid = validateForm();

  const initTime = getTimeStamp();
  const address = get(props, "destination.address", "");
  return (
    <Formik
      initialValues={{
        detailExInfo: "",
        detailExDateDay: initTime.day||"",
        detailExDateMonth: initTime.month||"",
        detailExDateYear: initTime.year||"",
        detailExTimeHour: initTime.hour||"",
        detailExTimeMin: initTime.minute||"",
        detailExPlace: address||"",
      }}
      //validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={validateInput}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formik => {
        const { 
          isSubmitting, 
          values,
          errors,
          touched} = formik;

        return (
          <React.Fragment>
            <Form className={styles.root}>
              <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(label => (
                <Step             
                  key={label}
                  classes={{
                    completed: styles.stepIconCompleted
                  }}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        completed: styles.stepIconCompleted,
                        active: styles.stepIconActive,
                      }
                    }}
                  >{label}</StepLabel>
                </Step>
              ))}
              </Stepper>
              <div>
                <div>
                  <span className={styles.instructions}>{getStepContent(activeStep,values,errors,touched)}</span>
                  <div>
                  {(activeStep === 0) &&
                    <Button
                      disabled={activeStep !== 0}
                      onClick={handleCancel}
                      cancel="true"
                    >
                      Peruuta
                    </Button>
                  }
                  {activeStep !== 0 &&
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      cancel="true"
                    >
                      Takaisin
                    </Button>
                  }
                  {activeStep === (steps.length-1) &&
                    <Button
                      className={styles.submit}
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting || !isFormValid}
                      uppercase="uppercase"
                    >
                      Lähetä
                    </Button>
                  }
                  </div>
                </div>
              </div>
              <br />
            </Form>
          </React.Fragment>
        )
      }}
    </Formik>
  );
}

RouteExceptionForm.propTypes = {
  vehicleStatus: PropTypes.oneOf(["loading", "unloading", "driving", "other"]),
  destination: PropTypes.shape({
    destinationId: PropTypes.string,
    destinationType: PropTypes.string,
    destinationState: PropTypes.string,
    destinationAddress: PropTypes.string,
  })
};

export default RouteExceptionForm;
