import gql from "graphql-tag";

export const getVehiclesQuery = gql`
  query GetVehicles {
    getVehicles {
      id: vehicleId
      vehicleId
      licenseNum
    }
  }
`;

export const getVehiclesDetailsQuery = gql`
  query GetVehicles {
    getVehicles {
      id: vehicleId
      vehicleId
      licenseNum
      contractorId
      contractorName
      phonenumber
      routePlanner
      driverType
      vehicle_containerCount
      vehicle_cubicmeters
      vehicle_ownWeight
      vehicle_capacityKg
      vehicle_axleCount
      vehicle_totalWeight
      trailer_containerCount
      trailer_cubicmeters
      trailer_ownWeight
      trailer_capacityKg
      trailer_axleCount
      trailer_totalWeight
      combination_containerCount
      combination_cubicmeters
      combination_ownWeight
      combination_capacityKg
      combination_axleCount
      combination_totalWeight
      terminal_phonenumber
      startOfService
      endOfService
      company
      createDate
      updateDate
      source
      pickupDate
    }
  }
`;

export const getVehiclesProps = (data = { getVehicles: [] }) => {
  return data.getVehicles.map(item => {
    const { vehicleId, licenseNum } = item;
    return {
      vehicleId,
      licenseNum
    };
  });
};

export const getVehiclesDetailsProps = (data = { getVehicles: [] }) => {
  return data.getVehicles.map(item => {
    const {
      vehicleId, 
      licenseNum, 
      contractorId,
      contractorName,
      phonenumber,
      routePlanner,
      driverType,
      vehicle_containerCount,
      vehicle_cubicmeters,
      vehicle_ownWeight,
      vehicle_capacityKg,
      vehicle_axleCount,
      vehicle_totalWeight,
      trailer_containerCount,
      trailer_cubicmeters,
      trailer_ownWeight,
      trailer_capacityKg,
      trailer_axleCount,
      trailer_totalWeight,
      combination_containerCount,
      combination_cubicmeters,
      combination_ownWeight,
      combination_capacityKg,
      combination_axleCount,
      combination_totalWeight,
      terminal_phonenumber,
      startOfService,
      endOfService,
      company,
      createDate,
      updateDate,
      source,
      pickupDate
    } = item;
    return {
      vehicleId,
      licenseNum,
      contractorId,
      contractorName,
      phonenumber,
      routePlanner,
      driverType,
      vehicle_containerCount,
      vehicle_cubicmeters,
      vehicle_ownWeight,
      vehicle_capacityKg,
      vehicle_axleCount,
      vehicle_totalWeight,
      trailer_containerCount,
      trailer_cubicmeters,
      trailer_ownWeight,
      trailer_capacityKg,
      trailer_axleCount,
      trailer_totalWeight,
      combination_containerCount,
      combination_cubicmeters,
      combination_ownWeight,
      combination_capacityKg,
      combination_axleCount,
      combination_totalWeight,
      terminal_phonenumber,
      startOfService,
      endOfService,
      company,
      createDate,
      updateDate,
      source,
      pickupDate
    };
  });
};

export const getCurrentVehicleQuery = gql`
  query currentVehicle {
    currentVehicle @client  {
      vehicleId
      licenseNum 
    }
  }
`;

