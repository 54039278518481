import React, {useEffect} from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Panel from "../../layout/Panel";

import styles from "./ScheduleDestination.module.scss";
import Transactions from "./Transactions";
import Completed from "../../layout/Completed";
import ArrowCircle from "../../layout/ArrowCircle";
import UnstyledLink from "../../layout/UnstyledLink";
import getTimeString from "../../../utils/getTimeString";
import ScheduleDestinationLabel from "./ScheduleDestinationLabel";
import PrefetchDestinationOrders from "../../../containers/routes/PrefetchDestinationOrders";

const ScheduleDestination = props => {

  useEffect(() => {
    props.refreshStyles();
  });

  return (
    <React.Fragment>
      <PrefetchDestinationOrders destination={props.destination}/>
      {props.status === "upcoming" ? (
        <UpcomingScheduleDestination {...props} />
      ) : (
        <CompletedScheduleDestination {...props.destination} />
      )}
    </React.Fragment>
  ) 
  ;
};

ScheduleDestination.propTypes = {
  status: PropTypes.oneOf(["upcoming", "completed"]).isRequired,
  destination: PropTypes.shape({
    type: PropTypes.oneOf(["load", "unload"]).isRequired,
    name: PropTypes.string,
    city: PropTypes.string,
    eta: PropTypes.string.isRequired,
    etd: PropTypes.string,
    ata: PropTypes.string,
    atd: PropTypes.string,
    state: PropTypes.oneOf([
      "new",
      "updated",
      "cancelled",
      "completed",
      "ongoing"
    ]),
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        orderNum: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  dismissCancelled: PropTypes.func.isRequired
};

export default ScheduleDestination;

class UpcomingScheduleDestination extends React.Component {
  render() {
    const {
      eta,
      etd,
      ata,
      atd,
      type,
      name = "",
      city = "",
      transactions,
      state,
      id
    } = this.props.destination;

    const isCancelled = state === "cancelled";
    const isAdded = state === "new";
    const isUpdated = state === "updated";
    const visitedDestination = !!ata && !!atd;

    const arrivalDate = moment(ata || eta, "DD-MM-YYYY HH:mm");
    const departureDate = atd
      ? moment(atd, "DD-MM-YYYY HH:mm")
      : etd
      ? moment(etd, "DD-MM-YYYY HH:mm")
      : null;

    const arrival = getTimeString(arrivalDate);
    const departure =
      departureDate && !arrivalDate.isSame(departureDate)
        ? getTimeString(departureDate)
        : null;

    const content = (
      <div
        className={`${styles.root} ${styles.upcoming} ${styles[type]} ${
          isCancelled ? styles.cancelled : null
        } ${visitedDestination ? styles.visited : null}`}
      >
        <div className={styles.note}>
          {isAdded && <div className={styles.added}>UUSI</div>}
          {isCancelled && (
            <div className={styles.cancelled}>
              PERUTTU
              <div
                className={styles.delete}
                onClick={() => this.props.dismissCancelled(id)}
              />
            </div>
          )}
          {isUpdated && <div className={styles.updated}>PÄIVITETTY</div>}
        </div>
        <Panel className={styles.details}>
          <div className={styles.time}>
            <span className={styles.start}>{arrival}</span>
            {departure && <span className={styles.arrow} />}
            {departure && <span className={styles.end}>{departure}</span>}
          </div>
          <ScheduleDestinationLabel
            isVisited={visitedDestination}
            type={type}
            isOngoing={state === "ongoing"}
            arrival={arrivalDate}
            departure={departureDate}
            className={styles.type}
          />
          <div className={styles.destination}>
            {name},{" "}
            <span className={styles.city}>
              {city && city.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
            </span>
          </div>
          <div>
            <div className={styles.transactions}>
              <Transactions transactions={transactions} />
            </div>
          </div>
          {!isCancelled && !visitedDestination && (
            <ArrowCircle className={styles.arrowCircle} />
          )}
          {visitedDestination && <Completed />}
        </Panel>
      </div>
    );

    return !isCancelled ? (
      <UnstyledLink to={`destination/${id}`}>{content}</UnstyledLink>
    ) : (
      <div>{content}</div>
    );
  }
}

const CompletedScheduleDestination = ({ eta, type, name, city, id }) => {
  const arrivalDate = moment(eta, "DD-MM-YYYY HH:mm");
  const arrival = `${arrivalDate.hour()}:${`0${arrivalDate.minute()}`.slice(
    -2
  )}`;

  return (
    <UnstyledLink to={`destination/${id}`}>
      <div className={`${styles.root} ${styles.completed} ${styles[type]}`}>
        <Panel className={styles.details}>
          <div className={styles.time}>
            <span className={styles.start}>{arrival}</span>
          </div>
          <div className={styles.type}>
            {type === "load" ? "Lastaus" : "Purku"}
          </div>
          <div className={styles.destination}>
            {name},{" "}
            <span className={styles.city}>
              {city && city.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
            </span>
          </div>
        </Panel>
      </div>
    </UnstyledLink>
  );
};
