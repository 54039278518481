import React from "react";
import PropTypes from "prop-types";
import { Mutation, withApollo } from "react-apollo";
import AWSAppSyncClient from "aws-appsync";
import get from "lodash/get";

import getTimeStamp from "../../utils/getTimeStamp";

import getDestinationsWithState from "../../components/routes/utils/getDestinationsWithState";

import SecurityExceptionForm from "../../components/exceptions/SecurityExceptionForm";
import Modal from "../../components/layout/Modal";
import Loading from "../../components/layout/Loading";
import Button from "../../components/input/Button";
import { logSecurityExceptionMutation } from "../../api/graphql/logSecurityException";

class LogSecurityException extends React.Component {

  async getGeocoords() {
    const geoSettings = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 10000
    };

    return new Promise( (resolve,reject) => {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve({locX:pos.coords.longitude,locY:pos.coords.latitude})
        },
        (err) => {
          console.log(`Error during geolocation - ${err.code} / ${err.message}`); // DEBUG statement
          reject(err);
        },
        geoSettings
      );
    });
  }

  buildExceptionsData = (vehicleLicense, destination) => {
    // For this, the exception will be logged for the destination 
    return {
      vehicleLicense: vehicleLicense,
      destinationId: (destination && destination.id) ? destination.id : null,
      destinationName: (destination && destination.name) ? destination.name : null
    };
  };

  setFormInfo(destination) {
    return {
      destinationId: destination.id,
      destinationType: destination.type,
      destinationState: destination.state,
      destinationAddress: destination.address,
    }
  }


  async logSecurityException(logException,exceptionToLog,exceptionInfo,geolocation) {
    const securityException = {
      ...exceptionToLog,
      timestamp: getTimeStamp(),
      ...geolocation,
      exception:  {
        ...exceptionInfo
      }
    };
    /* This could becombined to one AppSync call with all exceptions */
    logException({
      variables: {
        securityException: securityException
      }
    });
    console.log('Logged exceptions', securityException);
    return;
    
  }

  render() {
    const {
      geolocation,
      destinationId,
      destinations,
      refreshStyles
    } = this.props;

    return (
      <Mutation
        mutation={logSecurityExceptionMutation}
      >
        {(logException, { loading, error, called }) => {

          //This logic will build the internal exception data (non-user defined data) 
          // which will be combined with the user entered form data before being pushed to the backend.
          let exceptionToLog; 
          
          // This contains destination info that can be passed to the form for logging exceptions.
          let destination = {}
          
          const vehicleLicense = get(this.props, "currentVehicle.licenseNum", null);

          if (destinationId) { // If destinationId is defined then the exception was logged on a specific destination view (or one of the lower order/transaction views).
            destination = destinations.reduce((acc,dest) => (dest.id.toString()===destinationId) ? dest : acc, {});
            exceptionToLog = this.buildExceptionsData(vehicleLicense,destination);

          } else { // Else Exception is being logged for a generic location (e.g. on the schedule view)
            if (!getDestinationsWithState(destinations,"ongoing").length) { // Is the truck driving (No "ongoing" destination states)
              exceptionToLog = this.buildExceptionsData(vehicleLicense);

            } else { // The truck has at least one (& hopefully only one) "ongoing" destination (which means it is loading/unloading)
              // For the current "ongoing" destination build the exception data.
              destination = getDestinationsWithState(destinations,"ongoing")[0];
              exceptionToLog = this.buildExceptionsData(vehicleLicense,destination);
           }
          }

          /*
          // Error should be shown to the user but not block the app - error handling should use this component
          : error ? (
                <Error error={error} />
              ) : called && !loading && !error ? (
          */

          return (
            <Modal uncloseable={true}>
              <h3>TURVALLISUUSHAVAINTO</h3>
              {loading ? (
                <Loading />
              ) : called && !loading ? (
                <React.Fragment>
                  <span>Poikkeama lähetetty</span>
                  <br/>
                  <br/>
                  <Button 
                    onClick={() => window.history.back()}
                  >OK</Button>
                </React.Fragment>
              ) : (
                <SecurityExceptionForm
                  destination={destination}
                  refreshStyles={refreshStyles}
                  onSubmit={submittedExceptionInfo => this.logSecurityException(logException,exceptionToLog,submittedExceptionInfo,geolocation.getLocation())}
                />
              )}
            </Modal>
          );
        }}
      </Mutation>
    );
  }
}

LogSecurityException.propTypes = {
  client: PropTypes.instanceOf(AWSAppSyncClient).isRequired,
  geolocation: PropTypes.shape({
    restart: PropTypes.func.isRequired,
    geolocated: PropTypes.bool.isRequired,
    getLocation: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
  }).isRequired,
  destination: PropTypes.object
};

export default withApollo(LogSecurityException);
