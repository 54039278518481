import React from "react";
import { Router } from "@reach/router";

import Drivers from "./Drivers";
import AddDriver from "./AddDriver";
import Driver from "./Driver";

const UserManagement = ({ refreshStyles }) => {

  return (
    <Router>
      <Drivers path="/" refreshStyles={refreshStyles}>
        <AddDriver path="driver/add" />
        <Driver path="driver/:username" />
      </Drivers>
    </Router>
  )
};

UserManagement.propTypes = {};

export default UserManagement;
